export const adventureTypes = [
  { value: "1", label: "Activities" },
  { value: "2", label: "Packages" },
  { value: "3", label: "Events" },
  { value: "4", label: "Courses" },
];

export const adventureCategory = [
  { value: "1", label: "Land" },
  { value: "2", label: "Water" },
  { value: "3", label: "Air" },
  { value: "4", label: "Mountain_and_Ice" },
];

export const adventureRiskLevel = [
  { value: "1", label: "level 1" },
  { value: "2", label: "level 2" },
  { value: "3", label: "level 3" },
  { value: "4", label: "level 4" },
  { value: "5", label: "level 5" },
];
