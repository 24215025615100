import { useEffect, useState } from "react";
import useSWR from "swr";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import MainLoader from "../../../../common/components/Loader/Loader";
import { fetcher } from "../../../../services/fetcher";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import PricingTable from "../components/PricingTable/PricingTable";
import { planTypes } from "../../../../data/covers";

const CoverPricing = ({ mode }) => {
  const { planType, parentId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const endpoint = `cover-api/${planType}/${parentId}/pricing/`;

  const coverEndpoint = `cover-api/${planType}/${parentId}/`;

  const { data: cover, error } = useSWR(coverEndpoint, fetcher);

  const {
    data: existingPricing = [],
    isLoading,
    mutate,
  } = useSWR(endpoint, fetcher);

  const shouldFetchBaseCover =
    planType === planTypes.CUSTOMIZE &&
    !isLoading &&
    existingPricing.length === 0 &&
    cover &&
    mode !== "edit";

  const motherPricing = shouldFetchBaseCover
    ? `cover-api/master-covers/${cover.cover}/pricing/`
    : null;

  const {
    data: baseCoverPricing,
    error: baseCoverPricingError,
    isLoading: baseCoverPricingLoading,
  } = useSWR(motherPricing, fetcher);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      rows: [
        {
          id: "",
          age_max: "",
          age_min: "",
          duration: "",
          price: "",
          type: "",
          disabled: false,
        },
      ],
    },
  });

  useEffect(() => {
    if (shouldFetchBaseCover && baseCoverPricing) {
      const transformedPricing = baseCoverPricing.map((price) => ({
        price_id: price.id,
        age_max: price.age_max,
        age_min: price.age_min,
        duration: price.duration,
        price: price.price,
        type: price.type,
      }));
      reset({ rows: transformedPricing });
    } else if (existingPricing.length > 0) {
      const transformedPricing = existingPricing.map((price) => ({
        price_id: price.id,
        age_max: price.age_max,
        age_min: price.age_min,
        duration: price.duration,
        price: price.price,
        type: price.type,
        ...(mode === "add" ? { disabled: true } : {}),
      }));
      reset({ rows: transformedPricing });
    } else if (mode === "edit") {
      reset({ rows: [] });
    }
  }, [existingPricing, baseCoverPricing, reset, mode, shouldFetchBaseCover]);

  const onSubmit = async (data, rowIndex) => {
    setIsSubmitting(true);

    let editEndpoint;
    let payload;

    if (mode === "edit") {
      payload = data.rows[rowIndex];
      editEndpoint = `${endpoint}${payload.price_id}/`;
    } else {
      payload = data.rows.filter((item) => !item.disabled);
    }

    try {
      if (mode === "edit") {
        await fetcher(`${editEndpoint}`, "PATCH", payload);
        successNotifications("Success! Pricing modify");
      } else {
        await fetcher(`${endpoint}`, "POST", payload);
        successNotifications("Success! New Pricing added");
      }

      await mutate();
      navigate(-1);
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDelete = async (price) => {
    setIsSubmitting(true);
    try {
      await fetcher(`${endpoint}${price}/`, "DELETE");
      successNotifications("Success! Pricing deleted");
      await mutate();
      navigate(-1);
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading || baseCoverPricingLoading) {
    return <MainLoader />;
  }

  if (error || baseCoverPricingError) {
    return (
      <>
        <p>{error}</p>
      </>
    );
  }

  return (
    <>
      <PricingTable
        mode={mode}
        control={control}
        reset={reset}
        cover={cover && cover}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit(onSubmit)}
        onDelete={onDelete}
      />
    </>
  );
};

export default CoverPricing;



