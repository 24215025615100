import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./features/auth/pages/Login/Login";
import Unauthorized from "./common/pages/Unauthorized/Unauthorized";
import Layout from "./common/templates/Layout/Layout";
import RequireAuth from "./features/auth/components/RequireAuth/RequireAuth";
import PersistLogin from "./features/auth/components/PersistLogin/PersistLogin";
import Dashboard from "./features/dashboard/pages/Dashboard";
import AccountPage from "./features/users/pages/Account/Account";
import HandleChangePassword from "./features/users/pages/Account/Handlechangepassword";
import SuperuserRoutes from "./routes/SuperuserRoutes";
import OperatorRoutes from "./routes/OperatorRoutes";
import StaffRoutes from "./routes/StaffRoutes";

// RBAC -- L1 (User Type), L2(Allowed roles),

function App() {
  const { user_type } = useSelector((state) => state.auth);
  const userType = user_type && user_type.length > 0 ? user_type[0] : undefined;

  let userRoutes;
  let allowedRoles = [];

  switch (userType) {
    case "is_superuser":
      allowedRoles = ["is_superuser"];
      userRoutes = <SuperuserRoutes />;
      break;
    case "is_operator":
      allowedRoles = ["Operator"];
      userRoutes = <OperatorRoutes />;
      break;
    case "is_staff":
      allowedRoles = ["Accounts team", "Issuance Team"];
      userRoutes = <StaffRoutes />;
      break;
    default:
      allowedRoles = [];
      userRoutes = <Navigate to="/" replace />;
  }

  const router = createBrowserRouter([
    // Public routes
    { path: "/", element: <LoginPage /> },
    { path: "unauthorized", element: <Unauthorized /> },

    // Private routes
    {
      element: <PersistLogin />,
      children: [
        {
          element: <Layout />,
          children: [
            {
              element: <RequireAuth allowedRoles={allowedRoles} />,
              children: [
                {
                  path: "*",
                  element: userRoutes,
                },
                { path: "dashboard", element: <Dashboard /> },
                { path: "account-settings", element: <AccountPage /> },
                { path: "change-password", element: <HandleChangePassword /> },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
