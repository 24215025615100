import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Issuance from "../features/issuance/pages/Issuance/Issuance";
import TravelerInfo from "../features/issuance/containers/SingleIssuance/TravelerInfo";
import Policies from "../features/issuance/pages/Policies/Policies";
import TripDetails from "../features/issuance/containers/TripTravellerDetail/TripDetails";
import Wallet from "../features/users/pages/Wallet/Wallet";
import AssignCover from "../features/users/pages/AssignCover/AssignCover";
import QuoteLinks from "../features/issuance/pages/QuoteLinks/QuoteLinks";
import UnissuedPolicies from "../features/issuance/pages/UnissuedPolicies/UnissuedPolicies";
import TravellerDetails from "../features/issuance/containers/TripTravellerDetail/TravellerDetails";

const StaffRoutes = () => {
  const { roles } = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route path="wallet" element={<Wallet />} />
      <Route path="covers" element={<AssignCover />} />

      {/* Issuance Team Routes */}
      {roles.includes("Issuance Team") && (
        <Route path="issuance">
          <Route path=":policy_type" element={<Issuance />} />
          <Route path=":policy_type/traveller" element={<TravelerInfo />} />
          <Route path="policies" element={<Policies />} />
          <Route path="unissued-policies" element={<UnissuedPolicies />} />
          <Route path="policies/trips/:id" element={<TripDetails />} />
          <Route
            path="policies/travellers/:id"
            element={<TravellerDetails />}
          />
          <Route path="quote-links" element={<QuoteLinks />} />
        </Route>
      )}

      {/* Accounts Team Routes */}
      {roles.includes("Accounts team") && (
        <Route path="accounts">{/* Accounts-specific routes */}</Route>
      )}

      {/* Marketing Team Routes */}
      {roles.includes("Marketing team") && (
        <Route path="marketing">{/* Marketing-specific routes */}</Route>
      )}
    </Routes>
  );
};

export default StaffRoutes;
