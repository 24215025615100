export const coverScope = [
  { value: "Domestic", label: "Domestic" },
  { value: "International", label: "International" },
];

export const currencyScope = [
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
];

export const issueTo = [
  { value: "ASC360", label: "ASC360" },
  { value: "ASC360 LLP", label: "ASC360 LLP" },
];

export const filterOptions = [
  {
    label: "Cover Scope",
    value: "cover_scope",
    type: "checkbox",
    options: coverScope,
  },
  {
    label: "Currency",
    value: "currency__code",
    type: "checkbox",
    options: currencyScope,
  },
  {
    label: "Issued To",
    value: "issued_to",
    type: "checkbox",
    options: issueTo,
  },
  {
    label: "Is Deleted",
    value: "is_deleted",
    type: "checkbox",
    options: [{ value: "true", label: "Deleted" }],
  },
  {
    label: "Is Expired",
    value: "is_expired",
    type: "checkbox",
    options: [{ value: "true", label: "Expired" }],
  },
  {
    label: "Verification",
    value: "verification",
    type: "checkbox",
    options: [{ value: "true", label: "Verification" }],
  },
];

export const sortOptions = [
  {
    label: "Sort By",
    value: "ordering",
    type: "checkbox",
    multiple: false,
    options: [
      { label: "Created On", value: "created_on" },
      { label: "Updated On", value: "updated_on" },
    ],
  },
];
