const paymentModeOptions = [
  { label: "Cash", value: "CASH" },
  { label: "Card", value: "CARD" },
  { label: "UPI", value: "UPI" },
  { label: "Commission", value: "COMMISSION" },
  { label: "Bank Transfer", value: "BANK_TRANSFER" },
  { label: "Online Payment", value: "ONLINE_PAYMENT" },
  { label: "Other", value: "OTHER" },
];

const typeModeOptions = [
  { label: "Add", value: "ADD" },
  { label: "Deduct", value: "DEDUCT" },
];

export const filterOptions = [
  {
    label: "Payment Mode",
    value: "payment_mode",
    type: "checkbox",
    multiple: true,
    options: paymentModeOptions,
  },
  {
    label: "Transaction Type",
    value: "transaction_type",
    type: "checkbox",
    multiple: true,
    options: typeModeOptions,
  },
];
