import { useState, useMemo, useCallback, useEffect, forwardRef } from "react";
import {
  ActionIcon,
  Checkbox,
  Combobox,
  Group,
  TextInput,
  useCombobox,
  Button,
  Center,
  Text,
  ScrollArea,
} from "@mantine/core";
import searchIcon from "../../../assets/svg/search.svg";
import classes from "./TransferList.module.css";

const RenderList = ({
  options,
  value,
  setCurrentValue,
  search,
  setSearch,
  disabled,
}) => {
  const combobox = useCombobox();

  const handleValueSelect = (val) => {
    if (disabled) return;
    setCurrentValue((current) =>
      current.some((item) => item.id === val.id)
        ? current.filter((item) => item.id !== val.id)
        : [val, ...current]
    );
  };

  const items = options
    .filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase().trim())
    )
    .map((item) => (
      <Combobox.Option
        value={item}
        key={item.id}
        active={value.some((v) => v.id === item.id)}
        onMouseOver={() => combobox.resetSelectedOption()}
      >
        <Group gap="sm">
          <Checkbox
            checked={value.some((v) => v.id === item.id)}
            onChange={() => {}}
            aria-hidden
            tabIndex={-1}
            radius="sm"
            style={{ pointerEvents: "none" }}
            disabled={disabled}
          />
          <span>{item.name}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <div className={classes.renderList}>
      <Combobox store={combobox} onOptionSubmit={handleValueSelect}>
        <Combobox.EventsTarget>
          <Group wrap="nowrap" gap={0} className={classes.controls}>
            <TextInput
              placeholder="Search"
              classNames={{ input: classes.input }}
              value={search}
              style={{ width: "100%" }}
              leftSection={<img src={searchIcon} height={15} />}
              onChange={(event) => {
                setSearch(event.currentTarget.value);
                combobox.updateSelectedOptionIndex();
              }}
            />
          </Group>
        </Combobox.EventsTarget>

        <ScrollArea style={{ height: 180 }} className={classes.list}>
          <Combobox.Options>
            {items.length > 0 ? (
              items
            ) : (
              <Combobox.Empty>Nothing found....</Combobox.Empty>
            )}
          </Combobox.Options>
        </ScrollArea>
      </Combobox>
    </div>
  );
};

const TransferButton = ({ onTransfer, icon, disabled }) => (
  <ActionIcon
    variant="transparent"
    size={36}
    className={classes.control}
    onClick={onTransfer}
    disabled={disabled}
  >
    <img src={icon} className={classes.icon} />
  </ActionIcon>
);

const SelectAllButton = ({ onSelectAll, label, variant }) => (
  <Button
    variant={variant}
    size="sm"
    my={"md"}
    className={classes.selectAllButton}
    onClick={onSelectAll}
  >
    {label}
  </Button>
);

const TransferList = forwardRef(
  ({ fieldData, onChange, label, value, mode }, ref) => {
    const [data, setData] = useState([[], []]);
    const [currentValue, setCurrentValue] = useState([]);
    const [searchForward, setSearchForward] = useState("");
    const [searchBackward, setSearchBackward] = useState("");

    useEffect(() => {
      const selectedIds = new Set(value.map(String));

      const selected = fieldData.filter((item) =>
        selectedIds.has(item.id.toString())
      );

      const available = fieldData.filter(
        (item) => !selectedIds.has(item.id.toString())
      );

      setData([available, selected]);
    }, [fieldData, value]);

    const updateData = useCallback(
      (newData) => {
        setData(newData);
        if (onChange) {
          const ids = newData[1].map((item) => item.id.toString());
          onChange(ids);
        }
      },
      [onChange]
    );

    const handleTransfer = useCallback(
      (transferFrom) => {
        if (mode === "view") return;
        const transferTo = transferFrom === 0 ? 1 : 0;
        const transferFromData = data[transferFrom].filter(
          (item) => !currentValue.includes(item)
        );
        const transferToData = [
          ...data[transferTo],
          ...currentValue.filter(
            (item) =>
              !data[transferTo].some((existing) => existing.id === item.id)
          ),
        ];

        const newData = [...data];
        newData[transferFrom] = transferFromData;
        newData[transferTo] = transferToData;

        updateData(newData);
        setCurrentValue([]);
      },
      [data, currentValue, updateData, mode]
    );

    const handleSelectAll = useCallback(
      (options) => {
        if (mode === "view") return;
        setCurrentValue(options);
      },
      [mode]
    );

    const forwardList = useMemo(
      () => (
        <RenderList
          options={data[0]}
          value={currentValue}
          setCurrentValue={setCurrentValue}
          search={searchForward}
          setSearch={setSearchForward}
          disabled={mode === "view"}
        />
      ),
      [data, currentValue, searchForward, mode]
    );

    const backwardList = useMemo(
      () => (
        <RenderList
          options={data[1]}
          value={currentValue}
          setCurrentValue={setCurrentValue}
          search={searchBackward}
          setSearch={setSearchBackward}
          disabled={mode === "view"}
        />
      ),
      [data, currentValue, searchBackward, mode]
    );

    return (
      <div className={classes.root} ref={ref}>
        <div className={classes.forwardList}>
          <Text className={classes.label}>Available {label}</Text>
          {forwardList}
          {mode !== "view" && (
            <Center>
              <SelectAllButton
                onSelectAll={() =>
                  handleSelectAll(
                    data[0].filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchForward.toLowerCase().trim())
                    )
                  )
                }
                label="Select All"
                variant={"outline"}
              />
            </Center>
          )}
        </div>
        <div className={classes.transferButtons}>
          <TransferButton
            onTransfer={() => handleTransfer(0)}
            icon={"/images/svg/chevronright.svg"}
            disabled={mode === "view"}
          />
          <TransferButton
            onTransfer={() => handleTransfer(1)}
            icon={"/images/svg/chevronleft.svg"}
            disabled={mode === "view"}
          />
        </div>
        <div className={classes.backwardList}>
          <Text className={classes.label}>Selected {label}</Text>
          {backwardList}
          {mode !== "view" && (
            <Center>
              <SelectAllButton
                onSelectAll={() =>
                  handleSelectAll(
                    data[1].filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchBackward.toLowerCase().trim())
                    )
                  )
                }
                label="Select All"
                variant={"outline2"}
              />
            </Center>
          )}
        </div>
      </div>
    );
  }
);

TransferList.displayName = "TransferList";
export default TransferList;
