import { Paper } from "@mantine/core";
import MainLoader from "../../../../common/components/Loader/Loader";
import ProfileForm from "../../forms/ProfileForm/ProfileForm";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import classes from "./Account.module.css";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../../utils/userTypes";
import OperatorAccount from "../OperatorAccount/OperatorAccount";

const AccountPage = () => {
  const { user_type } = useSelector((state) => state.auth);
  const endpoint =
    user_type[0] === USER_TYPES.OPERATOR
      ? "operator/operator"
      : "auth-api/user-info-profile/";

  const { isLoading, data: profile, mutate } = useSWR(endpoint, fetcher);

  const updateProfileMutation = async (updatedUser) => {
    try {
      await fetcher(endpoint, "PUT", updatedUser);
      await mutate();
      successNotifications("Success! Profile Updated item.");
    } catch (err) {
      errorNotifications(err.response.data, err);
    }
  };

  const handleUpdate = async (profileData) => {
    updateProfileMutation(profileData);
  };

  const updateOperatorProfileMutation = async (updateProfile) => {
    try {
      await fetcher(endpoint, "PUT", updateProfile, {
        "content-type": "multipart/form-data",
      });
      await mutate();
      successNotifications("Success! Profile updated");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
    }
  };

  const handleOperatorSubmit = (payload) => {
    const formData = new FormData();

    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((id) => {
            formData.append(key, id);
          });
        } else {
          if (payload[key] instanceof Blob) {
            const fileExtension = payload[key].type.split("/")[1];
            const file = new File([payload[key]], `${key}.${fileExtension}`, {
              type: payload[key].type,
            });
            formData.append(key, file);
          } else {
            formData.append(key, payload[key]);
          }
        }
      }
    }

    updateOperatorProfileMutation(formData);
  };

  if (isLoading) {
    return <MainLoader />;
  }

  return (
    <>
      {/* Operator  */}
      {user_type[0] === USER_TYPES.OPERATOR && (
        <OperatorAccount operator={profile} onSubmit={handleOperatorSubmit} />
      )}

      {/* User Profile */}
      {user_type[0] !== USER_TYPES.OPERATOR && (
        <Paper shadow="xl" my="xl" className={classes.userProfileFormPaper}>
          <ProfileForm data={profile} onSubmit={handleUpdate} />
        </Paper>
      )}
    </>
  );
};

export default AccountPage;
