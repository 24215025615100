/**
 * Converts a date to YYYY-MM-DD or YYYY-MM-DD HH:mm:ss A format
 * @param {string|Date} timestamp - The date to format
 * @param {string} [type="date"] - The format type, either "date" or "datetime"
 * @returns {string} The formatted date string
 */
import dayjs from "dayjs";

export const FormatDate = (timestamp, type = "date") => {
  let formattedDate = new Date(timestamp);

  if (type === "datetime") {
    formattedDate = dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss A");
  } else {
    formattedDate = dayjs(timestamp).format("YYYY-MM-DD");
  }

  return formattedDate;
};
