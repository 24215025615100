import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireAuth = ({ allowedRoles }) => {
  const { isAuthenticated, roles } = useSelector((state) => state.auth);
  const location = useLocation();

  // Checking if the user has any of the allowed roles
  const hasAllowedRole = roles?.some((role) => allowedRoles.includes(role));

  // Allow access to the dashboard for any authenticated user as public route
  if (isAuthenticated && allowedRoles.length === 0) {
    return <Outlet />;
  }

  // If the user is authenticated and has at least one allowed role, grant access
  if (isAuthenticated && hasAllowedRole) {
    return <Outlet />;
  }

  // If not authenticated, redirect to the login page
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Navigate to="/unauthorized" state={{ from: location }} replace />;
};

export default RequireAuth;
