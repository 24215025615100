const coverScope = [
  { value: "Domestic", label: "Domestic" },
  { value: "International", label: "International" },
];

const currencyScope = [
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
];

const issueTo = [
  { value: "ASC360", label: "ASC360" },
  { value: "ASC360 LLP", label: "ASC360 LLP" },
];

const customizationType = [
  { label: "Operator", value: "1" },
  { label: "B2C", value: "2" },
  { label: "ASC360", value: "3" },
];

const coverType = [
  { value: "1", label: "Activities" },
  { value: "2", label: "Packages" },
  { value: "3", label: "Events" },
  { value: "4", label: "Courses" },
];

export const filterOptions = [
  {
    label: "Customization Type",
    value: "customization_type",
    type: "checkbox",
    options: customizationType,
  },
  {
    label: "Type",
    value: "type",
    type: "checkbox",
    options: coverType,
  },
  {
    label: "Cover Scope",
    value: "cover_scope",
    type: "checkbox",
    options: coverScope,
  },
  {
    label: "Currency",
    value: "currency__code",
    type: "checkbox",
    options: currencyScope,
  },
  {
    label: "Issued To",
    value: "issued_to",
    type: "checkbox",
    options: issueTo,
  },
  {
    label: "Is Deleted",
    value: "is_deleted",
    type: "checkbox",
    options: [{ value: "true", label: "Deleted" }],
  },
  {
    label: "Is Expired",
    value: "is_expired",
    type: "checkbox",
    options: [{ value: "true", label: "Expired" }],
  },
  {
    label: "Verification",
    value: "verification",
    type: "checkbox",
    options: [{ value: "true", label: "Verification" }],
  },
  {
    label: "Is partnership",
    value: "is_partnership_hidden",
    type: "checkbox",
    options: [{ value: "true", label: "Is partnership" }],
  },
];

export const sortOptions = [
  {
    label: "Sort By",
    value: "ordering",
    type: "checkbox",
    multiple: false,
    options: [
      { label: "Created On", value: "created_on" },
      { label: "Updated On", value: "updated_on" },
    ],
  },
];
