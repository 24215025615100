import { Controller } from "react-hook-form";
import { Select } from "@mantine/core";

const TableSelect = ({ name, control, options, value, readOnly }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          data={options}
          defaultValue={value}
          disabled={readOnly}
          onChange={field.onChange}
        />
      )}
    />
  );
};

export default TableSelect;
