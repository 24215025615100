import { notifications } from "@mantine/notifications";

const errorNotifications = (data, error) => {
  // Handle specific HTTP error codes (404, 401, 500,)
  if (error.response) {
    const status = error.response.status;
    const statusText = error.response.statusText;

    if (status === 401) {
      notifications.show({
        title: "Unauthorized (401)",
        message:
          "You are not authorized to access this resource. Please log in. 🚫",
      });
      return;
    }

    if (status === 404) {
      notifications.show({
        title: "Not Found (404)",
        message: "The requested resource could not be found. 🔍",
      });
      return;
    }

    if (status === 500) {
      notifications.show({
        title: "Server Error (500)",
        message:
          "An internal server error occurred. Please try again later. 💥",
      });
      return;
    }

    // Handle Error Reponse
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((errorMessage) => {
          notifications.show({
            title: `${statusText} ${status}`,
            message: `${key}: ${errorMessage} 🤥`,
          });
        });
      } else {
        notifications.show({
          title: `${statusText} ${status}`,
          message: `${key}: ${data[key]} 🤥`,
        });
      }
    });

    return;
  } else {
    notifications.show({
      title: "Error",
      message: "An unknown error occurred. Please try again. ❌",
    });
    return;
  }
};

const successNotifications = (message) => {
  notifications.show({
    title: `${message}`,
  });
  return;
};

export { errorNotifications, successNotifications };
