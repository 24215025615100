import { Card, Image, Text } from "@mantine/core";
import classes from "./OperatorCard.module.css";
import SplitButton from "../../../../common/components/Popover/Popover";
import WalletCard from "../../../../common/components/WalletCard/WalletCard";

const OperatorCard = ({ data }) => {
  const isDev = import.meta.env.MODE === "development";
  const operatorLogo = isDev
    ? `${import.meta.env.VITE_API_ENDPOINT}${data.logo}`
    : data.logo;

  return (
    <Card padding="lg" className={classes.card}>
      {/* <div className={classes.header}> */}
      <Card.Section className={classes.imageSection}>
        <Image src={operatorLogo} alt="operator logo" height={40} />
        <div className={classes.menuIcon}>
          <SplitButton uuid={data.uuid} />
        </div>
      </Card.Section>

      {/* </div> */}
      <div className={classes.body}>
        <div className={classes.details}>
          <Text className={classes.title}>{data.operator_name}</Text>
          <Text c="dimmed" className={classes.type}>
            {data.operator_type}
          </Text>
          <Text c="dimmed" className={classes.phno}>
            <a
              href={`tel:${data.operator.phone_number}`}
              className={classes.phoneLink}
            >
              <Image
                src="/images/svg/call.svg"
                className={classes.callIcon}
                alt="call icon"
              />
              {data.operator.phone_number}
            </a>
          </Text>
          <Text className={classes.email}>
            <a
              href={`mailto:${data.operator.email}`}
              className={classes.emailLink}
            >
              <Image
                src="/images/svg/mail-icon.svg"
                className={classes.mailIcon}
                alt="mail icon"
              />
              {data.operator.email}
            </a>
          </Text>
        </div>

        <div className={classes.wallet}>
          <WalletCard balance={data.wallet} type={"INR"} />
        </div>
      </div>
      <Text c="dimmed" className={classes.emcode}>
        <span className={classes.headingCode}>Operator code</span>:{" "}
        {data.operator_unique_code}
      </Text>
    </Card>
  );
};

export default OperatorCard;
