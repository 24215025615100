export const adventureCategory = [
  { value: "1", label: "Land" },
  { value: "2", label: "Water" },
  { value: "3", label: "Air" },
  { value: "4", label: "Mountain_and_Ice" },
];

export const filterOptions = [
  {
    label: "Category",
    value: "category",
    type: "checkbox",
    multiple: true,
    options: adventureCategory,
  },
];

export const sortOptions = [
  {
    label: "Sort By",
    value: "ordering",
    type: "checkbox",
    multiple: false,
    options: [
      { label: "Modify Date", value: "modify_date" },
      { label: "Create Date", value: "create_date" },
      { label: "By ID", value: "id" },
    ],
  },
];
