import { useCallback } from "react";
import { Text } from "@mantine/core";
import DataWrapper from "../../../covers/components/DataWrapper/DataWrapper";

const endpoint = "generate-quote/quotes/create/";

const QuoteLinks = () => {
  const handleDownloadDoc = useCallback(async (row) => {
    const quote = { ...row };
    const url = quote.unique_link;
    window.open(url, "_blank", "noopener,noreferrer");
  }, []);

  const config = {
    fields: [
      { key: "adventure", label: "Adventure" },
      { key: "price", label: "Cover Price" },
      { key: "name", label: "Name" },
      { key: "email", label: "Email" },
      { key: "nationality", label: "Nationality" },
      { key: "start_date", label: "Start Date" },
      { key: "end_date", label: "End Date" },
      { key: "num_people", label: "Total Pax" },
      { key: "travelling_from", label: "Travel From" },
      { key: "travelling_to", label: "Travel To" },
      { key: "link_status", label: "Link Status", type: "status" },
      { key: "trip_status", label: "Trip Status", type: "status" },
    ],
    handler: [
      {
        label: "Link",
        handler: (row) => handleDownloadDoc(row),
      },
    ],
  };

  return (
    <>
      <Text mb={"xl"} fw={600} size="xl">
        QuoteLinks
      </Text>
      <DataWrapper config={config} endpoint={endpoint} />
    </>
  );
};

export default QuoteLinks;
