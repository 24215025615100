import { Group, Image } from "@mantine/core";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../utils/userTypes";

const Logo = () => {
  const operatorProfile = useSelector((state) => state.operatorProfile);
  const userType = useSelector((state) => state.auth.user_type);
  const isDev = import.meta.env.MODE === "development";

  const operatorLogo = isDev
    ? `${import.meta.env.VITE_API_ENDPOINT}${operatorProfile?.logo}`
    : operatorProfile?.logo;

  return (
    <div>
      <Group spacing="0.5rem">
        <Image
          src="https://asc360.com/static/images/logo.png"
          alt="asc360 logo"
          height={60}
        />

        {Array.isArray(userType) && userType.includes(USER_TYPES.OPERATOR) && operatorProfile.logo && (
          <>
            <div style={{ borderLeft: "2px solid #B4B4B4", borderRadius:"24px", height: "60px", marginRight: "4px" }}></div>
            <Image
              src={operatorLogo}
              alt="Operator Logo"
              height={60}
            />
          </>
        )}
      </Group>
    </div>
  );
};

export default Logo;














































