import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  FileInput,
  Select,
  SimpleGrid,
  Button,
  Paper,
  Title,
  Group,
} from "@mantine/core";
import classes from "./OperatorProfileForm.module.css";

const OperatorProfileForm = ({ data, onSubmit }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      operator_type: data.operator_type || "",
      operator_name: data.operator_name || "",
      email: data.operator.email || "",
      phone_number: data.operator.phone_number || "",
      poc_name: data.poc_name || "",
      country: data.country || "",
      office_address: data.office_address || "",
      website: data.website || "",
      currency: data.currency || "",
      beneficiary_name: data.beneficiary_name || "",
      beneficiary_account_number: data.beneficiary_account_number || "",
      beneficiary_bank_name: data.beneficiary_bank_name || "",
      ifsc_code: data.ifsc_code || "",
      upi_id: data.upi_id || "",
      logo: data.logo || "",
      pan_card: data.pan_card || "",
      gst: data.gst || "",
      adhar_card: data.adhar_card || "",
      license: data.license || "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper shadow="xl" className={classes.operatorProfilePaper}>
        <SimpleGrid
          cols={{ base: 1, xs: 2, sm: 2, lg: 2 }}
          spacing={{ base: 10, xs: "xl", sm: "xl" }}
          className={classes.operatorProfileForm}
        >
          <Select
            label={"Type"}
            placeholder={"Select operator type"}
            data={["Domestic", "International"]}
            value={data.operator_type}
            {...register("operator_type")}
          />

          <TextInput
            label={"Name"}
            type="text"
            placeholder={"Enter operator name"}
            {...register("operator_name")}
          />

          <TextInput
            label={"Email"}
            type="email"
            placeholder={"Enter operator email"}
            {...register("email")}
          />

          <TextInput
            label={"Phone number"}
            type="number"
            placeholder={"Enter operator phone number"}
            {...register("phone_number")}
          />

          <Controller
            name="logo"
            control={control}
            render={({ field }) => (
              <FileInput
                label={"Upload Logo"}
                placeholder="operator logo"
                onChange={field.onChange}
              />
            )}
          />

          <TextInput
            label={"LIASE"}
            type="text"
            placeholder={"Enter name of LIASE"}
            {...register("poc_name")}
          />

          <TextInput
            label={"Country"}
            type="text"
            placeholder={"Enter country"}
            {...register("country")}
          />

          <TextInput
            label={"Office address"}
            type="text"
            placeholder={"Enter office address"}
            {...register("office_address")}
          />

          <TextInput
            label="Website"
            type="text"
            placeholder="Enter operator website"
            {...register("website")}
          />

          <Controller
            name="currency"
            control={control}
            render={({ field }) => (
              <Select
                label="Payment currency"
                placeholder="Enter payment currency"
                data={["INR", "USD", "NPR"]}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </SimpleGrid>
      </Paper>

      <Paper shadow="xl" my="xl" className={classes.operatorProfilePaper}>
        <Title size="1rem" className={classes.heading}>
          Bank Details
        </Title>
        <SimpleGrid
          cols={{ base: 1, xs: 2, sm: 2, lg: 2 }}
          spacing={{ base: 10, xs: "xl", sm: "xl" }}
          className={classes.operatorProfileForm}
        >
          <TextInput
            label="Beneficiary name"
            type="text"
            placeholder="Enter beneficiary name"
            {...register("beneficiary_name")}
          />

          <TextInput
            label="Beneficiary account"
            type="text"
            placeholder="Enter beneficiary account"
            {...register("beneficiary_account_number")}
          />

          <TextInput
            label="Beneficiary bank"
            type="text"
            placeholder="Enter beneficiary bank"
            {...register("beneficiary_bank_name")}
          />

          <TextInput
            label="Bank IFSC Code"
            type="text"
            placeholder="Enter ifsc code"
            {...register("ifsc_code")}
          />

          <TextInput
            label="UPI ID"
            type="text"
            placeholder="Enter UPI ID"
            {...register("upi_id")}
          />
        </SimpleGrid>
      </Paper>

      <Paper shadow="xl" my="xl" className={classes.operatorProfilePaper}>
        <Title className={classes.heading}>Upload Documents</Title>

        <SimpleGrid
          cols={{ base: 1, xs: 2, sm: 2, lg: 2 }}
          spacing={{ base: 10, xs: "xl", sm: "xl" }}
          className={classes.operatorProfileForm}
        >
          <Controller
            name="pan_card"
            control={control}
            render={({ field }) => (
              <FileInput
                label={"Pan Card"}
                placeholder="operator pan card"
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="gst"
            control={control}
            render={({ field }) => (
              <FileInput
                label={"GST Document"}
                placeholder="operator gst document"
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="adhar_card"
            control={control}
            render={({ field }) => (
              <FileInput
                label={"Aadhar Card"}
                placeholder="operator aadhar document"
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="license"
            control={control}
            render={({ field }) => (
              <FileInput
                label={"License"}
                placeholder="operator license"
                onChange={field.onChange}
              />
            )}
          />
        </SimpleGrid>
        <Group justify="center">
          <Button variant="outline" type="submit" mt="xl" size="md">
            Submit
          </Button>
        </Group>
      </Paper>

      {/* Hidden Fields */}

      {/* <SimpleGrid cols={{ base: 1, sm: 2, lg: 2 }} spacing="xl" className={classes.operatorProfileForm}> */}
      <TextInput
        type="hidden"
        value={"Operators"}
        {...register("designation")}
      />

      <TextInput
        type="hidden"
        value={"Operators"}
        {...register("permission")}
      />

      <TextInput
        type="hidden"
        value={"Operators"}
        {...register("department_name")}
      />
      {/* </SimpleGrid> */}
    </form>
  );
};

export default OperatorProfileForm;
