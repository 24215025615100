import { useState, useEffect, useMemo } from "react";
import useSWR from "swr";
import { useForm, Controller } from "react-hook-form";
import { fetcher } from "../../../../services/fetcher";
import {
  Select,
  Center,
  Paper,
  SimpleGrid,
  Text,
  Button,
  NumberInput,
  Radio,
  Group,
} from "@mantine/core";
import dayjs from "dayjs";
import { DatePickerInput } from "@mantine/dates";
import nationalities from "../../../../data/nationality.json";
import countries from "../../../../data/countries.json";

const GetYourQuote = ({ onUrlReady, operator }) => {
  const { handleSubmit, control, watch, register } = useForm();
  const [value, setValue] = useState([null, null]);
  const [numPax, setNumPax] = useState(1);

  const nationality = watch("nationality");
  const travellingTo = watch("travelling_to");
  const travellingFrom = watch("travelling_from");
  const adventure = watch("adventure");
  const residence_status = watch("residence_status");

  const allRequiredParamsAvailable =
    nationality && travellingTo && travellingFrom;

  const { data: adventureOptions, error } = useSWR(
    allRequiredParamsAvailable
      ? `quotation/adventure-options/?${new URLSearchParams({
          nationality,
          travelling_to: travellingTo,
          travelling_from: travellingFrom,
          ...(residence_status &&
            residence_status === "yes" && { residence_status }),
          ...(operator && { operator: operator.id }),
        }).toString()}`
      : null,
    fetcher
  );

  console.log(error);

  const transformOptions = () => {
    if (!adventureOptions) return [];

    return Object.keys(adventureOptions).map((key) => ({
      group: key,
      items: adventureOptions[key],
    }));
  };

  const adventureOptionsList = adventureOptions && transformOptions();

  useEffect(() => {
    register("no_of_pax", { value: numPax });
  }, [numPax, register]);

  const onSubmit = (data) => {
    const start_date = data.travel_dates[0]
      ? dayjs(data.travel_dates[0]).format("YYYY-MM-DD")
      : null;
    const end_date = data.travel_dates[1]
      ? dayjs(data.travel_dates[1]).format("YYYY-MM-DD")
      : null;

    const age_fields = [];
    for (let i = 1; i <= numPax; i++) {
      age_fields.push(data[`age_${i}`]);
    }

    const queryParams = new URLSearchParams({
      start_date,
      end_date,
      ...age_fields.reduce(
        (acc, age, index) => ({ ...acc, [`age_fields[${index}]`]: age }),
        {}
      ),
      adventure,
      nationality,
      travelling_to: travellingTo,
      travelling_from: travellingFrom,
      residence_status: data.residence_status,
    }).toString();

    const url = `quotation/cover-pricing/?${queryParams}`;

    onUrlReady(url);

    handleSubmit();
  };

  const showResidenceStatus = useMemo(() => {
    return nationality !== "Indian" && travellingFrom === "India";
  }, [nationality, travellingFrom]);

  return (
    <>
      <Paper shadow="xl" p="lg" my="lg">
        <Text mb="lg" size="lg" fw="700">
          Get Quote
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid
            cols={{ base: 1, sm: 2, lg: 3 }}
            spacing={{ base: 10, sm: "xl" }}
            verticalSpacing={{ base: "md", sm: "xl" }}
          >
            <Controller
              name="nationality"
              control={control}
              render={({ field }) => (
                <Select
                  label="Nationality"
                  placeholder="Select user nationality"
                  data={nationalities.map((item) => item.name)}
                  searchable
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              name="travelling_from"
              control={control}
              render={({ field }) => (
                <Select
                  label="From"
                  placeholder="Select travelling from"
                  data={countries.map((item) => item.name)}
                  searchable
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              name="travelling_to"
              control={control}
              render={({ field }) => (
                <Select
                  label="To"
                  placeholder="Select travelling to"
                  data={countries.map((item) => item.name)}
                  searchable
                  onChange={field.onChange}
                />
              )}
            />

            {/* Indian Resident Status */}
            {showResidenceStatus && (
              <Controller
                name="residence_status"
                control={control}
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    name="residence_status"
                    id="residenceStatus"
                    label="Are all travelers Indian residents with OCI, PAN, or an Indian bank account?"
                    withAsterisk
                  >
                    <Group mt="xs">
                      <Radio value="yes" label="Yes" />
                      <Radio value="no" label="No" />
                    </Group>
                  </Radio.Group>
                )}
              />
            )}

            <Controller
              name="travel_dates"
              control={control}
              render={({ field }) => (
                <DatePickerInput
                  type="range"
                  label="Travel Dates"
                  placeholder="Start Date - End Date"
                  value={value}
                  minDate={new Date()}
                  onChange={(val) => {
                    setValue(val);
                    field.onChange(val);
                  }}
                  allowSingleDateInRange
                />
              )}
            />

            <Controller
              name="adventure"
              control={control}
              render={({ field }) => (
                <Select
                  label="Adventure"
                  placeholder="Select an adventure"
                  data={adventureOptionsList}
                  searchable
                  {...field}
                />
              )}
            />

            <Controller
              name="no_of_pax"
              control={control}
              render={({ field }) => (
                <NumberInput
                  label="No. of pax"
                  placeholder="Enter no.of traveller"
                  min={1}
                  max={10}
                  allowNegative={false}
                  allowDecimal={false}
                  hideControls
                  value={field.value}
                  defaultValue={1}
                  onChange={(value) => {
                    setNumPax(value);
                    field.onChange(value);
                  }}
                />
              )}
            />

            {Array.from({ length: numPax }, (_, index) => (
              <Controller
                key={index}
                name={`age_${index + 1}`}
                control={control}
                render={({ field }) => (
                  <NumberInput
                    label={`Age of person ${index + 1}`}
                    placeholder={`Enter age of person ${index + 1}`}
                    min={1}
                    max={80}
                    allowNegative={false}
                    allowDecimal={false}
                    hideControls
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            ))}
          </SimpleGrid>
          <Center>
            <Button type="submit" mt="xl">
              Get Quote
            </Button>
          </Center>
        </form>
      </Paper>
    </>
  );
};

export default GetYourQuote;
