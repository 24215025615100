import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  uuid: "",
  operator_name: "",
  poc_name: "",
  designation: "",
  office_address: "",
  website: null,
  operator_type: "",
  country: "",
  logo: "",
  operator_unique_code: "",
  beneficiary_name: "",
  beneficiary_account_number: "",
  beneficiary_bank_name: "",
  ifsc_code: "",
  upi_id: "",
  pan_card: null,
  gst: null,
  atoai_registration: null,
  ministry_of_tourism_registration: null,
  state_tourism_registration: null,
  onboarded_by_email: "",
  permission: "",
  operator: {
    email: "",
    phone_number: "",
    is_active: false,
  },
  wallet: 0.0,
};

const operatorProfileSlice = createSlice({
  name: "operatorProfile",
  initialState,
  reducers: {
    setOperatorProfile(state, action) {
      return { ...state, ...action.payload };
    },
    clearOperatorProfile() {
      return initialState;
    },
  },
});

export const { setOperatorProfile, clearOperatorProfile } =
  operatorProfileSlice.actions;
export default operatorProfileSlice.reducer;
