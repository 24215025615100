import { Button, Flex } from "@mantine/core";
import { Link } from "react-router-dom";
import DataWrapper from "../../components/DataWrapper/DataWrapper";
import { filterOptions, sortOptions } from "../../utils/masterCovers";

const MasterCover = () => {
  const endpoint = "cover-api/master-covers/";

  const handlePricesView = (row) => {
    return `${row.id}/pricing/`;
  };

  const handleEdit = (row) => {
    return `edit/${row.id}`;
  };

  const handleView = (row) => {
    return `${row.id}`;
  };

  const handleBenefitsView = (row) => {
    return `${row.id}/benefits/`;
  };

  const config = {
    fields: [
      { key: "token", label: "Cover ID" },
      { key: "title", label: "Title" },
      { key: "cover_scope", label: "Scope" },
      { key: "currency", label: "Currency" },
      { key: "partner_name", label: "Partner" },
      { key: "is_expired", label: "Status", type: "status" },
    ],
    actions: [
      { label: "View", to: (row) => handleView(row) },
      { label: "Edit", to: (row) => handleEdit(row) },
      {
        label: "Price",
        to: (row) => handlePricesView(row),
        isButton: true,
      },
      {
        label: "Benefits",
        to: (row) => handleBenefitsView(row),
        isButton: true,
      },
    ],
  };

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        mb={"lg"}
      >
        <Link to={`add`}>
          <Button variant="outline">+ Add Covers</Button>
        </Link>
      </Flex>

      <DataWrapper
        endpoint={endpoint}
        filterOptions={filterOptions}
        sortOptions={sortOptions}
        config={config}
      />
    </>
  );
};

export default MasterCover;
