import AuthAPI from "../features/auth/endpoints";
import store from "../store.js";
import { setAuth } from "../features/auth/authSlice";

const apiTokenRefresh = async (apiTokenRefresh) => {
  const response = await AuthAPI.tokenRefresh(apiTokenRefresh);
  store.dispatch(setAuth(response.data));
  return response.data.access;
};

export default apiTokenRefresh;
