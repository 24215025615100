import { Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import CustomPasswordInput from "../../../../common/components/PasswordInput/PasswordInput";
import classes from "./Changepassword.module.css";

const ChangePasswordForm = ({ onSubmit }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const form = useForm({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const values = form.values;
    console.log("Form submitted with values: ", values);

    // Simulate password change request
    await onSubmit(values);

    // Redirect to the dashboard after successful password change
    navigate("/dashboard");
  };

  return (
    <form className={classes.changepassword} onSubmit={handleSubmit}>
      <CustomPasswordInput
        required
        label="Old Password"
        placeholder="Enter your old password"
        {...form.getInputProps("old_password")}
        radius="sm"
      />

      <CustomPasswordInput
        required
        label="New Password"
        placeholder="Create new password"
        {...form.getInputProps("new_password")}
        radius="md"
      />

      <CustomPasswordInput
        required
        label="Confirm New Password"
        placeholder="Confirm your password"
        {...form.getInputProps("confirm_password")}
        radius="md"
      />

      <div className="button-wrapper">
        <Button fullWidth variant="gradient" type="submit" mt="xl" size="md">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
