import { Paper, Title, Text, Image, Group } from "@mantine/core";
import classes from "./AuthLayout.module.css";

const AuthLayout = ({ children, title }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.background}></div>
      <div className={classes.formContainer}>
        <Paper className={classes.form} radius={0}>
          <Group className={classes.logo} justify="flex-end">
            <Image
              h={96}
              fit="contain"
              src="https://asc360.com/static/images/logo.png"
            ></Image>
          </Group>
          <Title order={2} className={classes.title} ta="start">
            {title}
          </Title>
          <Text className={classes.subtitle}>
            Making the outdoors safe and secure
          </Text>
          {children}
        </Paper>
      </div>
    </div>
  );
};

export default AuthLayout;
