import { useState, useEffect } from "react";
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  TextInput,
  rem,
  Paper,
  Button,
  Checkbox,
} from "@mantine/core";
import iconSearch from "../../../assets/svg/search.svg";
import MainLoader from "../Loader/Loader";
import { FilterMenu } from "../FilterMenu/FilterMenu";
import { SortMenu } from "../SortMenu/SortMenu";
import Actions from "./Actions";
import StatusText from "./StatusText";
import { FormatDate } from "../../../helper/DateFormatting";

import classes from "./DataTable.module.css";

function Th({ children, ...props }) {
  return (
    <Table.Th className={classes.th} {...props}>
      <UnstyledButton className={classes.control}>
        <Group justify="space-between" wrap="nowrap">
          <Text fw={500} fz="sm" component="span">
            {children}
          </Text>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

// Utility function to get nested value
const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const DataTable = ({
  data,
  config,
  loading,
  form,
  filterOptions,
  sortOptions,
  onSubmit,
  onSearch,
  onSelectionChange,
}) => {
  const [sortedData, setSortedData] = useState(data);
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const selectRow = (id) => {
    setSelection((current) => {
      const newSelection = current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id];

      if (onSelectionChange) {
        onSelectionChange(newSelection);
      }

      return newSelection;
    });
  };

  const selectAllRow = () => {
    setSelection((current) => {
      const allSelected = current.length === data.length;
      const newSelection = allSelected ? [] : data.map((item) => item.id);

      if (onSelectionChange) {
        onSelectionChange(newSelection);
      }

      return newSelection;
    });
  };

  const handleSelectionAction = (handler) => {
    if (handler) {
      handler(selection);
    }
  };

  const rows = sortedData.map((row, index) => (
    <Table.Tr key={index}>
      {config.selectionCheckbox && (
        <Table.Td>
          <Checkbox
            radius="sm"
            checked={selection.includes(row.id)}
            onChange={() => selectRow(row.id)}
          />
        </Table.Td>
      )}

      {config.fields.map((field) => (
        <Table.Td key={`${row.id}-${field.key}`}>
          {field.type === "combine" ? (
            <>
              <Text size="sm" me="xs" my={"9px"} component="span">
                {getNestedValue(row, field.key1)}
              </Text>
              <Text size="sm" component="span">
                {getNestedValue(row, field.key2)}
              </Text>
            </>
          ) : field.type === "date" ? (
            <Text size="sm" my={"9px"} component="span">
              {FormatDate(getNestedValue(row, field.key), field.type)}
            </Text>
          ) : field.type === "datetime" ? (
            <Text size="sm" my={"9px"} component="span">
              {FormatDate(getNestedValue(row, field.key), field.type)}
            </Text>
          ) : field.type === "status" ? (
            <StatusText
              status={getNestedValue(row, field.key)}
              field={field.key}
            />
          ) : (
            <Text
              size="sm"
              my={"9px"}
              fw={field.key === config.fields[0].key && 600}
              component="span"
            >
              {getNestedValue(row, field.key)}
            </Text>
          )}
        </Table.Td>
      ))}

      {(config.actions || config.handler) && (
        <Table.Td
          style={{
            position: "sticky",
            right: 0,
            backgroundColor: "var(--mantine-color-body)",
            zIndex: 1,
          }}
        >
          <Group gap={0} justify="flex-center">
            <Actions
              rowData={row}
              actions={config.actions || []}
              handler={config.handler || []}
            />
          </Group>
        </Table.Td>
      )}
    </Table.Tr>
  ));

  const headerStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "var(--mantine-color-body)",
    transition: "box-shadow 150ms ease",
  };

  return (
    <Paper>
      <Group justify="flex-start" py={"lg"} px={"sm"} mb={"lg"}>
        <TextInput
          placeholder="Search"
          variant="filled"
          leftSection={
            <img style={{ width: rem(16), height: rem(16) }} src={iconSearch} />
          }
          onChange={onSearch}
        />

        {filterOptions && (
          <FilterMenu
            form={form}
            filterOptions={filterOptions}
            onSubmit={onSubmit}
          />
        )}

        {sortOptions && (
          <SortMenu form={form} sortOptions={sortOptions} onSubmit={onSubmit} />
        )}

        <div style={{ flexGrow: 1 }} />

        {config.selectionActions && config.selectionActions.length > 0 && (
          <>
            {config.selectionActions.map((action, index) => (
              <Button
                key={index}
                variant="outline"
                onClick={() => handleSelectionAction(action.handler)}
              >
                {action.label}
              </Button>
            ))}
          </>
        )}
      </Group>

      <ScrollArea>
        <Table
          horizontalSpacing="sm"
          verticalSpacing="xs"
          miw={700}
          layout="fixed"
          withRowBorders={false}
          highlightOnHover
          style={{ minWidth: "100%", tableLayout: "auto" }}
        >
          <Table.Thead style={headerStyle}>
            <Table.Tr>
              {config.selectionCheckbox && (
                <Table.Th style={{ width: rem(40), whiteSpace: "nowrap" }}>
                  <Checkbox
                    onChange={selectAllRow}
                    checked={selection.length === data.length}
                    indeterminate={
                      selection.length > 0 && selection.length !== data.length
                    }
                  />
                </Table.Th>
              )}

              {config.fields.map((field, index) => (
                <Th
                  key={`${field.label}${index}`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Text fw={600} component="span">
                    {field.label}
                  </Text>
                </Th>
              ))}

              {(config.actions || config.handler) && (
                <Th
                  style={{
                    position: "sticky",
                    right: 0,
                    backgroundColor: "var(--mantine-color-body)",
                    zIndex: 2,
                  }}
                >
                  <Text fw={600} component="span">
                    Actions
                  </Text>
                </Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading ? (
              <Table.Tr>
                <Table.Td
                  colSpan={
                    config.fields.length +
                    (config.actions ? 1 : 0) +
                    (config.selectionCheckbox ? 1 : 0)
                  }
                >
                  <MainLoader />
                </Table.Td>
              </Table.Tr>
            ) : rows.length > 0 ? (
              rows
            ) : (
              <Table.Tr>
                <Table.Td
                  colSpan={
                    config.fields.length +
                    (config.actions ? 1 : 0) +
                    (config.selectionCheckbox ? 1 : 0)
                  }
                >
                  <Text
                    fw={500}
                    component="span"
                    style={{ display: "block", textAlign: "center" }}
                  >
                    Nothing found
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </Paper>
  );
};

export default DataTable;
