import { Loader } from "@mantine/core";
import classes from "./Loader.module.css";

const MainLoader = () => {
  return (
    <div className={classes.mainloader}>
      <Loader color="orange" size="lg" />
    </div>
  );
};

export default MainLoader;
