import classes from "./PriceCard.module.css";

const PriceCard = ({ cover, index, currency }) => {
  const bgColors = ["#FFECED", "#E5F7F8", "#F6F0E6", "#E8F2FC"];
  const colors = ["#FB374D", "#00A7AF", "#F89500", "#0085FF"];

  const bgColor = bgColors[index % colors.length];
  const color = colors[index % colors.length];

  return (
    <div className={classes.cover_card} style={{ backgroundColor: bgColor }}>
      <div className={classes.card_top} style={{ backgroundColor: color }}>
        <p className={classes.cover_type}>
          {cover.type.charAt(0).toUpperCase() + cover.type.slice(1)}
        </p>
      </div>

      <div className={classes.card_body}>
        <span>Total</span>
        <p className={classes.price}>
          <span>{currency}</span>
          {cover.price}
        </p>
      </div>
    </div>
  );
};

export default PriceCard;
