export const addUserOptions = (newUser) => {
  return {
    // optimistic data displays until we populate cache
    // param is previous data
    optimisticData: (data) => {
      console.log(data);
      const updatedResults = [...(data || []), newUser];
      console.log(updatedResults);

      return [...data];
    },
    rollbackOnError: true,
    // response from API request is 1st param
    // previous data is 2nd param
    populateCache: (added, data) => {
      const updatedResults = [...(data || []), added];
      console.log(updatedResults);

      return [...data];
    },
    revalidate: false,
  };
};

export const deleteUserOptions = (id) => {
  return {
    optimisticData: (data) => {
      const updatedResults = [...(data?.results || [])].filter((user) => {
        return user.id !== +id;
      });

      return {
        ...data,
        results: updatedResults.sort((a, b) => b.id - a.id),
      };
    },
    rollbackOnError: true,
    populateCache: (data) => {
      const updatedResults = [...(data?.results || [])].filter((user) => {
        return user.id !== +id;
      });

      return {
        ...data,
        results: updatedResults.sort((a, b) => b.id - a.id),
      };
    },
    revalidate: false,
  };
};
