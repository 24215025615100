import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Tabs from "../../../common/components/Tabs/Tabs";
import MainLoader from "../../../common/components/Loader/Loader";

const endpoint = "auth-api/user-create/";

const UserLayout = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(
    location.pathname.split("/").pop()
  );

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
    mutate: mutateUser,
  } = useSWR(`${endpoint}${id}`, fetcher, { dedupingInterval: 60000 });

  // const currentTab = location.pathname.split("/").pop();

  const handleNavigation = (value) => {
    setCurrentTab(value);
    navigate(`/users/${id}/${value}`);
  };

  const tabsConfig = [
    {
      value: "profile",
      label: "Profile",
      icon: "/images/svg/profile.svg",
      roles: ["is_superuser"],
    },
    {
      value: "covers",
      label: "Covers",
      icon: "/images/svg/covers.svg",
      roles: ["is_superuser"],
    },
    {
      value: "wallet",
      label: "Wallet",
      icon: "/images/svg/wallet.svg",
      roles: ["is_superuser"],
    },
    {
      value: "payments",
      label: "Payments",
      icon: "/images/svg/policy.svg",
      roles: ["is_superuser"],
    },
    {
      value: "policies-issued",
      label: "Policies Issued",
      icon: "/images/svg/comission.svg",
      roles: ["is_superuser"],
    },
  ];

  useEffect(() => {
    setCurrentTab(location.pathname.split("/").pop());
  }, [location]);

  if (isUserLoading) {
    return <MainLoader />;
  }

  return (
    <Tabs
      // defaultValue="profile"
      currentTab={currentTab}
      onChange={handleNavigation}
      tabs={tabsConfig}
    >
      <Outlet context={{ user, userError, mutateUser }} />
    </Tabs>
  );
};

export default UserLayout;
