import { useDispatch } from "react-redux";
import { logout } from "../../authSlice";
import axiosPrivate from "../../../../services/axiosPrivate";

const useAuthLogout = (refreshToken) => {
  const dispatch = useDispatch();

  const authLogout = async () => {
    try {
      await axiosPrivate.post("/auth-api/logout/", {
        refresh_token: refreshToken,
      });
      dispatch(logout());
    } catch (error) {
      console.log(error.reponse);
    }
  };

  return authLogout;
};

export default useAuthLogout;
