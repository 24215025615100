import useSWR from "swr";
import { fileFetcher } from "../../../../services/fetcher";
import OperatorProfileForm from "../../../operators/forms/OperatorProfileForm/OperatorProfileForm";
import MainLoader from "../../../../common/components/Loader/Loader";

const OperatorAccount = ({ operator, onSubmit }) => {
  const isDev = import.meta.env.MODE === "development";
  const baseURL = isDev ? import.meta.env.VITE_API_ENDPOINT : "";

  const { data: logoData, isLoading: isLogoLoading } = useSWR(
    operator.logo ? `${baseURL}${operator.logo}` : null,
    fileFetcher
  );

  const { data: panData, isLoading: isPanLoading } = useSWR(
    operator.pan_card ? `${baseURL}${operator.pan_card}` : null,
    fileFetcher
  );

  const { data: gstData, isLoading: isGstLoading } = useSWR(
    operator.gst ? `${baseURL}${operator.gst}` : null,
    fileFetcher
  );

  const isLoading = isLogoLoading || isPanLoading || isGstLoading;

  const extendedOperator = {
    ...operator,
    logo: operator.logo && logoData,
    pan_card: operator.pan_card && panData,
    gst: operator.gst && gstData,
  };

  if (isLoading) {
    return <MainLoader />;
  }

  return <OperatorProfileForm data={extendedOperator} onSubmit={onSubmit} />;
};

export default OperatorAccount;
