import { useForm } from "react-hook-form";
import dayjs from "dayjs";

const useFilters = ({ onFilterChange }) => {
  const form = useForm();

  const onSubmit = (formData) => {
    const queryParams = Object.entries(formData).reduce((acc, [key, value]) => {
      if (value) {
        if (Array.isArray(value) && value.length > 0 && key !== "date_range") {
          value.forEach((val) => {
            acc.push(`${key}=${encodeURIComponent(val)}`);
          });
        } else if (key === "ordering") {
          acc.push(`ordering=${encodeURIComponent(value)}`);
        } else if (
          Array.isArray(value) &&
          value.length > 0 &&
          key === "date_range"
        ) {
          const [start_date, end_date] = value;

          const formatStartDate = start_date
            ? dayjs(start_date).format("YYYY-MM-DD")
            : null;

          const formatEndDate = end_date
            ? dayjs(end_date).format("YYYY-MM-DD")
            : null;

          if (formatStartDate) {
            acc.push(`start_date=${encodeURIComponent(formatStartDate)}`);
          }

          if (formatEndDate) {
            acc.push(`end_date=${encodeURIComponent(formatEndDate)}`);
          }
        } else {
          acc.push(`${key}=${encodeURIComponent(value)}`);
        }
      }
      return acc;
    }, []);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    onFilterChange(queryString);
  };

  return {
    form,
    onSubmit,
  };
};

export default useFilters;
