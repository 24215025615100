import { useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { Button, Flex, Modal, Text, Grid, Paper, Title, Divider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import AddEditBenefits from "../../forms/AddEditBenefits";
import BenefitCard from "../../components/BenefitCard/BenefitsCard";
import MainLoader from "../../../../../common/components/Loader/Loader";
import {
  errorNotifications,
  successNotifications,
} from "../../../../../utils/handleNotifications";
import classes from "./Benefits.module.css";

const endpoint = "cover-api/benefits/";

const Benefits = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [editingBenefit, setEditingBenefit] = useState(null);
  const [benefitId, setBenefitId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
  const [benefitToDelete, setBenefitToDelete] = useState(null);

  const { data, isLoading, error, mutate } = useSWR(endpoint, fetcher);

  const { data: benefitData } = useSWR(
    benefitId ? `${endpoint}${benefitId}/` : null,
    fetcher
  );

  const addBenefitMutation = async (benefit) => {
    setIsSubmitting(true);
    try {
      await fetcher(endpoint, "POST", benefit);
      await mutate();
      successNotifications("Success! Benefit added");
      close();
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateBenefitMutation = async (benefit) => {
    setIsSubmitting(true);
    try {
      await fetcher(`${endpoint}${benefit.id}/`, "PATCH", benefit);
      await mutate();
      successNotifications("Success! Benefit edited");
      close();
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsSubmitting(false);
      setBenefitId(null);
      setEditingBenefit(null);
    }
  };

  const deleteBenefitMutation = async (benefitId) => {
    setIsSubmitting(true);
    try {
      await fetcher(`${endpoint}${benefitId}/`, "DELETE");
      await mutate();
      successNotifications("Success! Benefit deleted");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (data) => {
    if (editingBenefit) {
      updateBenefitMutation({ ...editingBenefit, ...data });
    } else {
      addBenefitMutation(data);
    }
  };

  const handleEdit = (benefit) => {
    setEditingBenefit(benefit);
    setBenefitId(benefit.id);
    open();
  };

  const handleDelete = (benefitId) => {
    setBenefitToDelete(benefitId);
    openDeleteModal();
  };

  const confirmDelete = () => {
    if (benefitToDelete) {
      deleteBenefitMutation(benefitToDelete);
    }
  };

  if (isLoading) {
    return <MainLoader />;
  }

  if (error) {
    return <p>Something went wrong</p>;
  }

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        mb={"lg"}
      >
        <Button
          variant="outline"
          mt="xl"
          onClick={() => {
            setBenefitId(null);
            setEditingBenefit(null);
            open();
          }}
        >
          + Add Benefits
        </Button>
      </Flex>

      <Paper className="paper">
        <Title className={classes.title}>Benefits List</Title>
        <Grid mt={"xl"} mb="md" className={classes.heading}>
          <Grid.Col span={3}>
            <Text className={classes.code}>Code</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text className={classes.benefit}>Benefits</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text className={classes.action}>Description</Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <Text className={classes.action}>Action</Text>
          </Grid.Col>
        </Grid>
        <Divider />
        <div className={classes.card}>
          {data.map((benefit) => (
            <BenefitCard
              key={benefit.id}
              benefit={benefit}
              onEdit={() => handleEdit(benefit)}
              onDelete={() => handleDelete(benefit.id)}
            />
          ))}
        </div>
      </Paper>

      <Modal
        opened={opened}
        onClose={close}
        size="50rem"
        centered
        title={editingBenefit ? "Edit Benefit" : "Add Benefit"}
        closeButtonProps={{
          icon: (
            <img
              src="/images/svg/close.svg"
              style={{ width: "1.6rem" }}
              alt="close-icon"
            />
          ),
        }}
      >
        <AddEditBenefits
          onSubmit={onSubmit}
          closeModal={close}
          initialData={benefitData || null}
          isSubmitting={isSubmitting}
        />
      </Modal>

      <Modal
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        title={<span style={{ color: "#333", fontFamily: "Montserrat", fontSize: "14px", fontWeight: "600", lineHeight: "normal" }}>Confirm Deletion</span>}
        centered
      >
        <Text className={classes.head}>Are you sure you want to delete this benefit?</Text>
        <Flex justify="center" mt="lg">
          <Button variant="outline" color="gray" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button color="red" ml="md" onClick={confirmDelete}>
            Delete
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default Benefits;