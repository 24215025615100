import { Button, SimpleGrid, Select, TextInput } from "@mantine/core";
import { useForm, isEmail } from "@mantine/form";
import classes from "./Profile.form.module.css";

const ProfileForm = ({ onSubmit, data }) => {
  const form = useForm({
    initialValues: {
      email: data?.user?.email || "",
      tel_no: data?.tel_no || "",
      first_name: data?.user?.first_name || "",
      last_name: data?.user?.last_name || "",
      gender: data?.gender || "",
      date_of_birth: data?.date_of_birth || "",
      address: data?.address || "",
      city: data?.city || "",
      pin: data?.pin || "",
      state: data?.state || "",
      country: data?.country || "",
    },

    validate: {
      email: isEmail("Invalid email"),
    },
  });

  return (
    <>
      <p className={classes["heading"]}>Basic Info</p>
      <form
        className={classes["login-form"]}
        onSubmit={form.onSubmit(() => {
          onSubmit(form.values);
        })}
      >
        <SimpleGrid
          cols={{ base: 1, xs: 2, sm: 2, lg: 2 }}
          spacing={{ base: 10, xs: "xl", sm: "xl" }}
          className={classes.operatorProfileForm}
        >
          <TextInput
            placeholder={"Enter user first name"}
            label={"First Name"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("first_name")}
          />

          <TextInput
            placeholder={"Enter user last name"}
            label={"Last name"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("last_name")}
          />

          <TextInput
            placeholder={"Enter user email"}
            label={"Email address"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("email")}
          />

          <TextInput
            placeholder={"Enter your date of birth"}
            label={"Date of birth"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("date_of_birth")}
          />

          <Select
            label="Gender"
            data={["Male", "Female", "Other"]}
            placeholder="Select gender"
            {...form.getInputProps("gender")}
          />

          <TextInput
            placeholder={"Enter user phone number"}
            label={"Phone number"}
            type={"number"}
            size={"sm"}
            {...form.getInputProps("tel_no")}
          />
          <TextInput
            placeholder={"Enter your address"}
            label={"Address"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("address")}
          />
          <TextInput
            placeholder={"Enter your pincode"}
            label={"Pin"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("pin")}
          />
          <TextInput
            placeholder={"Enter your city"}
            label={"City"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("city")}
          />
          <TextInput
            placeholder={"Enter your state"}
            label={"State"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("state")}
          />
          <TextInput
            placeholder={"Enter your country"}
            label={"Country"}
            type={"text"}
            size={"sm"}
            {...form.getInputProps("country")}
          />
        </SimpleGrid>

        <div className="button-wrapper" style={{ textAlign: "center" }}>
          <Button variant="gradient" type="submit" mt="xl" size="md">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default ProfileForm;
