import useSWR from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { SimpleGrid, Text } from "@mantine/core";
import MainLoader from "../../../../../common/components/Loader/Loader";
import VendorCard from "../../../components/VendorCard/VendorCard";

const vendorEndpoint = "vendor/policy_providers/";

const VendorList = () => {
  const { isLoading, data: item, error } = useSWR(vendorEndpoint, fetcher);

  if (isLoading) {
    return <MainLoader />;
  }

  if (error) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <SimpleGrid 
        cols={{ base: 1, xs: 1, sm: 2, md: 3 }} 
        spacing="lg"
    >
      {item.map((vendor) => (
        <VendorCard key={vendor.id} data={vendor} />
      ))}
    </SimpleGrid>
  );
};

export default VendorList;
