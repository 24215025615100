import { Controller } from "react-hook-form";
import { NumberInput } from "@mantine/core";

const TableInput = ({ name, control, value, readOnly }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <NumberInput
          {...field}
          defaultValue={value}
          decimalScale={2}
          allowNegative={false}
          hideControls
          disabled={readOnly}
          onChange={field.onChange}
        />
      )}
    />
  );
};

export default TableInput;
