import { useState, useEffect } from "react";
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  TextInput,
  rem,
  Paper,
  Button,
  Checkbox,
  Divider,
  Flex,
} from "@mantine/core";
import iconSearch from "../../../assets/svg/search.svg";
import MainLoader from "../Loader/Loader";
import TableInput from "../TableInput/TableInput";
import TableSelect from "../TableSelect/TableSelect";
import { USER_TYPES } from "../../../utils/userTypes";

import classes from "./FormDataTable.module.css";

function Th({ children, onSort }) {
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

function sortData(data, payload) {
  const { sortBy, reversed } = payload;

  if (!sortBy) {
    return data;
  }

  return [...data].sort((a, b) => {
    const aValue = getNestedValue(a, sortBy);
    const bValue = getNestedValue(b, sortBy);

    if (typeof aValue === "string" && typeof bValue === "string") {
      return reversed
        ? bValue.localeCompare(aValue)
        : aValue.localeCompare(bValue);
    } else if (typeof aValue === "number" && typeof bValue === "number") {
      return reversed ? bValue - aValue : aValue - bValue;
    } else {
      return 0;
    }
  });
}

const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const FormDataTable = ({
  data,
  config,
  heading,
  loading,
  setSelection,
  selection,
  handleSelection,
  onSubmitRow,
  control,
  userType,
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  useEffect(() => {
    setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection }));
  }, [data, sortBy, reverseSortDirection]);

  const selectRow = (id) =>
    setSelection((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    );

  const selectAllRow = () =>
    setSelection((current) =>
      current.length === data.length ? [] : data.map((item) => item.id)
    );

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const rows = sortedData.map((row, index) => (
    <Table.Tr key={index}>
      {config.selectionCheckbox && (
        <Table.Td>
          <Checkbox
            radius="sm"
            checked={selection.includes(row.id)}
            onChange={() => selectRow(row.id)}
          />
        </Table.Td>
      )}

      {config.fields.map((field) => (
        <Table.Td key={`${row.id}-${field.key}`}>
          {field.isInput ? (
            <TableInput
              name={`${field.key}_${row.id}`}
              control={control}
              value={getNestedValue(row, field.key)}
              readOnly={userType !== USER_TYPES.SUPERUSER}
            />
          ) : field.isSelect ? (
            <TableSelect
              name={`${field.key}_${row.id}`}
              control={control}
              options={field.options}
              value={getNestedValue(row, field.key)}
              readOnly={userType !== USER_TYPES.SUPERUSER}
            />
          ) : (
            <Text
              size="sm"
              my={"9px"}
              fw={field.key === config.fields[0].key && 600}
              styles={() => ({
                root: {
                  color:
                    field.key === config.fields[0].key ? "inherit" : "#777",
                },
              })}
            >
              {getNestedValue(row, field.key)}
            </Text>
          )}
        </Table.Td>
      ))}

      {config.actions && (
        <Table.Td>
          {config.actions.map((action, index) => (
            <Button
              key={index}
              variant="light"
              onClick={() => {
                if (typeof action.to === "function") {
                  action.to(row);
                }
              }}
            >
              {action.icon && <img src={action.icon} />}
              {`${action.label}`}
            </Button>
          ))}
        </Table.Td>
      )}

      {control && userType === USER_TYPES.SUPERUSER && (
        <Table.Td>
          <Button variant="outline" onClick={() => onSubmitRow(row)}>
            Update
          </Button>
        </Table.Td>
      )}
    </Table.Tr>
  ));

  const headerStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "var(--mantine-color-body)",
    transition: "box-shadow 150ms ease",
    boxShadow: scrolled ? "var(--mantine-shadow-sm)" : "none",
  };

  return (
    <Paper>
      <Group justify="space-between">
        <Group className={classes.header} justify="flex-start">
          <Text className={classes.heading}>{heading}</Text>
        </Group>
        <Group className={classes.header} justify="flex-end">
          <TextInput
            placeholder="Search by any field"
            mb="md"
            leftSection={
              <img
                style={{ width: rem(16), height: rem(16) }}
                src={iconSearch}
              />
            }
            value={search}
            onChange={handleSearchChange}
          />
        </Group>
      </Group>

      <ScrollArea
        h={config.scrollable ? 320 : ""}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          miw={700}
          layout="fixed"
          withRowBorders={false}
        >
          <Table.Thead style={headerStyle}>
            <Table.Tr>
              {config.selectionCheckbox && (
                <Table.Th style={{ width: rem(40) }}>
                  <Checkbox
                    onChange={selectAllRow}
                    radius={"sm"}
                    checked={selection.length === data.length}
                    indeterminate={
                      selection.length > 0 && selection.length !== data.length
                    }
                  />
                </Table.Th>
              )}

              {config.fields.map((field) => (
                <Th
                  key={field.key}
                  sorted={sortBy === field.key}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting(field.key)}
                >
                  <Text fw={600}>{field.label}</Text>
                </Th>
              ))}

              {control && userType === USER_TYPES.SUPERUSER && (
                <Th>
                  <Text fw={600}>Actions</Text>
                </Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading ? (
              <Table.Tr>
                <Table.Td
                  colSpan={
                    config.fields.length +
                    (config.actions ? 1 : 0) +
                    (config.selectionCheckbox ? 1 : 0)
                  }
                >
                  <MainLoader />
                </Table.Td>
              </Table.Tr>
            ) : rows.length > 0 ? (
              rows
            ) : (
              <Table.Tr>
                <Table.Td
                  colSpan={
                    config.fields.length +
                    (config.actions ? 1 : 0) +
                    (config.selectionCheckbox ? 1 : 0)
                  }
                >
                  <Text fw={500} ta="center">
                    Nothing found
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
      {config.selectionCheckbox && (
        <>
          <Divider mb={"lg"} />
          <Flex
            mih={50}
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
            mb={"lg"}
          >
            <Button variant="outline" mb={"lg"} onClick={handleSelection}>
              Save
            </Button>
          </Flex>
        </>
      )}
    </Paper>
  );
};

export default FormDataTable;
