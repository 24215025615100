import { useState } from "react";
import { Link } from "react-router-dom";
import { Group, Box, Collapse, Text, UnstyledButton, rem, Image } from "@mantine/core";
import classes from "./SidebarLinksGroup.module.css";
import IconChevronRight from "../../../assets/svg/rightarrowfilled.svg";

// Icon mapping based on labels
const iconMapping = {
  Dashboard: "/images/svg/dashboard.svg",
  Covers: "/images/svg/plan.svg",
  "Master Wallet": "/images/svg/wallets.svg",
  Vendors: "/images/svg/vendor.svg",
  Operators: "/images/svg/Operators.svg",
  Payments: "/images/svg/payment.svg",
  Issuance: "/images/svg/issuance.svg",
  Users: "/images/svg/user.svg",
  Wallet: "/images/svg/wallets.svg",
  "Assign Plans": "/images/svg/plan.svg",
  "Assign Covers": "/images/svg/plan.svg",
  "Commission": "/images/svg/commission.svg",
};

export function LinksGroup({
  label,
  initiallyOpened,
  links,
  currentPath,
  link,
  collapsed,
}) {

  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const isActive =
    currentPath.includes(link) ||
    (hasLinks &&
      links.some(
        (subLink) =>
          currentPath === subLink.link ||
          currentPath === subLink.link.slice(0, -1) ||
          currentPath.startsWith(subLink.link) ||
          currentPath.startsWith(subLink.link.slice(0, -1))
      ));

  const items = (hasLinks ? links : []).map((subLink) => (
    <Text
      component={Link}
      to={subLink.link}
      className={`${classes.link} ${
        currentPath === subLink.link ? classes.activeLink : ""
      }`}
      key={subLink.label}
    >
      {subLink.label}
    </Text>
  ));

  // Get the icon based on the label
  const iconSrc = iconMapping[label] || "/images/svg/default-icon.svg"; 

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={`${classes.control} ${
          isActive ? classes.activeControl : ""
        }`}
      >
        <Group
          component={hasLinks ? null : Link}
          to={`${link}`}
          justify="space-between"
          gap={0}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Image
              src={iconSrc}
              alt={`${label} icon`}
              height={20}
              className={`${isActive ? classes.activeIcon : ""}`}
              ml="md"
            />
            {!collapsed && <Box ml="md">{label}</Box>}
          </Box>
          {hasLinks && !collapsed && (
            <img
              className={`${classes.chevron} ${
                isActive ? classes.whiteIcon : ""
              }`}
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? "rotate(-90deg)" : "none",
              }}
              src={IconChevronRight}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks && !collapsed ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

const SidebarLinksGroup = ({ item, currentPath, collapsed }) => {
  return (
    <Box>
      <LinksGroup {...item} currentPath={currentPath} collapsed={collapsed} />
    </Box>
  );
};

export default SidebarLinksGroup;

