import axios from "axios";

// Define endpoint constants
const BASE_URL = `${import.meta.env.VITE_API_ENDPOINT}${
  import.meta.env.VITE_API_VERSION
}`;

// Axios instance with base URL
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export { axiosInstance, axiosPrivate };
