import { useCallback, useState, useMemo } from "react";
import useSWR from "swr";
import { Tabs } from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import DataWrapper from "../../../covers/components/DataWrapper/DataWrapper";
import {
  filterOptions as baseFilterOptions,
  sortOptions,
} from "../../utils/trips";

const Policies = () => {
  const tripEndpoint = `issuance/trips/`;
  const travellerEndpoint = "issuance/travellers/";
  const resendDocEndpoint = `issuance/generate-or-send-document/`;
  const operatorsEndpoint = `issuance/trips/?operator_policy=true`;
  const operators = "operator/operator-profiles/";

  const [currentSelection, setCurrentSelection] = useState([]);

  const { data: operatorData, isLoading: isOperatorsLoading } = useSWR(
    operators,
    fetcher
  );

  const operatorOptions = useMemo(() => {
    if (!operatorData) return;

    if (operatorData) {
      return operatorData.map((operator) => ({
        name: operator.operator_name,
        id: operator.operator.id,
      }));
    }
  }, [operatorData]);

  const handleTripDetails = (row) => {
    return `trips/${row.id}`;
  };

  const handleTravellerDetails = (row) => {
    return `travellers/${row.id}`;
  };

  const handleEndorsementDetails = (row) => {
    return `/issuance/endorsement/${row.id}`;
  };

  const handleResendDoc = useCallback(
    async (row) => {
      const { id: traveller_id } = row;

      const payload = {
        traveller_id: traveller_id,
      };

      try {
        await fetcher(resendDocEndpoint, "POST", payload);
        successNotifications("Success! Document resent");
      } catch (error) {
        console.error(error);
        errorNotifications(error.response.data.error, error);
      }
    },
    [resendDocEndpoint]
  );

  const handleDownloadDoc = useCallback(async (row) => {
    try {
      const { cover_document: downloadUrl } = row;

      if (
        !downloadUrl ||
        !downloadUrl.startsWith("https://asc360.s3.amazonaws.com/")
      ) {
        successNotifications("Download URL is not available.");
        return;
      }

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "CoverDoc";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      successNotifications("Error downloading document:", error);
    }
  }, []);

  const handleSelection = (newSelection) => {
    setCurrentSelection(newSelection);
  };

  const handleTravellersExport = async () => {
    if (currentSelection.length === 0) {
      return successNotifications("Please select travellers");
    }

    const payload = { traveller_ids: currentSelection };

    try {
      const response = await fetcher(
        "issuance/export/selected-travellers/",
        "POST",
        payload
      );

      if (response) {
        const csvBlob = new Blob([response], { type: "text/csv" });

        const url = window.URL.createObjectURL(csvBlob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "exported_travellers.csv";

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Travellers exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const handleCoversExport = useCallback(async (row) => {
    const { id: trip_id } = row;

    try {
      const response = await fetcher(
        `issuance/download-cover-documents/?trip_id=${trip_id}`,
        "POST",
        null,
        {},
        { responseType: "arraybuffer" }
      );

      if (response) {
        const zipBlob = new Blob([response], {
          type: "application/zip",
        });

        const url = window.URL.createObjectURL(zipBlob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `ASCTrip${trip_id}.zip`;

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Covers of trips exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  }, []);

  const handleTripsExport = useCallback(async (row) => {
    const { id: trip_id } = row;

    const payload = { trip_id: trip_id };

    try {
      const response = await fetcher(
        "issuance/export/selected-travellers/",
        "POST",
        payload
      );

      if (response) {
        const csvBlob = new Blob([response], { type: "text/csv" });

        const url = window.URL.createObjectURL(csvBlob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "exported_trips.csv";

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Trips exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  }, []);

  const tripConfig = {
    fields: [
      { key: "id", label: "Booking ID" },
      { key: "cover_type", label: "Cover Type" },
      { key: "cover_name", label: "Cover Name" },
      { key: "traveller_from_name", label: "Travel From" },
      { key: "traveller_to_name", label: "Travel To" },
      { key: "no_of_pax", label: "Pax" },
      { key: "tour_operator_name", label: "Tour Operator" },
      {
        key1: "currency",
        key2: "total_amount",
        label: "Total Amount",
        type: "combine",
      },
      { key: "coverage_start_date", label: "Start Date", type: "date" },
      { key: "coverage_end_date", label: "End Date", type: "date" },
      { key: "created_on", label: "Booking Date", type: "datetime" },
      { key: "created_by_name", label: "Issued by" },
      { key: "status", label: "Status", type: "status" },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTripDetails(row),
        isButton: true,
      },
    ],
    handler: [
      {
        label: "Export",
        handler: (row) => handleTripsExport(row),
      },
      {
        label: "Export Cover",
        handler: (row) => handleCoversExport(row),
      },
    ],
  };

  const travellerConfig = {
    fields: [
      { key: "cover_number", label: "Cover Number" },
      { key: "cover_type", label: "Cover Type" },
      { key: "cover_name", label: "Cover Name" },
      { key: "email", label: "Traveller Email" },
      { key: "first_name", label: "Traveller Name" },
      { key: "nationality_name", label: "Nationality" },
      { key: "dob", label: "Date of birth" },
      { key: "cover_issued_dt", label: "Issued date", type: "timestamp" },
      { key: "created_on", label: "Booking date", type: "timestamp" },
      { key: "created_by_name", label: "Issued by" },
      { key: "policy_status_traveller_wise", label: "Status", type: "status" },
    ],
    handler: [
      {
        label: "Resend",
        handler: (row) => handleResendDoc(row),
      },
      {
        label: "Cover Doc",
        handler: (row) => handleDownloadDoc(row),
      },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTravellerDetails(row),
        isButton: true,
      },
      {
        label: "Endorsement",
        to: (row) => handleEndorsementDetails(row),
      },
    ],
    selectionCheckbox: true,
    selectionActions: [{ label: "Export", handler: handleTravellersExport }],
  };

  const filterOptions = useMemo(
    () => [
      ...baseFilterOptions,
      {
        label: "Operator",
        value: "operator_id",
        type: "select",
        options: operatorOptions,
      },
    ],
    [operatorOptions]
  );

  return (
    <>
      <Tabs
        variant="pills"
        defaultValue="trips"
        styles={(theme) => ({
          tab: {
            fontWeight: 600,
            marginBottom: "1rem",
            border: `1px solid ${theme.colors.gray[4]}`,
          },
          tabLabel: {
            padding: "0 20px",
          },
        })}
      >
        <Tabs.List>
          <Tabs.Tab value="trips" fw={600}>
            Bookings
          </Tabs.Tab>
          <Tabs.Tab value="operators" fw={600}>
            Operators Bookings
          </Tabs.Tab>
          <Tabs.Tab value="travellers" fw={600}>
            Travellers
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="trips">
          <DataWrapper
            endpoint={tripEndpoint}
            config={tripConfig}
            filterOptions={isOperatorsLoading ? [] : filterOptions}
            sortOptions={sortOptions}
          />
        </Tabs.Panel>

        <Tabs.Panel value="travellers">
          <DataWrapper
            endpoint={travellerEndpoint}
            config={travellerConfig}
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            onSelectHandler={handleSelection}
          />
        </Tabs.Panel>

        {/* Operators */}
        <Tabs.Panel value="operators">
          <DataWrapper
            endpoint={operatorsEndpoint}
            config={tripConfig}
            filterOptions={isOperatorsLoading ? [] : filterOptions}
            sortOptions={sortOptions}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default Policies;
