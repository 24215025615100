import { Card, Image, Group, Text, Switch } from "@mantine/core";
import { Link } from "react-router-dom";
import SplitButton from "../../../../common/components/Popup/Popup";
import "./userCardStyles.css";

const UserCard = ({ item, handleSwitch }) => {
  const { user_profile_info, id } = item;
  const { first_name, last_name, email, is_active } = user_profile_info.user;

  return (
    <Card className="card">
      <div style={{ display: "flex" }}>
        <div className="image-container">
          <Card.Section>
            <Image
              src="/images/svg/userpro.svg"
              width={46}
              height={110}
              style={{
                padding: "8px",
                objectFit: "contain",
                marginTop: "6px",
                borderRadius: "8px",
                border:"1px solid #BEBEBE",
                background: "#DDD"
              }}
              alt={`${first_name}`}
            />
          </Card.Section>
        </div>
        <div className="menu-icon">
          <SplitButton userId={id} />
        </div>
        <div style={{ flex: "1", marginLeft: "26px" }} className="user-details">
          <Group position="apart">
            <Text weight={500} component="div" className="emp-name">
              <Link to={`${id}/profile`}>{`${first_name} ${last_name}`}</Link>
            </Text>
          </Group>

          <Text size="sm" className="job-title">
            {item.job_title || "\u00A0"}
          </Text>
          <Text size="sm">
            <Link to={`${id}/profile`} className="e-mail">
              {email || "\u00A0"}
            </Link>
          </Text>
          <div style={{ marginTop: "10px" }}>
            <Switch
              checked={is_active}
              onChange={(event) => handleSwitch(event, user_profile_info.user)}
              color={is_active ? "green" : "gray"} 
            />
          </div>
        </div>
      </div>

      <div>
        <Text size="sm" className="emp-heading">
          <span className="emp-detail">Emp Code : </span>
          {item.code || "\u00A0"}
        </Text>
        <Text size="sm" className="emp-heading" style={{ marginTop: "12px" }}>
          <span className="emp-department">Department : </span>
          {item.department_name || "\u00A0"}
        </Text>
      </div>
    </Card>
  );
};

export default UserCard;
