import { useState } from "react";
import { fetcher } from "../../../../services/fetcher";
import { Paper, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import nationalities from "../../../../data/nationality.json";
import countries from "../../../../data/countries.json";
import MasterForm from "../../forms/MasterCoverForm/MasterCoverForm";
import useCoverOptions from "../../../../common/hooks/useCoverOptions/useCoverOptions";
import useAdventureOptions from "../../../../common/hooks/useAdventureOptions/useAdventureOptions";
import useOtherOptions from "../../../../common/hooks/useOtherOptions/useOtherOptions";
import classes from "./AddMasterCover.module.css";
import dayjs from "dayjs";

const AddMasterCover = ({ mode }) => {
  const endpoint = "cover-api/master-covers/";

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { options: adventureOptions, isLoading } = useAdventureOptions(
    "cover-api/adventures/"
  );

  const { options: partnersOptions, isLoading: loadingPartners } =
    useOtherOptions("cover-api/insurance-partners/");

  const { options: coverOptions, isLoading: loadingCovers } = useCoverOptions(
    "cover-api/mother-covers/"
  );

  const addMasterCoverMutation = async (newCover) => {
    setIsSubmitting(true);
    try {
      await fetcher(endpoint, "POST", newCover, {
        "content-type": "multipart/form-data",
      });

      navigate(-1);
      successNotifications("Success! Master covers added");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (payload) => {
    const formData = new FormData();

    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((id) => {
            formData.append(key, id);
          });
        } else {
          if (key === "start_date" || key === "end_date") {
            const formattedDate = dayjs(payload[key]).format("YYYY-MM-DD");
            formData.append(key, formattedDate);
          } else {
            formData.append(key, payload[key]);
          }
        }
      }
    }

    addMasterCoverMutation(formData);
  };

  return (
    <>
      <Paper shadow="xl" className={classes.masterCoverAdd}>
        <Title mb={"lg"} className={classes.headingMasterCover}>
          Create Master Policy
        </Title>
        <MasterForm
          mode={mode}
          nationalities={nationalities}
          countries={countries}
          motherCovers={loadingCovers ? [] : coverOptions}
          adventureOptions={isLoading ? [] : adventureOptions}
          partnersOptions={loadingPartners ? [] : partnersOptions}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
        />
      </Paper>
    </>
  );
};

export default AddMasterCover;
