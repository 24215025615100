import { useNavigate } from "react-router-dom";
import { fetcher } from "../../../../../services/fetcher.js";
import { Paper } from "@mantine/core";
import VendorForm from "../../../forms/AddVendorForm/AddVendorForm/VendorForm.jsx";
import {
  successNotifications,
  errorNotifications} from "../../../../../utils/handleNotifications.js";
import classes from "./AddVendor.module.css";

const endpoint = "vendor/policy_providers/";

const AddVendor = () => {
  const navigate = useNavigate();

  const addVendorMutation = async (newVendor) => {
    try {
      await fetcher(endpoint, "POST", newVendor, {
        "content-type": "multipart/form-data",
      });
      successNotifications("Success! Vendor profile created");
      navigate(-1);
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
    }
  };

  const handleAddVendor = (payload) => {
    const formData = new FormData();

    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((id) => {
            formData.append(key, id);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }
    }

    addVendorMutation(formData);
  };

  return (
    <Paper shadow="xl" className={classes.vendorpagePaper}>
      <VendorForm onSubmit={handleAddVendor} />
    </Paper>
  );
};

export default AddVendor;
