import { useState } from "react";
import { fetcher } from "../../../../services/fetcher";
import { Paper, Group, Button, FileButton, Text, Tabs } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import WithPermission from "../../../auth/components/WithPermission/WithPermission";
import DataWrapper from "../../../covers/components/DataWrapper/DataWrapper";
import ConfirmModal from "../../../../common/components/ConfirmModal/ConfirmModal";
import { filterOptions, sortOptions } from "../../utils/travellers";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";

const UnissuedPolicies = () => {
  const endpoint =
    "issuance/travellers/?policy_status=PENDING&document_status=True";

  const operatorTravellers = `${endpoint}&operator_policy=true`;

  const [opened, { open, close }] = useDisclosure(false);
  const [currentSelection, setCurrentSelection] = useState([]);
  console.log(currentSelection);

  const [travellerCsv, setTravellerCsv] = useState(null);

  const handleSelection = (newSelection) => {
    setCurrentSelection(newSelection);
  };

  const handleFileInput = async (file) => {
    setTravellerCsv(file);
    open();
  };

  const handleModalClose = () => {
    close();
    setTravellerCsv(null);
  };

  const handleTravellerDetails = (row) => {
    return `/issuance/policies/travellers/${row.id}`;
  };

  const handleEndorsementDetails = (row) => {
    return `/issuance/endorsement/${row.id}`;
  };

  const handleImport = async () => {
    if (!travellerCsv) {
      return;
    }

    const formData = new FormData();
    formData.append("file", travellerCsv);

    try {
      await fetcher("issuance/update-traveller-policy/", "POST", formData, {
        "content-type": "multipart/form-data",
      });

      successNotifications("Import successfully");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    } finally {
      setTravellerCsv(null);
    }
  };

  const handleTravellersExport = async () => {
    if (currentSelection.length === 0) {
      return successNotifications("Please select travellers");
    }

    const payload = { traveller_ids: currentSelection };

    try {
      const response = await fetcher(
        "issuance/export/selected-travellers/",
        "POST",
        payload
      );

      if (response) {
        const csvBlob = new Blob([response], { type: "text/csv" });

        const url = window.URL.createObjectURL(csvBlob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "exported_travellers.csv";

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        successNotifications("Travellers exported successfully");
      } else {
        throw new Error("Export failed");
      }
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const travellerConfig = {
    fields: [
      { key: "cover_number", label: "Cover Number" },
      { key: "cover_type", label: "Cover Type" },
      { key: "cover_name", label: "Cover Name" },
      { key: "email", label: "Traveller Email" },
      { key: "first_name", label: "Traveller Name" },
      { key: "nationality_name", label: "Nationality" },
      { key: "dob", label: "Date of birth" },
      { key: "cover_issued_dt", label: "Issued date", type: "datetime" },
      { key: "created_on", label: "Booking date", type: "datetime" },
      { key: "created_by_name", label: "Issued by" },
      { key: "policy_status_traveller_wise", label: "Status", type: "status" },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTravellerDetails(row),
        isButton: true,
      },
      {
        label: "Endorsement",
        to: (row) => handleEndorsementDetails(row),
      },
    ],
    selectionCheckbox: true,
    selectionActions: [{ label: "Export", handler: handleTravellersExport }],
  };

  return (
    <>
      <Tabs
        defaultValue="travellers"
        variant="pills"
        styles={(theme) => ({
          tab: {
            fontWeight: 600,
            marginBottom: "1rem",
            border: `1px solid ${theme.colors.gray[4]}`,
          },
          tabLabel: {
            padding: "0 20px",
          },
        })}
      >
        <Tabs.List>
          <Tabs.Tab value="travellers">Travellers</Tabs.Tab>
          <Tabs.Tab value="operators">Operators Travellers</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="travellers" mt={"md"}>
          <WithPermission roleRequired={["is_superuser", "Issuance Team"]}>
            <Group justify="end" mb={"lg"}>
              <FileButton onChange={handleFileInput} accept=".csv">
                {(props) => (
                  <Button variant="outline" {...props}>
                    Upload CSV
                  </Button>
                )}
              </FileButton>
            </Group>
          </WithPermission>

          <Paper>
            <DataWrapper
              endpoint={endpoint}
              config={travellerConfig}
              filterOptions={filterOptions}
              sortOptions={sortOptions}
              onSelectHandler={handleSelection}
            />
          </Paper>
        </Tabs.Panel>

        <Tabs.Panel value="operators" mt={"md"}>
          <WithPermission roleRequired={["is_superuser", "Issuance Team"]}>
            <Group justify="end" mb={"lg"}>
              <FileButton onChange={handleFileInput} accept=".csv">
                {(props) => (
                  <Button variant="outline" {...props}>
                    Upload CSV
                  </Button>
                )}
              </FileButton>
            </Group>
          </WithPermission>

          <Paper>
            <DataWrapper
              endpoint={operatorTravellers}
              config={travellerConfig}
              filterOptions={filterOptions}
              sortOptions={sortOptions}
              onSelectHandler={handleSelection}
            />
          </Paper>
        </Tabs.Panel>
      </Tabs>

      <ConfirmModal
        opened={opened}
        onClose={handleModalClose}
        onConfirm={handleImport}
        title="Traveller Policy"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        Are you sure you want upload traveller csv{" "}
        {travellerCsv && (
          <Text size="sm" ta="center" mt="sm">
            Picked File: {travellerCsv.name}
          </Text>
        )}
      </ConfirmModal>
    </>
  );
};

export default UnissuedPolicies;
