import { useForm, FormProvider, Controller } from "react-hook-form";
import { Select } from "@mantine/core";
import { useEffect } from "react";
import BaseForm from "../../components/BaseForm/BaseForm";
import { issuedTo } from "../../utils/issuedTo";

const MotherForm = ({
  data,
  nationalities,
  countries,
  partnersOptions,
  adventureOptions,
  onSubmit,
  onSaveAs,
  mode,
  isSubmitting,
}) => {
  const methods = useForm({
    defaultValues: {
      id: "",
      issued_to: "",
      currency: "",
      title: "",
      nationality: [],
      travelling_from: [],
      travelling_to: [],
      adventure: [],
      cover_scope: "",
      max_duration: "",
      min_duration: "",
      age_max: "",
      age_min: "",
      price: "",
      terms_and_conditions: [],
      partner: "",
      start_date: "",
      end_date: "",
    },
    mode: "onChange",
  });

  const {
    reset,
    control,
    formState: { dirtyFields },
  } = methods;

  const isViewMode = mode === "view";

  useEffect(() => {
    if (data) {
      // exclude the travelling_to_names & travelling_from_names fields for setting the form default values
      const excludeKeys = [
        "travelling_to_names",
        "travelling_from_names",
        "nationality_names",
      ];

      const restData = Object.fromEntries(
        Object.entries(data).filter(([key]) => !excludeKeys.includes(key))
      );

      const formatData = {
        ...restData,
        partner: String(data.partner),
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      };

      reset(formatData);
    }
  }, [data, reset]);

  const specificFields = [
    {
      component: () => (
        <Controller
          name="partner"
          control={control}
          rules={{ required: "Partner is required" }}
          render={({ field, fieldState: { error } }) => (
            <Select
              placeholder="Select partner"
              label="Partner"
              size="sm"
              data={partnersOptions}
              {...field}
              disabled={isViewMode}
              error={error?.message}
            />
          )}
        />
      ),
      span: 6,
    },
    {
      component: () => (
        <Controller
          name="issued_to"
          control={control}
          rules={{ required: "Issued to is required" }}
          render={({ field, fieldState: { error } }) => (
            <Select
              placeholder="Issued to"
              label="Issued to"
              size="sm"
              data={issuedTo}
              {...field}
              disabled={isViewMode}
              error={error?.message}
            />
          )}
        />
      ),
      span: 6,
    },
  ];

  return (
    <FormProvider {...methods}>
      <BaseForm
        mode={mode}
        specificFields={specificFields}
        nationalities={nationalities}
        countries={countries}
        adventureOptions={adventureOptions}
        isSubmitting={isSubmitting}
        onSubmit={methods.handleSubmit((data) => onSubmit(data, dirtyFields))}
        onSaveAs={methods.handleSubmit((data) => onSaveAs(data, dirtyFields))}
      />
    </FormProvider>
  );
};

export default MotherForm;
