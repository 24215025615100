import { useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import { Flex, Button, Paper, Box, Center, Image } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useOutletContext } from "react-router-dom";
import DataTable from "../../../../common/components/DataTable/DataTable";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import MainLoader from "../../../../common/components/Loader/Loader";
import WithPermission from "../../../auth/components/WithPermission/WithPermission";
import { USER_TYPES } from "../../../../utils/userTypes";

const assignPlan = "issuance/assign-plan/";
const coversEndpoint = "issuance/covers-and-customize-covers/";

const AssignCover = () => {
  const outletContext = useOutletContext();
  const user = outletContext ? outletContext.user : null;
  const [value, toggle] = useToggle(["add", "view"]);
  const [currentSelection, setCurrentSelection] = useState([]);

  const userEmail = user ? user.user_profile_info.user.email : null;
  const assignPlanUrl = userEmail
    ? `${assignPlan}?user_email=${userEmail}`
    : assignPlan;

  const {
    data: plans,
    error: plansError,
    isLoading: isPlansLoading,
    mutate: mutatePlans,
  } = useSWR(assignPlanUrl, fetcher);

  const {
    data: covers,
    error: coversError,
    isLoading: isCoversLoading,
  } = useSWR(plans ? coversEndpoint : null, fetcher, {
    dedupingInterval: 9000,
  });

  const plansConfig = {
    fields: [
      { key: "title", label: "Name" },
      { key: "cover_scope", label: "Scope" },
      { key: "currency", label: "Currency" },
      { key: "price", label: "Price" },
    ],
  };

  const handleSelection = (newSelection) => {
    setCurrentSelection(newSelection);
  };

  const handleAssignCover = async () => {
    if (!user) {
      return;
    }

    if (currentSelection.length === 0) {
      return successNotifications("Please select covers");
    }

    try {
      const customizeCoverIds = [];

      currentSelection.forEach((id) => {
        if (
          covers.customize_covers.some(
            (customizeCover) => customizeCover.id === id
          )
        ) {
          customizeCoverIds.push(id);
        }
      });

      const currentCustomize = Array.isArray(plans.customize_covers)
        ? plans.customize_covers.map((customize) => customize.id)
        : [];

      const payload = {
        user_email: user.user_profile_info.user.email,
        customize_cover_ids: [...currentCustomize, ...customizeCoverIds],
      };

      await fetcher("issuance/assign-plan/", "POST", payload);
      await mutatePlans();
      toggle();
      successNotifications("Success, Covers assigned");
    } catch (error) {
      console.log(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const config = {
    fields: [
      { key: "title", label: "Name" },
      { key: "cover_scope", label: "Scope" },
      { key: "currency", label: "Currency" },
      { key: "price", label: "Price" },
    ],
    selectionCheckbox: true,
    scrollable: true,
    selectionActions: [{ label: "Save", handler: handleAssignCover }],
  };

  if (isPlansLoading) {
    return <MainLoader />;
  }

  if (plansError) {
    return <p>Failed to load plans</p>;
  }

  // Ensure `plans.customize_covers` is always an array
  const assignedCovers = Array.isArray(plans.customize_covers)
    ? plans.customize_covers
    : [];

  // Filter covers to exclude already assigned ones based on title
  const combinedData = covers
    ? covers.customize_covers.filter(
        (cover) =>
          !assignedCovers.some((assignedCover) => assignedCover.id === cover.id)
      )
    : [];

  const isPlansEmpty = assignedCovers.length === 0;

  const EmptyComponent = () => (
    <Paper shadow="xl" style={{ backgroundColor: "#fff" }}>
      <Box style={{ textAlign: "center", padding: "20vh" }}>
        <Center>
          <Image
            src="/images/add-icon.png"
            width={80}
            height={114}
            alt="avatar"
          />
        </Center>
        <p>No covers available.</p>
        <WithPermission roleRequired={USER_TYPES.SUPERUSER}>
          <Button variant="outline" onClick={() => toggle()}>
            {value === "add" ? "+ Assign Covers" : "View Covers"}
          </Button>
        </WithPermission>
      </Box>
    </Paper>
  );

  return (
    <>
      <WithPermission roleRequired={USER_TYPES.SUPERUSER}>
        <Flex
          mih={50}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"lg"}
        >
          <Button variant="outline" onClick={() => toggle()}>
            {value === "add" ? "+ Assign Covers" : "View Covers"}
          </Button>
        </Flex>
      </WithPermission>

      {value === "add" ? (
        isPlansEmpty ? (
          <EmptyComponent />
        ) : (
          <DataTable
            config={plansConfig}
            data={assignedCovers}
            heading={"Assigned Covers"}
          />
        )
      ) : (
        <DataTable
          config={config}
          data={coversError ? [] : combinedData}
          heading={"Select Covers"}
          loading={isCoversLoading}
          onSelectionChange={handleSelection}
        />
      )}
    </>
  );
};

export default AssignCover;
