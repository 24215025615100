import { useEffect, useMemo, startTransition } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { MultiSelect } from "@mantine/core";
import useSWR from "swr";
import BaseForm from "../../components/BaseForm/BaseForm";
import { fetcher } from "../../../../services/fetcher";

const MasterForm = ({
  data,
  motherCovers,
  nationalities,
  partnersOptions,
  countries,
  adventureOptions,
  onSubmit,
  onSaveAs,
  mode,
  isSubmitting,
}) => {
  const methods = useForm({
    defaultValues: {
      mother_cover: [],
      id: "",
      currency: "",
      title: "",
      nationality: [],
      travelling_from: [],
      travelling_to: [],
      adventure: [],
      cover_scope: "",
      max_duration: "",
      min_duration: "",
      age_max: "",
      age_min: "",
      terms_and_conditions: [],
      partner: "",
      start_date: null,
      end_date: null,
    },
    mode: "onChange",
  });

  const {
    reset,
    control,
    watch,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const selectedMotherCover = watch("mother_cover");

  const { isLoading: isLoadingMotherCover } = useSWR(
    mode === "add" && selectedMotherCover.length > 0
      ? `cover-api/mother-covers/${selectedMotherCover[0]}/`
      : null,
    fetcher,
    {
      onSuccess: (data) => {
        startTransition(() => {
          const {
            title,
            currency,
            cover_scope,
            age_max,
            age_min,
            max_duration,
            min_duration,
            terms_and_conditions,
            nationality,
            travelling_from,
            travelling_to,
            adventure,
            start_date,
            end_date,
          } = data;

          setValue("title", title);
          setValue("currency", currency);
          setValue("cover_scope", cover_scope);
          setValue("age_max", age_max);
          setValue("age_min", age_min);
          setValue("max_duration", max_duration);
          setValue("min_duration", min_duration);
          setValue("terms_and_conditions", terms_and_conditions || []);
          setValue("nationality", nationality);
          setValue("travelling_from", travelling_from);
          setValue("travelling_to", travelling_to);
          setValue("adventure", adventure || []);
          setValue("start_date", start_date ? new Date(start_date) : null);
          setValue("end_date", end_date ? new Date(end_date) : null);
        });
      },
    }
  );

  useEffect(() => {
    if (data) {
      const excludeKeys = [
        "travelling_to_names",
        "travelling_from_names",
        "nationality_names",
      ];

      const restData = Object.fromEntries(
        Object.entries(data).filter(([key]) => !excludeKeys.includes(key))
      );

      const formatData = {
        ...restData,
        mother_cover: data.mother_cover.map(String),
        partner: data.partner.map(String),
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      };

      reset(formatData);
    }
  }, [data, reset]);

  const specificFields = useMemo(
    () => [
      {
        component: () => (
          <Controller
            name="mother_cover"
            control={control}
            rules={{ required: "Mother Cover is required" }}
            render={({ field, fieldState: { error } }) => (
              <MultiSelect
                label={"Mother Cover"}
                placeholder={"Mother Cover"}
                data={motherCovers}
                value={Array.isArray(field.value) ? field.value : []}
                onChange={field.onChange}
                disabled={mode === "view" || isLoadingMotherCover}
                error={error?.message}
              />
            )}
          />
        ),
        span: 6,
      },
      {
        component: () => (
          <Controller
            name="partner"
            control={control}
            rules={{ required: "Partners is required" }}
            render={({ field, fieldState: { error } }) => (
              <MultiSelect
                label={"Partners"}
                placeholder={"Enter partners"}
                data={partnersOptions}
                value={Array.isArray(field.value) ? field.value : []}
                onChange={field.onChange}
                disabled={mode === "view"}
                error={error?.message}
              />
            )}
          />
        ),
        span: 6,
      },
    ],
    [control, motherCovers, partnersOptions, mode, isLoadingMotherCover]
  );

  return (
    <FormProvider {...methods}>
      <BaseForm
        mode={mode}
        specificFields={specificFields}
        nationalities={nationalities}
        countries={countries}
        adventureOptions={adventureOptions}
        isSubmitting={isSubmitting}
        onSubmit={methods.handleSubmit((data) => onSubmit(data, dirtyFields))}
        onSaveAs={methods.handleSubmit((data) => onSaveAs(data, dirtyFields))}
      />
    </FormProvider>
  );
};

export default MasterForm;
