export const TRAVELER_DOC = {
  PASSPORT_FRONT: "passport_front",
  PASSPORT_BACK: "passport_back",
  AADHAR_FRONT: "aadhar_card_front",
  AADHAR_BACK: "aadhar_card_back",
  FLIGHT_TICKET: "flight_ticket",
  RESIDENCE_PROOF: "residence_proof",
};

export const ISSUE_AS = [
  { label: "User", value: "agent" },
  { label: "Operator", value: "operator" },
];

export const COVER_TYPES = {
  MASTER_COVER: "master-covers",
  CUSTOMIZE_COVER: "customize-covers",
};
