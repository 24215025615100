import { useOutletContext } from "react-router-dom";
import { Tabs } from "@mantine/core";
import DataWrapper from "../../../covers/components/DataWrapper/DataWrapper";

const OperatorIssuedPolicies = () => {
  const { operator } = useOutletContext();
  const tripsEndpoint = `issuance/trips/?operator_id=${operator.operator.id}`;
  const travellersEndpoint = `issuance/travellers/?operator_id=${operator.operator.id}`;

  const handleTripDetails = (row) => {
    return `${row.id}`;
  };

  const tripsConfig = {
    fields: [
      { key: "cover_type", label: "Cover Type" },
      { key: "traveller_from_name", label: "From" },
      { key: "traveller_to_name", label: "To" },
      { key: "no_of_pax", label: "Pax" },
      { key: "coverage_start_date", label: "Start Date" },
      { key: "coverage_end_date", label: "End Date" },
    ],
    actions: [
      {
        label: "Details",
        to: (row) => handleTripDetails(row),
        isButton: true,
      },
    ],
  };

  const travellersConfig = {
    fields: [
      { key: "first_name", label: "Name" },
      { key: "cover_number", label: "Cover Number" },
      { key: "cover_type", label: "Cover Type" },
      { key: "nationality_name", label: "Nationality" },
      { key: "email", label: "Email" },
      { key: "dob", label: "DOB" },
    ],
  };

  return (
    <>
      <Tabs defaultValue="trips" variant="pills">
        <Tabs.List>
          <Tabs.Tab value="trips">Trips</Tabs.Tab>
          <Tabs.Tab value="travellers">Travellers</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="trips" mt={"md"}>
          <DataWrapper endpoint={tripsEndpoint} config={tripsConfig} />
        </Tabs.Panel>

        <Tabs.Panel value="travellers" mt={"md"}>
          <DataWrapper
            endpoint={travellersEndpoint}
            config={travellersConfig}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default OperatorIssuedPolicies;
