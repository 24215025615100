// import React from 'react';
import { Image } from "@mantine/core";

const NotificationComponent = () => {
  return (
    <Image
      src="/images/svg/notification-icon.svg"
      height={24}
      style={{ cursor: "pointer" }}
    />
  );
};

export default NotificationComponent;
