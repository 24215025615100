import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  Select,
  SimpleGrid,
  FileInput,
  Switch,
  Button,
  Group,
  Paper,
  Text,
} from "@mantine/core";
import nationalities from "../../../../data/nationality.json";
import { mapDataToOptions } from "../../../../utils/mapDataToOptions";
import { DatePickerInput } from "@mantine/dates";
import WithPermission from "../../../auth/components/WithPermission/WithPermission";

const ViewEditTraveller = ({ data, onSubmit, isEditing, setIsEditing }) => {
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: data,
  });

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const getChangedValues = (formValues) => {
    return Object.keys(formValues).reduce((acc, key) => {
      if (formValues[key] !== data[key]) {
        acc[key] = formValues[key];
      }
      return acc;
    }, {});
  };

  const onSubmitForm = (formValues) => {
    const changedValues = getChangedValues(formValues);
    onSubmit(changedValues);
  };

  const genderOptions = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
    { value: "3", label: "Other" },
  ];

  return (
    <Paper p="lg" my="lg">
      <Text size="xl" weight={700} mb="md">
        Traveller Details
      </Text>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <SimpleGrid cols={3} spacing="lg">
          <Controller
            name="is_primary"
            control={control}
            render={({ field }) => (
              <Switch
                my="lg"
                label="Is Primary"
                checked={field.value}
                onChange={(event) =>
                  field.onChange(event.currentTarget.checked)
                }
                value={field.value}
                disabled={!isEditing}
              />
            )}
          />

          <Controller
            name="nationality_id"
            control={control}
            render={({ field }) => (
              <Select
                label="Nationality"
                placeholder="Nationality"
                data={mapDataToOptions(nationalities)}
                value={field.value?.toString()}
                onChange={(value) => field.onChange(value)}
                searchable
                disabled={!isEditing}
              />
            )}
          />

          <TextInput
            {...register("first_name")}
            label="First Name"
            placeholder="First Name"
            disabled={!isEditing}
          />

          <TextInput
            {...register("last_name")}
            label="Last Name"
            placeholder="Last Name"
            disabled={!isEditing}
          />

          <TextInput
            {...register("email")}
            label="Email"
            type="email"
            placeholder="Email"
            disabled={!isEditing}
          />

          <TextInput
            label="Phone number"
            {...register("phone_number")}
            placeholder="Phone Number"
            disabled={!isEditing}
          />

          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <Select
                label="Gender"
                placeholder="Select gender"
                data={genderOptions}
                value={field.value?.toString()}
                onChange={(value) => field.onChange(value)}
                disabled={!isEditing}
              />
            )}
          />

          <Controller
            name="dob"
            control={control}
            render={({ field }) => (
              <DatePickerInput
                label="Date of birth"
                placeholder="Traveller DOB"
                valueFormat="YYYY MMM DD"
                value={field.value ? new Date(field.value) : null}
                onChange={(date) =>
                  field.onChange(date?.toISOString().split("T")[0])
                }
                disabled={!isEditing}
              />
            )}
          />

          <TextInput
            label="Passport Number"
            {...register("passport_number")}
            placeholder="Passport Number"
            disabled={!isEditing}
          />

          <TextInput
            label="Emergency Name"
            {...register("emergency_name")}
            placeholder="Emergency Contact Name"
            disabled={!isEditing}
          />

          <TextInput
            label="Emergency Contact"
            {...register("emergency_contact")}
            placeholder="Emergency Contact Number"
            disabled={!isEditing}
          />

          <TextInput
            label="Emergency Email"
            {...register("emergency_email")}
            type="email"
            placeholder="Emergency Contact Email"
            disabled={!isEditing}
          />

          <FileInput
            label="Passport Front"
            {...register("passport_front")}
            placeholder="Passport Front"
          />

          <FileInput
            label="Passport Back"
            {...register("passport_back")}
            placeholder="Passport Back"
          />

          <FileInput
            label="Aadhar Card Front"
            {...register("aadhar_card_front")}
            placeholder="Aadhar Card Front"
          />

          <FileInput
            label="Aadhar Card Back"
            {...register("aadhar_card_back")}
            placeholder="Aadhar Card Back"
          />

          <FileInput
            label="Flight Ticket"
            {...register("flight_ticket")}
            placeholder="Flight Ticket"
          />
        </SimpleGrid>

        <WithPermission roleRequired={["is_superuser"]}>
          <Group justify="center" mt="xl">
            <Button variant="outline2" onClick={() => setIsEditing(!isEditing)}>
              {isEditing ? "Cancel" : "Edit"}
            </Button>

            {isEditing && <Button type="submit">Save Changes</Button>}
          </Group>
        </WithPermission>
      </form>
    </Paper>
  );
};

export default ViewEditTraveller;
