import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextInput, Textarea, Grid, Group, Button } from "@mantine/core";

const AddEditBenefits = ({ onSubmit, initialData, isSubmitting }) => {
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (initialData) {
      setValue("id", initialData.id);
      setValue("code", initialData.code);
      setValue("name", initialData.name);
      setValue("description", initialData.description);
    }
  }, [initialData, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid cols={2} grow>
          <Grid.Col span={6}>
            <TextInput
              label="Code"
              placeholder="Create benefit code"
              {...register("code", { required: true })}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <TextInput
              label="Name"
              placeholder="Enter benefit name"
              {...register("name", { required: true })}
            />
          </Grid.Col>

          <Grid.Col>
            <Textarea
              label="Description"
              placeholder="Enter benefit description"
              {...register("description")}
            />
          </Grid.Col>

          <TextInput type="hidden" {...register("id")} />
        </Grid>

        <Group justify="center">
          <Button
            variant="gradient"
            type="submit"
            mt="xl"
            size="md"
            loading={isSubmitting}
            loaderProps={{ type: "dots" }}
          >
            Submit
          </Button>
        </Group>
      </form>
    </>
  );
};

export default AddEditBenefits;
