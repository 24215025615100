import { Group, Text, Image, Grid, HoverCard } from "@mantine/core";
import classes from "./BenefitsCard.module.css";

const BenefitCard = ({ benefit, onEdit, onDelete }) => {
  return (
    <Group
      position="apart"
      align="center"
      style={{ width: "100%", marginTop: "1rem" }}
    >
      <Grid style={{ width: "100%" }}>
        <Grid.Col span={3}>
          <Text className={classes.benefitcode}>{benefit.code}</Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text className={classes.benefitName}>{benefit.name}</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <HoverCard width={280} shadow="md">
            <HoverCard.Target>
              <Text className={classes.benefitName}>
                {benefit.description.length > 20
                  ? `${benefit.description.substring(0, 20)}...`
                  : benefit.description}
              </Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm">{benefit.description}</Text>
            </HoverCard.Dropdown>
          </HoverCard>
        </Grid.Col>
        <Grid.Col span={2}>
          <Group ml={"xs"}>
            <Image
              src="/images/svg/edit-icon.svg"
              alt="edit-icon"
              onClick={onEdit}
              style={{ cursor: "pointer" }}
            />
            <Image
              src="/images/svg/delete-icon.svg"
              style={{ height: '20px', width: '20px', cursor: "pointer" }}
              onClick={onDelete}
            />
          </Group>
        </Grid.Col>
      </Grid>
    </Group>
  );
};

export default BenefitCard;