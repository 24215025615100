import { useSelector } from "react-redux";
import { Paper, Title, Image } from "@mantine/core";
import ChangePasswordForm from "../../forms/ChangePasswordForm/ChangePasswordForm";
import useAuthLogout from "../../../auth/hooks/useAuthLogout/useAuthLogout";
import { errorNotifications, successNotifications } from "../../../../utils/handleNotifications";
import AuthAPI from "../../../auth/endpoints";
import classes from './Handlechangepassword.module.css'

const HandleChangePassword = () => {
  const { refreshToken } = useSelector((state) => state.auth);
  const authLogout = useAuthLogout(refreshToken);

  const handlePassword = async (newPassword) => {
    console.log("handlePassword called with newPassword:", newPassword);
    return new Promise((resolve, reject) => {
      AuthAPI.changePassword(newPassword)
        .then(() => {
          resolve();
          authLogout(refreshToken);
          successNotifications(
            "Success! Password changed successfully, Please Login"
          );
        })
        .catch((error) => {
          errorNotifications(error.response.data, error);
          reject(error);
        });
    });
  };

  return (
    <Paper shadow="xl" radius="md" p="xl" my={"md"}>
      <Title className={classes.userpasswordHeading}>Set New Password</Title>
      <Image
        src="/images/svg/password.svg"
        style={{
          height: "40px",
          width: "40px",
          float: "right",
          position: "relative",
          top: "-26px",
        }}
        className={classes.passwordIcon}
      />
      <p className={classes.subtitle}>
        Your new password must be different from the previously used one
      </p>
      <ChangePasswordForm onSubmit={handlePassword} />
    </Paper>
  );
};

export default HandleChangePassword;
