import classes from "./WalletCard.module.css";
// import { Image } from "@mantine/core";

const WalletCard = ({ balance, type }) => {
  return (
  // <div className={classes.walletContainer}>
    <div className={classes.card}>
      <div className={classes.svgEffect1}>
        <img src="/images/svg/bglayer1.svg" />
      </div>

      <div className={classes.svgEffect2}>
        <img src="/images/svg/bglayer2.svg" />
      </div>

      <div className={classes.inner}>
        <p className={classes.cardTitle}>Wallet</p>
        <p className={classes.cardSubtitle}>Available Balance</p>
        <p className={classes.balance}>
          {type}
          <span className={classes.currency}>{balance}</span>
        </p>
      </div>
    </div>
  // </div>
  );
};

export default WalletCard;