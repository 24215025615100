// // import { useState } from 'react';
// import { Group, TextInput } from '@mantine/core';
// import iconSearch from "../../../../../assets/svg/search.svg";
// import classes from './Search.module.css';

// const SearchComponent = () => {

//   return (
//     <Group className={classes.header} justify="flex-end">
//       <TextInput
//         placeholder="Search by any field"
//         mb="md"
//         leftSection={<img style={{ width: "16px", height: "16px" }} src={iconSearch} alt="search" />}
//       />
//     </Group>
//   );
// };

// export default SearchComponent;


import { useState } from 'react';
import { Group, TextInput } from '@mantine/core';
import iconSearch from "../../../../../assets/svg/search.svg";
import classes from './Search.module.css';

const SearchComponent = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    onSearch(value);
  };

  return (
    <Group className={classes.header} justify="flex-end">
      <TextInput
        placeholder="Search by any field"
        mb="md"
        value={searchQuery}
        onChange={handleInputChange}
        leftSection={<img style={{ width: "16px", height: "16px" }} src={iconSearch} alt="search" />}
      />
    </Group>
  );
};

export default SearchComponent;
