const typeModeOptions = [
  { label: "Pending", value: "pending" },
  { label: "Success", value: "SUCCESS" },
];

const coverTypeOptions = [
  { label: "Domestic", value: "Domestic" },
  { label: "International", value: "International" },
];

export const filterOptions = [
  {
    label: "Cover Type",
    value: "cover_type",
    type: "checkbox",
    multiple: true,
    options: coverTypeOptions,
  },
  {
    label: "Status",
    value: "status",
    type: "checkbox",
    multiple: true,
    options: typeModeOptions,
  },
];

export const sortOptions = [
  {
    label: "Sort By",
    value: "ordering",
    type: "checkbox",
    multiple: false,
    options: [
      { label: "Updated On", value: "updated_on" },
      { label: "Created On", value: "created_on" },
      { label: "Coverage Start Date", value: "coverage_start_date" },
      { label: "Coverage End Date", value: "coverage_end_date" },
      { label: "Total Amount", value: "total_amount" },
      { label: "No. of Pax", value: "no_of_pax" },
    ],
  },
];
