import { Card, Image, Text } from "@mantine/core";
import classes from "./VendorCard.module.css";

const VendorCard = ({ data }) => {
  const isDev = import.meta.env.MODE === "development";
  const vendorLogo = isDev
    ? `${import.meta.env.VITE_API_ENDPOINT}${data.logo}`
    : data.logo;

  return (
    <a href={`/vendor/view/${data.id}`} className={classes.cardLink}>
      <Card padding="lg"  className={classes.card}>
        <Card.Section className={classes.vendorimage}>
          <Image src={vendorLogo} alt="vendor logo" height={40} />
        </Card.Section>

        <div className={classes.body}>
          <div className={classes.details}>
              <Text className={classes.email}>
                {data.email?.trim() ? (
                  <a href={`mailto:${data.email}`}>
                    <Image
                      src="/images/svg/mail-icon.svg"
                      className={classes.mailIcon}
                      alt="mail icon"
                    />
                    {data.email}
                  </a>
                  ) : (
                    <span>&nbsp;</span>
                )}
              </Text> 
              <Text className={classes.phno}>
                {data.email?.trim() ? (
                  <a href={`tel:${data.phone_number}`}>
                    <Image
                      src="/images/svg/call.svg"
                      className={classes.callIcon}
                      alt="mail icon"
                    />
                    {data.phone_number}
                  </a>
                  ) : (
                    <span>&nbsp;</span>
                )}
              </Text> 
              <Text size="sm" className={classes.heading}>
                <span className={classes.detail}>Vendor code: </span>
                {data.code || <span>&nbsp;</span>}  
              </Text>
            
              <Text size="sm" className={classes.heading} style={{ marginTop: "12px" }}>
                {data.policy_provider_type && (
                 <>
                 <span className={classes.detail}>Provider Type: </span>
                 {data.policy_provider_type || <span>&nbsp;</span>}
                 </>
                )}
              </Text> 
          </div>
        </div>
      </Card>
    </a>
  );
};

export default VendorCard;
