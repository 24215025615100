import useWebSocket from "../hooks/useWebSocket/useWebSocket";
import { useSelector } from "react-redux";

const WebSocketComponent = () => {
  const { accessToken } = useSelector((state) => state.auth);
  const { messages, sendMessage } = useWebSocket(
    `wss://dev.asc360.in/ws/notifications/?token=${accessToken}`,
    {
      credentials: "include",
    }
  );

  const handleSendMessage = () => {
    const message = { type: "PING" };
    sendMessage(message);
  };

  return (
    <div>
      <h2>WebSocket Messages</h2>
      <button onClick={handleSendMessage}>Send Ping</button>
      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{JSON.stringify(msg)}</li>
        ))}
      </ul>
    </div>
  );
};

export default WebSocketComponent;
