import { Text, SimpleGrid, Paper, Group } from "@mantine/core";

const CommissionStats = ({ data }) => {
  // Check if data is empty, set default values if true
  const totalCommission = data.length > 0 ? data[0].total_commission_amount : 0;
  const remainingCommission =
    data.length > 0 ? data[0].remaining_commission_amount : 0;
  const dueCommission =
    data.length > 0 ? data[0].total_paid_commission_amount : 0;

  const statsData = [
    {
      label: "Total Commission Earn",
      amount: totalCommission,
      color: "#E8AC13",
      bg: "#FFF7DB",
    },
    {
      label: "Commission Paid",
      amount: dueCommission,
      color: "#00C864",
      bg: "#F1FFF8",
    },
    {
      label: "Commission Due",
      amount: remainingCommission,
      color: "#FF0D00",
      bg: "#FFEDEC",
    },
  ];

  const stats = statsData.map((stat, index) => {
    return (
      <Paper
        withBorder
        radius="12px"
        shadow="sm"
        py="lg"
        px="md"
        bg={stat.bg}
        key={index}
        sx={{ border: "1px solid pink" }}
      >
        <Group>
          <div>
            <Text c={stat.color} fw={700} size="28px" mb={"sm"}>
              ₹ {stat.amount}
            </Text>
            <Text c="#333" size="md" fw={600}>
              {stat.label}
            </Text>
          </div>
        </Group>
      </Paper>
    );
  });

  return (
    <SimpleGrid my={"xl"} cols={{ base: 1, sm: 3 }}>
      {stats}
    </SimpleGrid>
  );
};

export default CommissionStats;
