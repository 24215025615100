import { useEffect, useState } from "react";
import useSWR from "swr";
import { useParams, useNavigate } from "react-router-dom";
import MainLoader from "../../../../../common/components/Loader/Loader";
import { fetcher } from "../../../../../services/fetcher";
import {
  successNotifications,
  errorNotifications,
} from "../../../../../utils/handleNotifications";
import BenefitsTable from "../../components/BenefitsTable/BenefitsTable";
import { useForm } from "react-hook-form";
import { planTypes } from "../../../../../data/covers";

const CoverBenefits = ({ mode }) => {
  const { planType, coverID } = useParams();
  const endpoint = `cover-api/${planType}/${coverID}/benefits/`;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isMotherPlan = planType === planTypes.MOTHER;
  const navigate = useNavigate();

  const {
    data: existingBenefits,
    isLoading: existingBenefitsLoading,
    mutate,
  } = useSWR(endpoint, fetcher);

  const {
    data: benefits = [],
    error: benefitsError,
    isLoading: isBenefitsLoading,
  } = useSWR("cover-api/benefits/", fetcher);

  const maxCoverageEndpoint = !isMotherPlan
    ? `cover-api/${planType}/${coverID}/max-coverage/`
    : null;

  const {
    data: baseBenefits = [],
    error: baseBenefitsError,
    isLoading: baseBenefitsLoading,
  } = useSWR(maxCoverageEndpoint, fetcher);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      rows: [
        {
          benefit: "",
          coverages: [],
          deductible_amount: "",
          currency: "",
          disabled: false,
        },
      ],
      columns: [],
    },
  });

  useEffect(() => {
    if (existingBenefits) {
      const transformedBenefits = existingBenefits.map((benefit) => ({
        ...benefit,
        benefit_id: benefit.id,
        benefit: String(benefit.benefit),
        disabled: mode === "add",
        selected: isMotherPlan ? undefined : true,
      }));

      const columns =
        existingBenefits[0]?.coverages.map((coverage) => ({
          value: coverage.cover_type,
        })) || [];

      if (!isMotherPlan && baseBenefits) {
        let combinedBenefits = [...transformedBenefits];

        if (mode === "add") {
          const uniqueBenefits = baseBenefits
            .filter(
              (baseBenefit) =>
                !existingBenefits.some(
                  (existingBenefit) =>
                    existingBenefit.benefit === baseBenefit.benefit
                )
            )
            .map((baseBenefit) => {
              return {
                ...baseBenefit,
                benefit: String(baseBenefit.benefit),
                disabled: false,
                selected: false,
              };
            });

          combinedBenefits = [...transformedBenefits, ...uniqueBenefits];
        }

        // Integrating max_coverage into the combined benefits
        combinedBenefits = combinedBenefits.map((benefit) => {
          const matchingMaxCoverage = baseBenefits.find(
            (baseBenefit) => baseBenefit.benefit === +benefit.benefit
          );

          return {
            ...benefit,
            coverages: benefit.coverages.map((coverage) => {
              const matchingCoverage = matchingMaxCoverage?.coverages.find(
                (baseCoverage) =>
                  baseCoverage.cover_type === coverage.cover_type
              );

              return {
                ...coverage,
                max_coverage:
                  matchingCoverage?.coverage_amount || coverage.coverage_amount,
              };
            }),
          };
        });

        const columns =
          combinedBenefits[0]?.coverages.map((coverage) => ({
            value: coverage.cover_type,
          })) || [];

        reset({ rows: combinedBenefits, columns });
      } else {
        reset({ rows: transformedBenefits, columns });
      }
    }
  }, [baseBenefitsLoading, existingBenefitsLoading, reset, mode]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    let payload = isMotherPlan
      ? data.rows.filter((item) => !item.disabled)
      : data.rows.filter((item) => !item.disabled && item.selected);

    if (payload && Array.isArray(payload)) {
      payload.forEach((item) => {
        if (item.benefit) {
          item.benefit = Number(item.benefit);
        }

        item.coverages.forEach((coverage) => {
          if (!coverage.cover_type) {
            console.warn("Missing cover_type for coverage:", coverage);
          }
          if (coverage.coverage_amount) {
            coverage.coverage_amount = Number(coverage.coverage_amount);
          }
        });
      });
    }

    try {
      if (mode === "edit") {
        await fetcher(`${endpoint}`, "PATCH", payload);
        successNotifications("Success! Benefits modified");
      } else {
        await fetcher(`${endpoint}`, "POST", payload);
        successNotifications("Success! New Benefits added");
      }

      await mutate();
      navigate(-1);
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDelete = async (benefitID) => {
    console.log(benefitID);

    try {
      await fetcher(`${endpoint}${benefitID}`, "DELETE");
      successNotifications("Success! Benefit deleted");

      await mutate();
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    }
  };

  function transformData(data) {
    if (!data || isBenefitsLoading) {
      return [];
    }

    return data.map((item) => ({
      value: String(item.id),
      label: item.code,
    }));
  }

  const isLoading = isMotherPlan
    ? existingBenefitsLoading
    : baseBenefitsLoading || existingBenefitsLoading;
  const error = isMotherPlan ? null : baseBenefitsError || benefitsError;

  if (isLoading || isBenefitsLoading) {
    return <MainLoader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <BenefitsTable
      mode={mode}
      control={control}
      reset={reset}
      plan={planType}
      benefits={transformData(benefits)}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}
      handleOnDelete={onDelete}
    />
  );
};

export default CoverBenefits;
