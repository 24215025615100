import { useNavigate } from "react-router-dom";
import { fetcher } from "../../../../services/fetcher";
import { Paper } from "@mantine/core";
import AddOperatorForm from "../../forms/AddOperatorForm/AddOperatorForm";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import classes from './AddOperator.module.css';

const endpoint = "operator/operator-profiles/";

const AddOperator = () => {
  const navigate = useNavigate();

  const addOperatorMutation = async (newOperator) => {
    try {
      await fetcher(endpoint, "POST", newOperator, {
        "content-type": "multipart/form-data",
      });
      successNotifications("Success! Operator profile created");
      navigate(-1);
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
    }
  };

  const handleAddOperator = (payload) => {
    const formData = new FormData();

    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((id) => {
            formData.append(key, id);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }
    }

    addOperatorMutation(formData);
  };

  return (
    <Paper shadow="xl" className={classes.operatorWalletPaper}>
      <AddOperatorForm onSubmit={handleAddOperator} />
    </Paper>
  );
};

export default AddOperator;
