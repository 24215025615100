import { Badge } from "@mantine/core";
import "./StatusText.module.css";

const StatusText = ({ status, field }) => {
  let color = "";
  let displayText = "";
  let textColor = "";

  if (field === "is_expired") {
    color = status ? "#E4E4E4" : "#D1FFD9";
    displayText = status ? "Inactive" : "Active";
    textColor = status ? "#999999" : "#24CB3F";
  } else if (field === "link_status") {
    color = status ? "#D1FFD9" : "#E4E4E4";
    displayText = status ? "Active" : "Expired";
    textColor = status ? "#24CB3F" : "#999999";
  } else if (field === "policy_status_traveller_wise") {
    if (status === "Active") {
      color = "#D1FFD9";
      displayText = "Active";
      textColor = "#24CB3F";
    } else if (status === "Matured") {
      color = "#FFEDD1";
      displayText = "Matured";
      textColor = "#FF8A00";
    } else if (status === "Inactive") {
      color = "gray";
      displayText = "In Active";
    } else if (status === "Pending") {
      color = "#FFEDD1";
      displayText = "Pending";
      textColor = "#FF8A00";
    } else if (status === "Expired") {
      color = "#FFE1D8";
      displayText = "Expired";
      textColor = "#FF8A00";
    }
  } else {
    switch (status) {
      case "COMPLETED":
        color = "#D1FFD9";
        displayText = "Success";
        textColor = "#24CB3F";
        break;
      case "SUCCESS":
        color = "#D1FFD9";
        displayText = "Success";
        textColor = "#24CB3F";
        break;
      case "PENDING":
        color = "#FFEDD1";
        displayText = "Pending";
        textColor = "#FF8A00";
        break;
      case "pending":
        color = "#FFEDD1";
        displayText = "Pending";
        textColor = "#FF8A00";
        break;
      case "inactive":
        color = "#E4E4E4";
        displayText = "Inactive";
        break;
      case "cancel":
        color = "#FFE1D8";
        displayText = "Pending";
        break;
      default:
        color = "gray";
        displayText = "Unknown";
    }
  }

  return (
    <Badge
      radius={"4px"}
      fw={600}
      tt="capitalize"
      px={"md"}
      c={textColor}
      color={`${color}`}
    >
      {displayText}
    </Badge>
  );
};

export default StatusText;
