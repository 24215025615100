import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";

const useOptions = (endpoint, mapDataToOptions) => {
  const { data, error, isLoading } = useSWR(endpoint, fetcher);

  let options = [];

  if (!isLoading && !error && data) {
    options = mapDataToOptions(data.results || data);
  }

  return {
    options,
    isLoading: !error && !data,
    error,
  };
};

export default useOptions;
