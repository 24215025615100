import { useEffect } from "react";
import { Table, Title, CopyButton, Tooltip } from "@mantine/core";
import classes from "./CoverpricesTable.module.css";
import useSWR from "swr";
import { fetcher } from "../../../services/fetcher.js";

function CoverPricesTable({ pricingEndpoint, selectedCover }) {
  const { data, error } = useSWR(pricingEndpoint, fetcher);

  useEffect(() => {
    if (selectedCover) {
      console.log("Selected Cover:", selectedCover);
    }
    if (data) {
      console.log("Fetched Data:", data);
    }
  }, [selectedCover, data]);

  if (error) {
    console.error("Error loading data:", error);
    return <div>Failed to load</div>;
  }
  if (!data) return <div>Loading...</div>;

  if (data.length === 0) {
    return <div className={classes.notfound}>Cover pricing not found</div>;
  }

  const groupedData = {};
  const durationsSet = new Set();
  const ageGroupsSet = new Set();
  const typesSet = new Set();

  data.forEach((cover) => {
    const ageGroup = `${cover.age_min}-${cover.age_max} Years`;
    ageGroupsSet.add(ageGroup);
    if (!groupedData[ageGroup]) {
      groupedData[ageGroup] = {};
    }
    if (!groupedData[ageGroup][cover.duration]) {
      groupedData[ageGroup][cover.duration] = {};
    }
    groupedData[ageGroup][cover.duration][cover.type] = cover;
    durationsSet.add(cover.duration);
    typesSet.add(cover.type);
  });

  const durations = Array.from(durationsSet).sort((a, b) => a - b);
  const types = Array.from(typesSet);
  const ageGroups = Array.from(ageGroupsSet);

  const renderAgeGroupWiseTable = () => (
    <>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Age Group</Table.Th>
          {durations.map((duration) => (
            <Table.Th key={duration}>{duration} days</Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {ageGroups.map((ageGroup) => (
          <Table.Tr key={ageGroup}>
            <Table.Td className={classes.agegroupCategory}>{ageGroup}</Table.Td>
            {durations.map((duration) => (
              <Table.Td style={{ textAlign: "center" }} key={duration}>
                {groupedData[ageGroup][duration] ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {types.map((type) =>
                      groupedData[ageGroup][duration][type] ? (
                        <div
                          key={type}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            className={classes.code}
                          >
                            <CopyButton value={"ASC" + groupedData[ageGroup][duration][type].id}>
                              {({ copied, copy }) => (
                                <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="left">
                                  <img
                                    src={copied ? "/images/svg/check.svg" : "/images/svg/copy.svg"}
                                    alt={copied ? "Copied" : "Copy"}
                                    style={{ cursor: "pointer", height: "20px", marginRight: "0.5rem", margin: "0.5rem" }}
                                    onClick={copy}
                                  />
                                </Tooltip>
                              )}
                            </CopyButton>
                            {"ASC" + groupedData[ageGroup][duration][type].id}
                          </div>
                          <div className={classes.coverprice}>
                            {groupedData[ageGroup][duration][type]?.currency || "N/A"}{" "}
                            {groupedData[ageGroup][duration][type]?.price || "N/A"}
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                ) : (
                  "-"
                )}
              </Table.Td>
            ))}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </>
  );

  const renderDurationWiseTable = () => (
    <>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Duration</Table.Th>
          {types.map((type) => (
            <Table.Th key={type}>{type}</Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {durations.map((duration) => (
          <Table.Tr key={duration}>
            <Table.Td className={classes.durationCategory}>
              {duration} days
            </Table.Td>
            {types.map((type) => (
              <Table.Td style={{ textAlign: "center" }} key={type}>
                {groupedData[ageGroups[0]][duration][type] ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className={classes.code}
                    >
                      <CopyButton value={"ASC" + groupedData[ageGroups[0]][duration][type].id}>
                        {({ copied, copy }) => (
                          <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="left">
                            <img
                              src={copied ? "/images/svg/check.svg" : "/images/svg/copy.svg"}
                              alt={copied ? "Copied" : "Copy"}
                              style={{ cursor: "pointer", height: "20px", marginRight: "0.5rem", margin: "0.5rem" }}
                              onClick={copy}
                            />
                          </Tooltip>
                        )}
                      </CopyButton>
                      {"ASC" + groupedData[ageGroups[0]][duration][type].id}
                    </div>
                    <div className={classes.coverprice}>
                      {groupedData[ageGroups[0]][duration][type]?.currency || "N/A"}{" "}
                      {groupedData[ageGroups[0]][duration][type]?.price || "N/A"}
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </Table.Td>
            ))}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </>
  );

  return (
    <>
      <Title className={classes.coverpriceHeading}>Coverprices</Title>
      <div className={classes.tableContainer}>
        <div className={classes.heading}>{selectedCover}</div>
        <Table className={classes.tableBorder}>
          {ageGroups.length > 1 ? renderAgeGroupWiseTable() : renderDurationWiseTable()}
        </Table>
      </div>
    </>
  );
}

export default CoverPricesTable;