export const mapDataToOptions = (data) => {
  if (!data) {
    return;
  }
  return data.map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  }));
};

export const mapCoverDataToOptions = (data) => {
  return data.map(({ id, title }) => ({
    label: title,
    value: id.toString(),
  }));
};

export const mapAdventureOptions = (data) => {
  return data.map(({ id, name }) => ({
    name: name,
    id: id,
  }));
};

export function generateCoversGroup(data) {
  if (!data || data.length === 0) {
    return [];
  }

  const customizeCovers = data.covers[0].customize_covers.map((item) => ({
    value: String(item.id),
    label: item.title,
  }));

  const coversGroup = [{ group: "customize_covers", items: customizeCovers }];

  return coversGroup;
}

export function generateWalletGroup(data) {
  if (!data || data.length === 0) {
    return [];
  }

  const operators = data.operators.map((item) => ({
    value: String(item.id),
    label: item.full_name,
  }));

  const staff = data.staff.map((item) => ({
    value: String(item.id),
    label: item.full_name,
  }));

  const walletGroup = [
    { group: "Operators", items: operators },
    { group: "Staff", items: staff },
  ];

  return walletGroup;
}
