import { useState } from "react";
import { Outlet } from "react-router-dom";
import classes from "./Layout.module.css";
import CustomBreadcrumbs from "../../components/Breadcrumbs/CustomBreadcrumbs";
import HelpComponent from "../../components/Help/Help";
import NotificationComponent from "../../components/Notification/Notification";
import UserMenu from "../../components/UserMenu/UserMenu";
import Sidebar from "../../components/Sidebar/Sidebar";
import Logo from "../../components/Logo/Logo";
import { AppShell, Burger, Group, Image } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import expandIcon from '/images/svg/expand.svg';
import collapseIcon from '/images/svg/collapse.svg';

const Layout = () => {
  const [opened, { toggle }] = useDisclosure();
  const [collapsed, setCollapsed] = useState(false);  // Add collapsed state

  const handleCollapseToggle = () => {
    setCollapsed((prev) => !prev);  // Toggle collapse state
  };

  return (
    <AppShell
      header={{ height: { base: 60, xl: 70 } }}
      navbar={{
        width: collapsed ? 80 : { base: 200, md: 260, lg: 280, xl: 300 },  
        breakpoint: "md",
        collapsed: { mobile: !opened },
      }}
      padding="md"
      withBorder={false}
    >
      <AppShell.Header>
        <Group h="100%" px="lg" justify="space-between" style={{ flex: 1 }}>
          <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="sm" />
          <Logo />
          <div className={classes.headerLogo}>
            <Group ml="xl">
              <HelpComponent />
              <NotificationComponent />
              <UserMenu />
            </Group>
          </div>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar
        p="lg"
        className={opened ? classes.navbarExpanded : classes.navbarCollapsed}
      >
        {/* Sidebar content */}
        <Sidebar collapsed={collapsed} />

        {/* Collapse/Expand button positioned at the bottom-right */}
        <button onClick={handleCollapseToggle} className={classes.collapseButton}>
          <Image
            src={collapsed ? expandIcon : collapseIcon} 
            alt={collapsed ? "Expand" : "Collapse"}
            width={24}
            height={24}
          />
        </button>
      </AppShell.Navbar>
      <AppShell.Main className={classes.main_body}>
        <CustomBreadcrumbs />
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};

export default Layout;
