import { Image } from "@mantine/core";

const HelpComponent = () => {
  return (
    <Image
      src="/images/svg/help.svg"
      height={24}
      style={{ cursor: "pointer" }}
    />
  );
};

export default HelpComponent;
