import { TextInput, Title, SimpleGrid, Group, Button } from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./VendorViewForm.module.css";

const VendorViewForm = ({ data }) => {
  return (
    <>
      <Title mb="lg" className={classes.heading}>
        Basic
      </Title>
      <form className={classes.vendorViewForm}>
        <SimpleGrid cols={{ base: 1, xs: 1, sm: 2, lg: 2 }} spacing="xl">
          <TextInput label="Vendor Name" value={data.name} readOnly />
          <TextInput label="Vendor Code" value={data.code} readOnly />
          {/* <Image src={data.logo} alt="Vendor Logo" style={{ gridColumn: 'span 2' }} /> */}
          <TextInput label="Vendor Country" value={data.country} readOnly />
          <TextInput label="Email" value={data.email} readOnly />
          <TextInput label="Phone Number" value={data.phone_number} readOnly />
          <TextInput label="Tax ID" value={data.tax_id} readOnly />
          <TextInput
            label="Tax Withholding Category"
            value={data.tax_withholding_category}
            readOnly
          />
          <TextInput
            label="Policy Provider Type"
            value={data.policy_provider_type}
            readOnly
          />
          <TextInput label="GST Category" value={data.gst_category} readOnly />
          <TextInput label="PAN" value={data.pan} readOnly />
          <TextInput label="Tax Category" value={data.tax_category} readOnly />
          <TextInput
            label="Account Number"
            value={data.account_number}
            readOnly
          />
          <TextInput label="Bank Name" value={data.bank_name} readOnly />
          <TextInput label="Branch Name" value={data.branch_name} readOnly />
          <TextInput label="IFSC Code" value={data.ifsc_code} readOnly />
          <TextInput label="SWIFT Code" value={data.swift_code} readOnly />
          <TextInput label="IBAN" value={data.iban} readOnly />
          <TextInput label="Bank Country" value={data.bank_country} readOnly />
        </SimpleGrid>
        <Group justify="center">
          <Link to={`/vendor/edit/${data.id}`}>
            <Button variant="outline" type="submit" mt="xl" size="md">
              Edit
            </Button>
          </Link>
          <Button variant="outline" type="submit" mt="xl" size="md" disabled>
            Submit
          </Button>
        </Group>
      </form>
    </>
  );
};

export default VendorViewForm;
