import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  FileInput,
  SimpleGrid,
  Button,
  Select,
  Title,
  Group
} from "@mantine/core";
import countries from "../../../../../data/countries.json";
import classes from "./VendorForm.module.css";


const VendorForm = ({ onSubmit }) => {
  const { register, handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      code: '',
      logo: '',
      country: '',
      email: '',
      phone_number: '',
      tax_id: '',
      tax_withholding_category: '',
      policy_provider_type: '',
      gst_category: '',
      pan: '',
      tax_category: '',
      account_number: '',
      bank_name: '',
      branch_name: '',
      ifsc_code: '',
      swift_code: '',
      iban: '',
      bank_country: '',
      disabled: false
    }   
  });

  return (
    <>
      <Title mb="lg" className={classes.heading}>Basic</Title>
      <form className={classes.vendorForm} onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid cols={{ base: 1, xs: 1, sm: 2, lg: 2 }} spacing={{ base: 10, xs: "xl", sm: "xl" }}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Vendor Name is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                label={"Vendor Name"}
                type="text"
                placeholder="Enter vendor name"
                withAsterisk 
                {...field}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            rules={{ required: "Vendor Code is required" }}
            render={({ field }) => (
              <TextInput
                label={"Vendor Code"}
                type="text"
                placeholder={"Enter vendor code"}
                withAsterisk
                {...field} 
                error={errors.code?.message} 
              />
            )}
          />

          <Controller
            name="logo"
            control={control}
            rules={{ required: "Vendor Logo is required" }}
            render={({ field, fieldState: { error } }) => (
              <FileInput
                label={"Upload Vendor Logo"}
                withAsterisk
                {...field}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="country"
            control={control}
            rules={{ required: "Vendor Country is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                label={"Vendor Country"}
                placeholder={"Select Vendor Country"}
                withAsterisk
                data={countries.map((item) => ({ value: item.id.toString(), label: item.name }))}
                onChange={field.onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                label={"Email"}
                type="text"
                placeholder="Enter Email"
                withAsterisk
                {...field}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="phone_number"
            control={control}
            rules={{
              required: "Phone Number is required",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                label={"Phone Number"}
                type="text"
                placeholder="Enter Phone Number"
                withAsterisk
                {...field}
                error={error?.message}
              />
            )}
          />


          <TextInput
            label={"Tax ID"}
            type="text"
            placeholder={"Enter tax ID"}
            {...register("tax_id")}
          />

         <TextInput
            label={"Tax Withholding Category"}
            type="text"
            placeholder={"Enter tax withholding category"}
            {...register("tax_withholding_category")}
          /> 

          <Controller
            name="policy_provider_type"
            control={control}
            rules={{ required: "Policy Provider Type is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                label={"Policy Provider Type"}
                placeholder={"Select Policy Provider Type"}
                withAsterisk
                data={["Individual", "Company"]}
                onChange={field.onChange}
                error={error?.message}
              />
            )}
          />

          <Select
              label="GST Category"
              placeholder="Select GST category"
              data={[
                { value: "Unregistered", label: "Unregistered" },
                { value: "Registered Regular", label: "Registered Regular" },
                { value: "Registered Composition", label: "Registered Composition" },
                { value: "SEZ", label: "SEZ" },
                { value: "Overseas", label: "Overseas" },
                { value: "UIN Holder", label: "UIN Holder" }
              ]}
              {...register("gst_category")}
          />

          <TextInput
            label={"PAN"}
            type="text"
            placeholder={"Enter PAN"}
            {...register("pan")}
          />

          <Select
            label="Tax Category"
            placeholder="Select tax category"
            data={[
              { value: "In-State", label: "In-State" },
              { value: "Services", label: "Services" },
              { value: "Registered Composition", label: "Registered Composition" },
              { value: "Reverse Charge In-State", label: "Reverse Charge In-State" },
              { value: "Reverse Charge Out-State", label: "Reverse Charge Out-State" }
            ]}
            {...register("tax_category")}
          />


          <TextInput
            label={"Account Number"}
            type="text"
            placeholder={"Enter account number"}
            {...register("account_number")}
          />

          <TextInput
            label={"Bank Name"}
            type="text"
            placeholder={"Enter bank name"}
            {...register("bank_name")}
            error={errors.bank_name?.message}
          />

          <TextInput
            label={"Branch Name"}
            type="text"
            placeholder={"Enter branch name"}
            {...register("branch_name")}
          />

          <TextInput
            label={"IFSC Code"}
            type="text"
            placeholder={"Enter IFSC code"}
            {...register("ifsc_code")}
            error={errors.ifsc_code?.message}
          />

          <TextInput
            label={"SWIFT Code"}
            type="text"
            placeholder={"Enter SWIFT code"}
            {...register("swift_code")}
          />

          <TextInput
            label={"IBAN"}
            type="text"
            placeholder={"Enter IBAN"}
            {...register("iban")}
          />

          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <Select
                label={"Bank Country"}
                placeholder={"Enter bank Country"}
                data={countries.map((item) => ({ value: item.id.toString(), label: item.name }))}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
        </SimpleGrid>
        <Group justify="center">
          <Button variant="outline" type="submit" mt="xl" size="md">
            Submit
          </Button>
        </Group>
      </form>
    </>
  );
};

export default VendorForm;