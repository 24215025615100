import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  Select,
  SimpleGrid,
  FileInput,
  Checkbox,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

const TravelerInfoForm = forwardRef(
  ({ onSubmit, travellerIndex, formData, getRequiredDocs, options }, ref) => {
    const { handleSubmit, watch, control, setValue } = useForm({
      defaultValues: {
        indian_resident: false,
        is_primary: travellerIndex === 0 ? true : false,
        nationality: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        gender: "",
        dob: null,
        passport_number: "",
        emergency_name: "",
        emergency_contact: "",
        emergency_email: "",
        residence_proof: null,
        passport_front: null,
        passport_back: null,
        aadhar_card_front: null,
        aadhar_card_back: null,
        flight_ticket: null,
      },
    });

    const nationality = watch("nationality");

    const [requiredDocuments, setRequiredDocuments] = useState([]);

    const handleTravelerSubmit = (data) => {
      onSubmit(data, travellerIndex);
    };

    useEffect(() => {
      setValue(
        "indian_resident",
        formData.quote_data.residence_status === "yes"
      );
    }, [formData.quote_data.residence_status, setValue]);

    useEffect(() => {
      if (nationality) {
        const docs = getRequiredDocs(
          nationality,
          formData.quote_data.travelling_from,
          formData.quote_data.travelling_to,
          formData.quote_data.residence_status
        );
        setRequiredDocuments(docs);
      }
    }, [
      nationality,
      formData.quote_data.travelling_from,
      formData.quote_data.travelling_to,
      formData.quote_data.residence_status,
      getRequiredDocs,
    ]);

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(handleTravelerSubmit),
    }));

    return (
      <form>
        <SimpleGrid cols={3} spacing={"lg"}>
          <div style={{ display: "none" }}>
            <Controller
              name="indian_resident"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event) =>
                    field.onChange(event.currentTarget.checked)
                  }
                />
              )}
            />
          </div>

          <div style={{ display: "none" }}>
            <Controller
              name="is_primary"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(event) =>
                    field.onChange(event.currentTarget.checked)
                  }
                />
              )}
            />
          </div>

          <Controller
            name="nationality"
            control={control}
            rules={{ required: "Nationality is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                label="Nationality"
                placeholder="Nationality"
                data={options}
                onChange={field.onChange}
                searchable
                error={error?.message}
              />
            )}
          />

          <Controller
            name="first_name"
            control={control}
            rules={{ required: "First Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                label="First Name"
                placeholder="First Name"
                error={error?.message}
              />
            )}
          />

          <Controller
            name="last_name"
            control={control}
            rules={{ required: "Last Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                label="Last Name"
                placeholder="Last Name"
                error={error?.message}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                label="Email"
                type="email"
                placeholder="Email"
                error={error?.message}
              />
            )}
          />

          <Controller
            name="phone_number"
            control={control}
            rules={{ required: "Phone number is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                label="Phone number"
                placeholder="Phone Number"
                error={error?.message}
              />
            )}
          />

          <Controller
            name="gender"
            control={control}
            rules={{ required: "Gender is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                label="Gender"
                placeholder="Select gender"
                data={[
                  { value: "1", label: "Male" },
                  { value: "2", label: "Female" },
                  { value: "3", label: "Other" },
                ]}
                onChange={field.onChange}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="dob"
            control={control}
            rules={{ required: "DOB is required" }}
            render={({ field, fieldState: { error } }) => (
              <DatePickerInput
                label="Date of birth"
                placeholder="traveller dob"
                valueFormat="YYYY MMM DD"
                {...field}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="passport_number"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Passport Number"
                placeholder="Passport Number"
              />
            )}
          />

          <Controller
            name="emergency_name"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Emergency Name"
                placeholder="Emergency Contact Name"
              />
            )}
          />

          <Controller
            name="emergency_contact"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Emergency Contact"
                type="number"
                placeholder="Emergency Contact Number"
              />
            )}
          />

          <Controller
            name="emergency_email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Emergency Email"
                type="email"
                placeholder="Emergency Contact Email"
              />
            )}
          />

          {requiredDocuments.includes("residence_proof") && (
            <Controller
              name="residence_proof"
              control={control}
              rules={{ required: "Residence Proof is required" }}
              render={({ field, fieldState: { error } }) => (
                <FileInput
                  label="Residence Proof"
                  placeholder="Residence Proof"
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          )}

          {requiredDocuments.includes("passport_front") && (
            <Controller
              name="passport_front"
              control={control}
              rules={{ required: "Passport front is required" }}
              render={({ field, fieldState: { error } }) => (
                <FileInput
                  label="Passport Front"
                  placeholder="Passport Front"
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          )}

          {requiredDocuments.includes("passport_back") && (
            <Controller
              name="passport_back"
              control={control}
              rules={{ required: "Passport back is required" }}
              render={({ field, fieldState: { error } }) => (
                <FileInput
                  label="Passport Back"
                  placeholder="Passport Back"
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          )}

          {requiredDocuments.includes("aadhar_card_front") && (
            <Controller
              name="aadhar_card_front"
              control={control}
              rules={{ required: "Aadhar card front is required" }}
              render={({ field, fieldState: { error } }) => (
                <FileInput
                  label="Aadhar Card Front"
                  placeholder="Aadhar Card Front"
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          )}

          {requiredDocuments.includes("aadhar_card_back") && (
            <Controller
              name="aadhar_card_back"
              control={control}
              rules={{ required: "Aadhar card back is required" }}
              render={({ field, fieldState: { error } }) => (
                <FileInput
                  label="Aadhar Card Back"
                  placeholder="Aadhar Card Back"
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          )}

          {requiredDocuments.includes("flight_ticket") && (
            <Controller
              name="flight_ticket"
              control={control}
              rules={{ required: "Flight ticket back is required" }}
              render={({ field, fieldState: { error } }) => (
                <FileInput
                  label="Flight Ticket"
                  placeholder="Flight Ticket"
                  onChange={field.onChange}
                  error={error?.message}
                />
              )}
            />
          )}
        </SimpleGrid>
      </form>
    );
  }
);

TravelerInfoForm.displayName = "TravelerInfoForm";

export default TravelerInfoForm;
