import { ScrollArea } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import WithPermission from "../../../features/auth/components/WithPermission/WithPermission";
import SidebarLinksGroup from "../SidebarLinksGroup/SidebarLinksGroup";
import { superuserMenu, operatorMenu, staffMenu } from "./navbar";
import classes from "./Sidebar.module.css";

const Sidebar = ({ collapsed }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const { user_type } = useSelector((state) => state.auth);

  let menuData;
  switch (user_type && user_type.length > 0 ? user_type[0] : undefined) {
    case "is_superuser":
      menuData = superuserMenu;
      break;
    case "is_operator":
      menuData = operatorMenu;
      break;
    case "is_staff":
      menuData = staffMenu;
      break;
    default:
      menuData = [];
  }

  const links = menuData.map((item) => (
    <WithPermission roleRequired={item.roles} key={item.label}>
      <SidebarLinksGroup item={item} currentPath={currentPath} collapsed={collapsed} />
    </WithPermission>
  ));

  return (
    <ScrollArea
      type="auto"
      scrollbarSize={6}
      offsetScrollbars
      className={`${classes.links} ${collapsed ? classes.collapsed : ""}`}  
    >
      <nav className={classes.navbar}>
        <div className={classes.linksInner}>{links}</div>
      </nav>
    </ScrollArea>
  );
};

export default Sidebar;



