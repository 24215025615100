// import { useFieldArray, Controller, useWatch } from "react-hook-form";
// import {
//   Button,
//   Table,
//   NumberInput,
//   Select,
//   Paper,
//   Group,
//   TextInput,
//   Image,
// } from "@mantine/core";
// import { coverTypes } from "../../../../../data/covers";
// import { useNavigate, useParams } from "react-router-dom";

// function PricingTable({
//   cover,
//   control,
//   reset,
//   onSubmit,
//   onDelete,
//   mode,
//   isSubmitting,
// }) {
//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "rows",
//   });

//   const navigate = useNavigate();
//   const { planType, parentId } = useParams();

//   const isEditMode = mode === "edit";
//   const watchedRows = useWatch({ control, name: "rows" });

//   const handleAddRow = () => {
//     append({
//       age_max: cover?.age_max || "",
//       age_min: cover?.age_min || "",
//       duration: "",
//       price: "",
//       type: coverTypes[0], // Set default type
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Proceed with form submission
//     onSubmit(e);
//   };

//   const handleEditClick = () => {
//     navigate(`/covers/${planType}/${parentId}/pricing/edit`);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <Paper py="lg" mt="lg" shadow="xl">
//         <div style={{ overflowX: "auto", marginTop: "20px" }}>
//           <Table withRowBorders={false} verticalSpacing="md">
//             <Table.Thead>
//               <Table.Tr>
//                 <Table.Th>Type</Table.Th>
//                 <Table.Th>Min age</Table.Th>
//                 <Table.Th>Max age</Table.Th>
//                 <Table.Th>Duration</Table.Th>
//                 <Table.Th>Price</Table.Th>
//                 <Table.Th>Actions</Table.Th>
//               </Table.Tr>
//             </Table.Thead>
//             <Table.Tbody>
//               {fields.map((field, index) => (
//                 <Table.Tr key={field.id}>
//                   {isEditMode && (
//                     <div style={{ display: "none" }}>
//                       <Controller
//                         name={`rows.${index}.price_id`}
//                         control={control}
//                         render={({ field }) => (
//                           <TextInput
//                             type="hidden"
//                             value={field.id}
//                             disabled
//                             {...field}
//                           />
//                         )}
//                       />
//                     </div>
//                   )}

//                   <Table.Td>
//                     <Controller
//                       name={`rows.${index}.type`}
//                       control={control}
//                       render={({ field }) => (
//                         <Select
//                           data={coverTypes}
//                           value={field.value}
//                           onChange={(value) => field.onChange(value)}
//                           placeholder="Variant Type"
//                           searchable
//                           disabled={!isEditMode && watchedRows[index]?.disabled}
//                         />
//                       )}
//                     />
//                   </Table.Td>

//                   <Table.Td>
//                     <Controller
//                       name={`rows.${index}.age_min`}
//                       control={control}
//                       render={({ field }) => (
//                         <NumberInput
//                           {...field}
//                           max={cover?.age_max}
//                           min={cover?.age_min}
//                           allowNegative={false}
//                           allowDecimal={false}
//                           hideControls
//                           placeholder="Enter min age"
//                           disabled={!isEditMode && watchedRows[index]?.disabled}
//                         />
//                       )}
//                     />
//                   </Table.Td>

//                   <Table.Td>
//                     <Controller
//                       name={`rows.${index}.age_max`}
//                       control={control}
//                       render={({ field }) => (
//                         <NumberInput
//                           {...field}
//                           placeholder="Enter max age"
//                           max={cover?.age_max}
//                           min={cover?.age_min}
//                           allowNegative={false}
//                           allowDecimal={false}
//                           hideControls
//                           disabled={!isEditMode && watchedRows[index]?.disabled}
//                         />
//                       )}
//                     />
//                   </Table.Td>

//                   <Table.Td>
//                     <Controller
//                       name={`rows.${index}.duration`}
//                       control={control}
//                       render={({ field }) => (
//                         <NumberInput
//                           {...field}
//                           placeholder="Enter duration"
//                           max={cover?.max_duration}
//                           min={cover?.min_duration}
//                           allowNegative={false}
//                           allowDecimal={false}
//                           hideControls
//                           disabled={!isEditMode && watchedRows[index]?.disabled}
//                         />
//                       )}
//                     />
//                   </Table.Td>

//                   <Table.Td>
//                     <Controller
//                       name={`rows.${index}.price`}
//                       control={control}
//                       render={({ field }) => (
//                         <NumberInput
//                           {...field}
//                           placeholder="Enter cover price"
//                           decimalScale={2}
//                           allowNegative={false}
//                           hideControls
//                           min={cover?.price}
//                           disabled={!isEditMode && watchedRows[index]?.disabled}
//                         />
//                       )}
//                     />
//                   </Table.Td>

//                   {/* Only show the "Edit" button if not in edit mode */}
//                   {/* {!isEditMode && (
//                     <Table.Td>
//                       <Button
//                         variant="outline2"
//                         type="button"
//                         onClick={() => handleEditClick()} // Pass price_id to navigate to edit
//                       >
//                         Edit
//                       </Button>
//                     </Table.Td>
//                   )} */}
//                   {!isEditMode && (
//                     <Table.Td>
//                       <Button
//                         variant="outline2"
//                         type="button"
//                         onClick={() => handleEditClick()}
//                         disabled={
//                           !watchedRows[index]?.type || 
//                           !watchedRows[index]?.age_min || 
//                           !watchedRows[index]?.age_max || 
//                           !watchedRows[index]?.duration || 
//                           !watchedRows[index]?.price
//                         } // Disable if any field is empty
//                       >
//                         Edit
//                       </Button>
//                     </Table.Td>
//                   )}


//                   <Table.Td>
//                     {isEditMode ? (
//                       <Group spacing="xs" align="center">
//                         <Button
//                           variant="outline"
//                           type="button"
//                           onClick={() => onSubmit(index)}
//                           disabled={isSubmitting}
//                         >
//                           Update
//                         </Button>
//                         <Button
//                           variant="outline2"
//                           type="button"
//                           onClick={() => onDelete(field.price_id)}
//                           disabled={isSubmitting}
//                         >
//                           <Image
//                             src="/images/svg/delete-icon.svg"
//                             alt="delete icon"
//                             height={20}
//                           />
//                         </Button>
//                       </Group>
//                     ) : (
//                       <Group spacing="xs" align="center">
//                         <Button
//                           variant="outline2"
//                           type="button"
//                           onClick={() => remove(index)}
//                           disabled={!isEditMode && watchedRows[index]?.disabled}
//                         >
//                           Remove
//                         </Button>
//                       </Group>
//                     )}
//                   </Table.Td>
//                 </Table.Tr>
//               ))}
//             </Table.Tbody>
//           </Table>

//           {/* Actions Buttons */}
//           {!isEditMode && (
//             <Group justify="center" gap="xl" mt="xl">
//               <Button type="button" variant="outline" onClick={handleAddRow}>
//                 Add Row
//               </Button>

//               <Button
//                 loading={isSubmitting}
//                 loaderProps={{ type: "dots" }}
//                 variant="gradient"
//                 type="submit"
//               >
//                 Submit
//               </Button>

//               <Button variant="outline2" type="button" onClick={() => reset()}>
//                 Reset
//               </Button>
//             </Group>
//           )}
//         </div>
//       </Paper>
//     </form>
//   );
// }

// export default PricingTable;



import { useFieldArray, Controller, useWatch } from "react-hook-form";
import {
  Button,
  Table,
  NumberInput,
  Select,
  Paper,
  Group,
  TextInput,
  Image,
} from "@mantine/core";
import { coverTypes } from "../../../../../data/covers";
import { useNavigate, useParams } from "react-router-dom";

function PricingTable({
  cover,
  control,
  reset,
  onSubmit,
  onDelete,
  mode,
  isSubmitting,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "rows",
  });

  const navigate = useNavigate();
  const { planType, parentId } = useParams();

  const isEditMode = mode === "edit";
  const watchedRows = useWatch({ control, name: "rows" });

  const handleAddRow = () => {
    append({
      age_max: cover?.age_max || "",
      age_min: cover?.age_min || "",
      duration: "",
      price: "",
      type: coverTypes[0], // Set default type
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  const handleEditClick = () => {
    navigate(`/covers/${planType}/${parentId}/pricing/edit`);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper py="lg" mt="lg" shadow="xl">
        {/* Wrapper for horizontal scroll */}
        <div style={{ overflowX: "auto", marginTop: "20px" }}>
          <Table withRowBorders={false} verticalSpacing="md" style={{ width: '1000px' }}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ width: "20%" }}>Type</Table.Th>
                <Table.Th style={{ width: "20%" }}>Min age</Table.Th>
                <Table.Th style={{ width: "20%" }}>Max age</Table.Th>
                <Table.Th style={{ width: "20%" }}>Duration</Table.Th>
                <Table.Th style={{ width: "20%" }}>Price</Table.Th>
                <Table.Th style={{ width: "20%" }}>Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {fields.map((field, index) => (
                <Table.Tr key={field.id}>
                  {isEditMode && (
                    <div style={{ display: "none" }}>
                      <Controller
                        name={`rows.${index}.price_id`}
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            type="hidden"
                            value={field.id}
                            disabled
                            {...field}
                          />
                        )}
                      />
                    </div>
                  )}

                  <Table.Td>
                    <Controller
                      name={`rows.${index}.type`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          data={coverTypes}
                          value={field.value}
                          onChange={(value) => field.onChange(value)}
                          placeholder="Variant Type"
                          searchable
                          disabled={!isEditMode && watchedRows[index]?.disabled}
                        />
                      )}
                    />
                  </Table.Td>

                  <Table.Td>
                    <Controller
                      name={`rows.${index}.age_min`}
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          {...field}
                          max={cover?.age_max}
                          min={cover?.age_min}
                          allowNegative={false}
                          allowDecimal={false}
                          hideControls
                          placeholder="Enter min age"
                          disabled={!isEditMode && watchedRows[index]?.disabled}
                        />
                      )}
                    />
                  </Table.Td>

                  <Table.Td>
                    <Controller
                      name={`rows.${index}.age_max`}
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          {...field}
                          placeholder="Enter max age"
                          max={cover?.age_max}
                          min={cover?.age_min}
                          allowNegative={false}
                          allowDecimal={false}
                          hideControls
                          disabled={!isEditMode && watchedRows[index]?.disabled}
                        />
                      )}
                    />
                  </Table.Td>

                  <Table.Td>
                    <Controller
                      name={`rows.${index}.duration`}
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          {...field}
                          placeholder="Enter duration"
                          max={cover?.max_duration}
                          min={cover?.min_duration}
                          allowNegative={false}
                          allowDecimal={false}
                          hideControls
                          disabled={!isEditMode && watchedRows[index]?.disabled}
                        />
                      )}
                    />
                  </Table.Td>

                  <Table.Td>
                    <Controller
                      name={`rows.${index}.price`}
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          {...field}
                          placeholder="Enter cover price"
                          decimalScale={2}
                          allowNegative={false}
                          hideControls
                          min={cover?.price}
                          disabled={!isEditMode && watchedRows[index]?.disabled}
                        />
                      )}
                    />
                  </Table.Td>

                  {!isEditMode && (
                    <Table.Td>
                      <Button
                        variant="outline2"
                        type="button"
                        onClick={() => handleEditClick()}
                        disabled={
                          !watchedRows[index]?.type || 
                          !watchedRows[index]?.age_min || 
                          !watchedRows[index]?.age_max || 
                          !watchedRows[index]?.duration || 
                          !watchedRows[index]?.price
                        } // Disable if any field is empty
                      >
                        Edit
                      </Button>
                    </Table.Td>
                  )}

                  <Table.Td>
                    {isEditMode ? (
                      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                        <Button
                          variant="outline"
                          type="button"
                          onClick={() => onSubmit(index)}
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                        <Button
                          variant="outline2"
                          type="button"
                          onClick={() => onDelete(field.price_id)}
                          disabled={isSubmitting}
                        >
                          <Image
                            src="/images/svg/delete-icon.svg"
                            alt="delete icon"
                            height={20}
                          />
                        </Button>
                      </div>
                    ) : (
                      <Group spacing="xs" align="center">
                        <Button
                          variant="outline2"
                          type="button"
                          onClick={() => remove(index)}
                          disabled={!isEditMode && watchedRows[index]?.disabled}
                        >
                          Remove
                        </Button>
                      </Group>
                    )}
                  </Table.Td>

                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>

          {/* Actions Buttons */}
          {!isEditMode && (
            <Group justify="center" gap="xl" mt="xl">
              <Button type="button" variant="outline" onClick={handleAddRow}>
                Add Row
              </Button>

              <Button
                loading={isSubmitting}
                loaderProps={{ type: "dots" }}
                variant="gradient"
                type="submit"
              >
                Submit
              </Button>

              <Button variant="outline2" type="button" onClick={() => reset()}>
                Reset
              </Button>
            </Group>
          )}
        </div>
      </Paper>
    </form>
  );
}

export default PricingTable;
