import AuthAPI from "../../endpoints";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../authSlice";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const { refreshToken } = useSelector((state) => state.auth);

  const refresh = async () => {
    const response = await AuthAPI.tokenRefresh({
      refresh: refreshToken,
    });
    dispatch(setAuth(response.data));
    return response.data.access;
  };

  return refresh;
};

export default useRefreshToken;
