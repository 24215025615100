import { Routes, Route } from "react-router-dom";
import OperatorWallet from "../features/operators/containers/Wallet/OperatorWallet";
import AssignCoverOperator from "../features/operators/containers/AssignCoverOperator/AssignCoverOperator";
import Commission from "../features/operators/containers/Commission/Commission";
import OperatorPayments from "../features/operators/containers/Payments/Payments";
import TripDetails from "../features/issuance/containers/TripTravellerDetail/TripDetails";
import TravellerDetails from "../features/issuance/containers/TripTravellerDetail/TravellerDetails";

// Issuance
import Issuance from "../features/issuance/pages/Issuance/Issuance";
import TravelerInfo from "../features/issuance/containers/SingleIssuance/TravelerInfo";
import Policies from "../features/issuance/pages/Policies/Policies";
import OperatorIssuedPolicies from "../features/operators/containers/PoliciesIssued/PoliciesIssued";
import QuoteLinks from "../features/issuance/pages/QuoteLinks/QuoteLinks";

const OperatorRoutes = () => (
  <Routes>
    <Route path="wallet" element={<OperatorWallet />} />
    <Route path="covers" element={<AssignCoverOperator />} />
    <Route path="commission" element={<Commission />} />
    <Route path="payments" element={<OperatorPayments />} />
    <Route path="policies-issued" element={<OperatorIssuedPolicies />} />
    <Route path="policies-issued/:id" element={<TripDetails />} />

    {/* Issuance */}
    <Route path="issuance">
      <Route path=":policy_type" element={<Issuance />} />
      <Route path=":policy_type/traveller" element={<TravelerInfo />} />
      <Route path="policies" element={<Policies />} />
      <Route path="policies/trips/:id" element={<TripDetails />} />
      <Route path="policies/travellers/:id" element={<TravellerDetails />} />
      <Route path="quote-links" element={<QuoteLinks />} />
    </Route>
  </Routes>
);

export default OperatorRoutes;
