import { fetcher } from "../../../../services/fetcher";
import useSWR from "swr";
import { useNavigate, useParams } from "react-router-dom";
import AdventureForm from "../components/AdventureForm/AdventureForm";
import { Paper } from "@mantine/core";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import MainLoader from "../../../../common/components/Loader/Loader";
import classes from "./AddViewEditAdventure.module.css";

const AddViewEditAdventure = ({ mode }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const endpoint = "cover-api/adventures/";
  const riskEndpoint = "cover-api/partner-risk-levels/";

  const shouldFetch = mode === "view" || mode === "edit";

  const {
    data: adventure,
    error,
    isLoading,
    mutate,
  } = useSWR(shouldFetch ? `${endpoint}${id}/` : null, fetcher);

  const {
    data: riskLevels = [],
    error: errorRiskLevels,
    isLoading: loadingRiskLevels,
  } = useSWR(riskEndpoint, fetcher);

  console.log(errorRiskLevels);

  const addAdventureMutation = async (newAdventure) => {
    try {
      await fetcher(endpoint, "POST", newAdventure);
      await mutate();
      navigate(-1);
      successNotifications("Success! Adventure added");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data.error, error);
    }
  };

  const editAdventureMutation = async (updatedAdventure) => {
    if (!id) return;

    try {
      await fetcher(`${endpoint}${id}/`, "PATCH", updatedAdventure);
      await mutate();
      successNotifications("Success! Adventure updated");
      navigate(-1);
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data.error, error);
    }
  };

  const handleSubmit = (payload) => {
    const data = {
      ...payload,
      partner_risk_levels: payload.partner_risk_levels.map(Number),
    };

    if (mode === "add") {
      addAdventureMutation(data);
    } else if (mode === "edit") {
      editAdventureMutation(data);
    }
  };

  const formatRiskLevels = (data) => {
    return data.map((item) => ({
      value: String(item.id),
      label: `${item.partner} - ${item.risk_level}`,
    }));
  };

  const initialValues = adventure
    ? {
        ...adventure,
        category: String(adventure.category),
        partner_risk_levels: adventure.partner_risk_levels.map(String),
      }
    : null;

  if (isLoading) {
    return <MainLoader />;
  }

  if (error) {
    return (
      <p>
        {`${error}`} <br /> Something went wrong
      </p>
    );
  }

  return (
    <Paper shadow="xl" className={classes.operatorWalletPaper}>
      <AdventureForm
        mode={mode}
        data={initialValues}
        riskLevels={!loadingRiskLevels && formatRiskLevels(riskLevels)}
        onSubmit={handleSubmit}
      />
    </Paper>
  );
};

export default AddViewEditAdventure;
