import useSWR from "swr";
import {
  Paper,
  Skeleton,
  Grid,
  Button,
  Flex,
  Modal,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import WalletCard from "../../../../common/components/WalletCard/WalletCard";
import WalletTransaction from "../../containers/WalletTransaction/Transaction";
import AddTransactionForm from "../../forms/AddTransactionForm/AddTransactionForm";
import WalletStats from "../../components/WalletStats/WalletStats";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import { generateWalletGroup } from "../../../../utils/mapDataToOptions";
import { fetcher } from "../../../../services/fetcher";

const endpoint = "asc-wallet/master_wallets/";
const addFundEndpoint = "asc-wallet/master_wallet_transactions/";
const userWallets = "asc-wallet/user-wallets-list/";

const MasterWallet = () => {
  const [opened, { open, close }] = useDisclosure(false);

  const { data: wallet, isLoading, error } = useSWR(`${endpoint}`, fetcher);

  const { data, isLoading: isDataLoading } = useSWR(`${userWallets}`, fetcher);

  const addFundMutation = async (fund) => {
    try {
      await fetcher(`${addFundEndpoint}`, "POST", fund);
      successNotifications("Success! Transaction");
      close();
    } catch (error) {
      console.log(error);
      errorNotifications(error.response.data, error);
    }
  };

  const onSubmit = (data) => {
    addFundMutation(data);
  };

  if (error) {
    <p>Something went wrong</p>;
  }

  return (
    <>
      <Paper p="lg" shadow="xl">
        <Grid>
          <Grid.Col span={{ base: 12, xs: 6, sm: 7 }}>
            <Text fw={"600"} size={"lg"} c={"#333"}>
              Master Wallet Details
            </Text>
            <WalletStats
              deposit={wallet && wallet[0].total_deposits}
              withdrawal={wallet && wallet[0].total_withdrawals}
              loading={isLoading}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6, sm: 5 }}>
            {isLoading ? (
              <Skeleton>
                <WalletCard />
              </Skeleton>
            ) : (
              <WalletCard
                balance={wallet[0].total_balance}
                type={wallet[0].currency_type}
              />
            )}
          </Grid.Col>
        </Grid>
      </Paper>

      <Flex justify="end" mt="lg" mb="md">
        <Button variant="outline" mt="xl" onClick={open}>
          + Add Funds
        </Button>
      </Flex>

      <WalletTransaction />

      <Modal
        opened={opened}
        onClose={close}
        size="50rem"
        centered
        title={"Wallet Details"}
        closeButtonProps={{
          icon: (
            <img
              src="/images/svg/close.svg"
              style={{ width: "1.6rem" }}
              alt="close-icon"
            />
          ),
        }}
      >
        <AddTransactionForm
          onSubmit={onSubmit}
          wallets={!isDataLoading && generateWalletGroup(data)}
          closeModal={close}
        />
      </Modal>
    </>
  );
};

export default MasterWallet;
