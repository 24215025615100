import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import MasterForm from "../../forms/MasterCoverForm/MasterCoverForm";
import useAdventureOptions from "../../../../common/hooks/useAdventureOptions/useAdventureOptions";
import useOtherOptions from "../../../../common/hooks/useOtherOptions/useOtherOptions";
import nationalities from "../../../../data/nationality.json";
import countries from "../../../../data/countries.json";
import MainLoader from "../../../../common/components/Loader/Loader";
import {
  successNotifications,
  errorNotifications,
} from "../../../../utils/handleNotifications";
import useCoverOptions from "../../../../common/hooks/useCoverOptions/useCoverOptions";
import { Paper } from "@mantine/core";
import dayjs from "dayjs";

const endpoint = "cover-api/master-covers/";

const ViewEditMasterCover = ({ mode }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    isLoading,
    data: cover,
    mutate,
    error,
  } = useSWR(`${endpoint}${id}`, fetcher, {
    revalidateOnFocus: false,
  });

  const { options: adventureOptions, loadingAdventures } = useAdventureOptions(
    "cover-api/adventures/"
  );

  const { options: partnersOptions, isLoading: loadingPartners } =
    useOtherOptions("cover-api/insurance-partners/");

  const { options: coverOptions, isLoading: loadingCovers } = useCoverOptions(
    "cover-api/mother-covers/"
  );

  // Curried mutation handler
  const handleMutation = (method) => async (formData) => {
    if (!id) return;
    setIsSubmitting(true);

    try {
      await fetcher(`${endpoint}${id}/`, method, formData, {
        "content-type": "multipart/form-data",
      });
      await mutate();
      successNotifications(
        `Success! Master Cover ${
          method === "PATCH" ? "updated" : "copy created"
        }`
      );
      navigate(-1);
    } catch (error) {
      console.error(error);
      errorNotifications(error.response?.data, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to handle form data transformation & trigger mutation
  const processFormData = (mutationType) => (payload, dirtyFields) => {
    const formData = new FormData();

    const fieldsToProcess = mutationType === "POST" ? payload : dirtyFields;

    Object.keys(fieldsToProcess).forEach((key) => {
      if (Array.isArray(payload[key])) {
        payload[key].forEach((id) => {
          formData.append(key, id);
        });
      } else {
        if (key === "start_date" || key === "end_date") {
          const formattedDate = dayjs(payload[key]).format("YYYY-MM-DD");
          formData.append(key, formattedDate);
        } else {
          formData.append(key, payload[key]);
        }
      }
    });

    handleMutation(mutationType)(formData);
  };

  if (isLoading) {
    return <MainLoader />;
  }

  if (error) {
    <p>Something went wrong</p>;
  }

  return (
    <Paper>
      <MasterForm
        mode={mode}
        nationalities={nationalities}
        countries={countries}
        partnersOptions={loadingPartners ? [] : partnersOptions}
        adventureOptions={loadingAdventures ? [] : adventureOptions}
        motherCovers={loadingCovers ? [] : coverOptions}
        data={cover}
        isSubmitting={isSubmitting}
        onSubmit={processFormData("PATCH")}
        onSaveAs={processFormData("POST")}
      />
    </Paper>
  );
};

export default ViewEditMasterCover;
