import { useState } from "react";
import { Menu, Group, ActionIcon, Image, Divider } from "@mantine/core";
import FilterCheckboxGroup from "../FilterCheckboxGroup/FilterCheckboxGroup";
import classes from "./SortMenu.module.css";

export function SortMenu({ form, sortOptions, onSubmit }) {
  const [opened, setOpened] = useState(false);

  const handleClose = () => {
    setOpened(false);
  };
  return (
    <Group justify="center">
      <Menu
        width={300}
        position="top-start"
        transitionProps={{ transition: "pop" }}
        withinPortal
        opened={opened}
        onClose={handleClose}
      >
        <Menu.Target>
          <ActionIcon
            variant={opened ? "outline" : "filled"}
            color={opened ? "orange" : "#F6F6F6"}
            radius={"md"}
            px="lg"
            py="md"
            aria-label="filter"
            onClick={() => setOpened((o) => !o)}
          >
            <img
              src={
                opened
                  ? "/images/svg/color-filter.svg"
                  : "/images/svg/filter.svg"
              }
            />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown className={classes.menu}>
          <Group position="apart" px="xs" mt="xs">
            <span className={classes.heading}>Sorting</span>
            <ActionIcon
              variant="transparent"
              onClick={handleClose}
              style={{ marginLeft: "auto" }}
            >
              <Image src="/images/svg/cross.svg" size={16} />
            </ActionIcon>
          </Group>
          <Divider mt="md" mb="sm" />
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            style={{ padding: "0 0 0 0.6rem" }}
          >
            {sortOptions.map((filter, index) => (
              <FilterCheckboxGroup
                key={index}
                options={filter.options}
                form={form}
                name={filter.value}
                type={filter.type}
                label={filter.label}
                className={classes.label}
                onChange={form.handleSubmit(onSubmit)}
              />
            ))}
          </form>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
