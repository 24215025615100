import { useState } from "react";
import { Checkbox, Radio, Switch, Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { Controller } from "react-hook-form";

const FilterCheckboxGroup = ({
  options,
  type,
  form,
  name,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState([null, null]);

  const renderField = (field) => {
    switch (type) {
      case "checkbox":
        return (
          <Checkbox.Group
            {...field}
            {...props}
            onChange={(value) => {
              field.onChange(value);
              onChange();
            }}
            my={"sm"}
          >
            {options.map((option) => (
              <Checkbox
                key={option.value}
                value={option.value}
                label={option.label}
                radius="sm"
                style={{ marginTop: "0.4em" }}
              />
            ))}
          </Checkbox.Group>
        );

      case "radio":
        return (
          <Radio.Group
            {...field}
            {...props}
            onChange={(value) => {
              field.onChange(value);
              if (onChange) onChange(value);
            }}
          >
            {options.map((option) => (
              <Radio
                key={option.value}
                value={option.value}
                label={option.label}
                style={{ marginTop: "6px" }}
              />
            ))}
          </Radio.Group>
        );

      case "switch":
        return (
          <Switch
            {...field}
            checked={field.value}
            onChange={(event) => {
              const value = event.currentTarget.checked;
              field.onChange(value);
              if (onChange) onChange(value);
            }}
            {...props}
          />
        );

      case "select":
        return (
          <Select
            {...field}
            data={options.map((option) => ({
              value: String(option.id),
              label: option.name,
            }))}
            onChange={(value) => {
              field.onChange(value);
              if (onChange) onChange(value);
            }}
            searchable
            {...props}
          />
        );

      case "date-range":
        return (
          <DatePickerInput
            {...props}
            type="range"
            label="Select Dates"
            placeholder="Start Date - End Date"
            value={value}
            maxDate={new Date()}
            onChange={(val) => {
              setValue(val);
              if (val[0] && val[1]) {
                field.onChange(val);
                if (onChange) onChange(val);
              }
            }}
            allowSingleDateInRange
          />
        );

      default:
        return null;
    }
  };

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => renderField(field)}
    />
  );
};

export default FilterCheckboxGroup;
