import { Flex, Button } from "@mantine/core";
import { Link } from "react-router-dom";
import VendorList from "../container/vendorList/VendorList/VendorList";

const VendorsPage = () => {
  return (
    <>  
      <Flex
          mih={50}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"lg"}
      >
          <Link to={`add`}>
              <Button variant="outline">+ Add Vendor</Button>
          </Link>
      </Flex>
      <VendorList />
    </>
  )
}

export default VendorsPage;
