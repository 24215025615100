import { Text, Paper, Group, Skeleton } from "@mantine/core";

const WalletStats = ({ deposit, withdrawal, loading }) => {
  const statsData = [
    {
      label: "Total Deposit",
      amount: deposit,
      color: "#E8AC13",
      bg: "#FFF7DB",
    },
    {
      label: "Total Withdrawal",
      amount: withdrawal,
      color: "#00C864",
      bg: "#F1FFF8",
    },
  ];

  const stats = statsData.map((stat, index) => {
    return (
      <Skeleton visible={loading} key={index}>
        <Paper
          withBorder
          radius="12px"
          shadow="sm"
          py="lg"
          px="md"
          bg={stat.bg}
          sx={{ border: `1px solid pink` }}
        >
          <Group>
            <div>
              <Text c="#333" size="md" fw={600} mb={"sm"}>
                {stat.label}
              </Text>
              <Text c={stat.color} fw={700} size="28px">
                ₹ {stat.amount}
              </Text>
            </div>
          </Group>
        </Paper>
      </Skeleton>
    );
  });

  return (
    <Group mt={"xl"} grow>
      {stats}
    </Group>
  );
};

export default WalletStats;
