import { useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { Paper, Skeleton, Text } from "@mantine/core";
import VendorEditForm from "../../../forms/VendorEditForm/VendorEditForm";
import {
  successNotifications,
  errorNotifications,
} from "../../../../../utils/handleNotifications";
// import classes from "./EditVendor.module.css";

const endpoint = "vendor/policy_providers/";

const EditVendor = () => {
  const { id } = useParams();
  const { data, error } = useSWR(`${endpoint}${id}/`, fetcher);

  const editVendorMutation = async (updatedVendor) => {
    try {
      await fetcher(`${endpoint}${id}/`, "PATCH", updatedVendor, {
        "content-type": "multipart/form-data",
      });
      successNotifications("Success! Vendor profile updated");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
    }
  };

  const handleEditVendor = (payload) => {
    const formData = new FormData();

    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((id) => {
            formData.append(key, id);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }
    }

    editVendorMutation(formData);
  };

  if (error) {
    return <Text>Something went wrong</Text>;
  }

  if (!data) {
    return <Skeleton height={200} />;
  }

  return (
    <Paper shadow="xl" >
      <VendorEditForm data={data} onSubmit={handleEditVendor} />
    </Paper>
  );
};

export default EditVendor;
