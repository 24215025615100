import { Tabs as MantineTabs, Divider, Group } from "@mantine/core";
import classes from "./Tabs.module.css";
import WithPermission from "../../../features/auth/components/WithPermission/WithPermission";

const Tabs = ({ currentTab, onChange, tabs, children }) => {
  return (
    <MantineTabs
      value={currentTab}
      onChange={onChange}
      variant="unstyled"
      classNames={classes}
    >
      <MantineTabs.List className={classes.tabsList}>
        <Group className={classes.tabsContainer}>
          {tabs.map((tab) => (
            <WithPermission roleRequired={tab.roles} key={tab.value}>
              <MantineTabs.Tab
                value={tab.value}
                leftSection={<img src={tab.icon} alt={`${tab.label} icon`} />}
                className={
                  currentTab === tab.value ? classes.activeTab : classes.tab
                }
              >
                {tab.label}
              </MantineTabs.Tab>
            </WithPermission>
          ))}
        </Group>
      </MantineTabs.List>
      <Divider my="xl" className={classes.divider} />
      {children}
    </MantineTabs>
  );
};

export default Tabs;
