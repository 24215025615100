import { Outlet, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import useRefreshToken from "../../hooks/useRefreshToken/useRefreshToken";
import { logout } from "../../authSlice";
import MainLoader from "../../../../common/components/Loader/Loader";

const PersistLogin = () => {
  const dispatch = useDispatch();
  const refresh = useRefreshToken();
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated, accessToken } = useSelector((state) => state.auth);

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
        if (isMounted) {
          setIsLoading(false);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(logout());
        }
        if (isMounted) {
          setIsLoading(false);
        }
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      return decodedToken.exp * 1000 < Date.now();
    };

    // Avoids unwanted call to verifyRefreshToken
    if (isAuthenticated) {
      if (!accessToken || isTokenExpired(accessToken)) {
        verifyRefreshToken();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }

    return () => (isMounted = false);
  }, [isAuthenticated, accessToken, dispatch, refresh]);

  if (isLoading) {
    return <MainLoader />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default PersistLogin;
