import { useRef } from "react";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { fetcher, fileFetcher } from "../../../../services/fetcher";
import { useOutletContext } from "react-router-dom";
import OperatorProfileForm from "../../forms/OperatorProfileForm/OperatorProfileForm";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import MainLoader from "../../../../common/components/Loader/Loader";

const endpoint = "operator/operator-profiles/";

const OperatorProfile = () => {
  const { operator, mutateOperator } = useOutletContext();
  const navigate = useNavigate();

  const isDev = import.meta.env.MODE === "development";
  const baseURL = isDev ? import.meta.env.VITE_API_ENDPOINT : "";

  // Refs to track fetched files
  const logoFile = useRef(false);
  const panCardFile = useRef(false);
  const gstFile = useRef(false);
  const aadharFile = useRef(false);
  const licenseFile = useRef(false);

  const { data: logoData, isLoading: isLogoLoading } = useSWR(
    operator.logo && !logoFile.current ? `${baseURL}${operator.logo}` : null,
    fileFetcher,
    {
      onSuccess: () => {
        logoFile.current = true;
      },
    }
  );

  const { data: panData, isLoading: isPanLoading } = useSWR(
    operator.pan_card && !panCardFile.current
      ? `${baseURL}${operator.pan_card}`
      : null,
    fileFetcher,
    {
      onSuccess: () => {
        panCardFile.current = true;
      },
    }
  );

  const { data: gstData, isLoading: isGstLoading } = useSWR(
    operator.gst && !gstFile.current ? `${baseURL}${operator.gst}` : null,
    fileFetcher,
    {
      onSuccess: () => {
        gstFile.current = true;
      },
    }
  );

  const { data: aadharData, isLoading: isAadharLoading } = useSWR(
    operator.adhar_card && !aadharFile.current
      ? `${baseURL}${operator.adhar_card}`
      : null,
    fileFetcher,
    {
      onSuccess: () => {
        aadharFile.current = true;
      },
    }
  );

  const { data: licenseData, isLoading: isLicenseLoading } = useSWR(
    operator.license && !licenseFile.current
      ? `${baseURL}${operator.license}`
      : null,
    fileFetcher,
    {
      onSuccess: () => {
        licenseFile.current = true;
      },
    }
  );

  const isLoading =
    isLogoLoading ||
    isPanLoading ||
    isGstLoading ||
    isLicenseLoading ||
    isAadharLoading;

  const updateProfileMutation = async (updateProfile) => {
    try {
      await fetcher(`${endpoint}${operator.uuid}/`, "PUT", updateProfile, {
        "content-type": "multipart/form-data",
      });
      await mutateOperator();
      navigate(-1);
      successNotifications("Success! Profile updated");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
    }
  };

  const handleSubmit = (payload) => {
    const formData = new FormData();

    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((id) => {
            formData.append(key, id);
          });
        } else {
          if (payload[key] instanceof Blob) {
            const fileExtension = payload[key].type.split("/")[1];
            const file = new File([payload[key]], `${key}.${fileExtension}`, {
              type: payload[key].type,
            });
            formData.append(key, file);
          } else {
            formData.append(key, payload[key]);
          }
        }
      }
    }

    updateProfileMutation(formData);
  };

  const extendedOperator = {
    ...operator,
    logo: operator.logo && logoData,
    pan_card: operator.pan_card && panData,
    gst: operator.gst && gstData,
    adhar_card: operator.adhar_card && aadharData,
    license: operator.license && licenseData,
  };

  if (isLoading) {
    return <MainLoader />;
  }

  return (
    <OperatorProfileForm data={extendedOperator} onSubmit={handleSubmit} />
  );
};

export default OperatorProfile;
