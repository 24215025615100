import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { SimpleGrid, Title } from "@mantine/core";
import { Button, MultiSelect, Select, TextInput } from "@mantine/core";
import { adventureCategory } from "../../../../../data/adventures";
import classes from "./AdventureForm.module.css";

const AdventureForm = ({ mode, data, onSubmit, riskLevels }) => {
  const isEditMode = mode === "edit";
  const isViewMode = mode === "view";
  console.log(data);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      id: "",
      name: "",
      category: "",
      partner_risk_levels: "",
    },
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  return (
    <>
      <Title className={classes.heading}>Create Level & Activities</Title>
      <form className={classes.adventureForm} onSubmit={handleSubmit(onSubmit)}>
        {isEditMode && (
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <TextInput
                type="hidden"
                value={field.value}
                disabled
                {...field}
              />
            )}
          />
        )}
        <SimpleGrid
          cols={{ base: 1, xs: 2, sm: 2, lg: 2 }}
          spacing={{ base: 10, xs: "xl", sm: "xl" }}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                placeholder="Enter adventure name"
                label="Name"
                type="text"
                size="sm"
                disabled={isViewMode}
                {...field}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="category"
            control={control}
            rules={{ required: "Category is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                label="Category"
                placeholder="Select adventure category"
                size="sm"
                data={adventureCategory}
                disabled={isViewMode}
                {...field}
                error={error?.message}
              />
            )}
          />

          <Controller
            name="partner_risk_levels"
            control={control}
            rules={{ required: "Partner risk level is required" }}
            render={({ field, fieldState: { error } }) => (
              <MultiSelect
                label="Partner Risk Level"
                placeholder={"Select partner risk level"}
                size="sm"
                data={riskLevels}
                onChange={field.onChange}
                value={Array.isArray(field.value) ? field.value : []}
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        </SimpleGrid>
        <div className={classes.buttonWrapper}>
          <Button variant="gradient" type="submit" mt="xl" size="md">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default AdventureForm;
