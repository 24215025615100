import { useState, useEffect, useCallback } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { fetcher } from "../../../../services/fetcher";
import {
  Flex,
  Pagination,
  Paper,
  Text,
  TextInput,
  SimpleGrid,
} from "@mantine/core";
import DataTable from "../../../../common/components/DataTable/DataTable";
import MainLoader from "../../../../common/components/Loader/Loader";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";

const TripDetails = () => {
  const { id } = useParams();
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tripData, setTripData] = useState(null);

  const endpoint = `issuance/trips/${id}/`;
  const resendDocEndpoint = `issuance/generate-or-send-document/`;

  const { data, isLoading, error } = useSWR(
    `${endpoint}?page=${pageIndex}`,
    fetcher,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (data) {
      if (!tripData) {
        setTripData(data.trip);
      }
      const totalItems = data.trip.no_of_pax;
      const itemsPerPage = 10;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    }
  }, [data, setTotalPages, tripData]);

  const handlePageChange = (page) => {
    setPageIndex(page);
  };

  const handleResendDoc = useCallback(
    async (row) => {
      const traveller = { ...row };
      const payload = {
        traveller_id: traveller.id,
      };

      try {
        await fetcher(resendDocEndpoint, "POST", payload);
        successNotifications("Success! Document resent");
      } catch (error) {
        console.error(error);
        errorNotifications(error.response.data.error, error);
      }
    },
    [resendDocEndpoint]
  );

  const handleDownloadDoc = useCallback(async (row) => {
    try {
      const traveller = { ...row };
      const downloadUrl = traveller.cover_document;

      if (
        !downloadUrl ||
        !downloadUrl.startsWith("https://asc360.s3.amazonaws.com/")
      ) {
        successNotifications("Download URL is not available.");
        return;
      }

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "CoverDoc";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      successNotifications("Error downloading document:", error);
    }
  }, []);

  const config = {
    fields: [
      { key: "cover_number", label: "Cover Number" },
      { key: "cover_type", label: "Type" },
      { key: "cover_name", label: "Cover" },
      { key: "first_name", label: "First Name" },
      { key: "nationality_name", label: "Nationality" },
      { key: "email", label: "Email" },
      { key: "phone_number", label: "Phone" },
      { key: "dob", label: "DOB" },
      { key: "user_name", label: "Issued by" },
    ],
    handler: [
      {
        label: "Resend",
        handler: (row) => handleResendDoc(row),
      },
      {
        label: "Cover Doc",
        handler: (row) => handleDownloadDoc(row),
      },
    ],
  };

  if (isLoading) {
    <MainLoader />;
  }

  if (error) {
    <p>Something went wrong</p>;
  }

  return (
    <>
      <Paper shadow="xs" p="xl" my={"lg"}>
        <Text fw={600} size="lg">
          Trip Details
        </Text>

        <SimpleGrid cols={3} mt={"xl"}>
          <TextInput label="Cover type" value={data?.trip.cover_type} />

          <TextInput label="Cover" value={data?.trip.cover_name} />

          <TextInput
            label="Travelling from"
            value={data?.trip.traveller_from_name}
          />

          <TextInput
            label="Travelling from"
            value={data?.trip.traveller_to_name}
          />

          <TextInput
            label="Start Date"
            value={data?.trip.coverage_start_date}
          />

          <TextInput label="End Date" value={data?.trip.coverage_end_date} />

          <TextInput label="No. of Pax" value={data?.trip.no_of_pax} />

          <TextInput label="Currency" value={data?.trip.currency} />

          <TextInput label="Total Amount" value={data?.trip.total_amount} />

          <TextInput
            label="Operator Name"
            value={data?.trip.tour_operator_name}
          />

          <TextInput
            label="Operator Phone Number"
            value={data?.trip.operator_phone_number}
          />

          <TextInput label="Status" value={data?.trip.status} />
        </SimpleGrid>
      </Paper>

      <DataTable
        config={config}
        data={data ? data.travellers : []}
        loading={isLoading}
      />

      <Flex justify="end" mt="lg">
        <Pagination
          page={pageIndex}
          onChange={handlePageChange}
          total={totalPages}
          defaultValue={pageIndex}
          siblings={1}
        />
      </Flex>
    </>
  );
};

export default TripDetails;
