// import { PasswordInput } from '@mantine/core';
// import classes from './PasswordInput.module.css';

// function CustomPasswordInput({ label, description, placeholder }) {
//   return (
//     <PasswordInput
//       label={label}
//       description={description}
//       placeholder={placeholder}
//       style={{ marginTop: '22px' }}
//       classNames={{ 
//         label: classes.customPasswordInputLabel,
//         input: classes.customPasswordInput
//       }}
//     />
//   );
// }

// export default CustomPasswordInput;


import { PasswordInput } from '@mantine/core';
import classes from './PasswordInput.module.css';

function CustomPasswordInput({ label, description, placeholder, ...props }) {
  return (
    <PasswordInput
      label={label}
      description={description}
      placeholder={placeholder}
      style={{ marginTop: '22px' }}
      classNames={{ 
        label: classes.customPasswordInputLabel,
        input: classes.customPasswordInput
      }}
      {...props} // This will forward all other props to the PasswordInput component
    />
  );
}

export default CustomPasswordInput;
