import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  Select,
  SimpleGrid,
  NumberInput,
  Textarea,
  Group,
  Button,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

const AddTransactionForm = ({ onSubmit, wallets }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  console.log(errors);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid cols={2}>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <NumberInput
                label="Amount"
                placeholder="Enter amount"
                decimalScale={2}
                allowNegative={false}
                hideControls
                {...field}
              />
            )}
          />

          <Controller
            name="payment_mode"
            control={control}
            render={({ field }) => (
              <Select
                label="Payment mode"
                placeholder="select transaction type"
                data={[
                  "CASH",
                  "CARD",
                  "UPI",
                  "COMMISSION",
                  "BANK_TRANSFER",
                  "ONLINE_PAYMENT",
                  "OTHER",
                ]}
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="transaction_type"
            control={control}
            render={({ field }) => (
              <Select
                label="Transaction Type"
                placeholder="select transaction type"
                data={["ADD", "DEDUCT"]}
                onChange={field.onChange}
              />
            )}
          />

          <TextInput
            label="Transaction Number"
            placeholder="Create transaction Number"
            {...register("transaction_number")}
          />

          <Controller
            name="transaction_date"
            control={control}
            render={({ field }) => (
              <DatePickerInput
                label="Transaction Date"
                placeholder="Enter transaction date"
                valueFormat="YYYY MMM DD"
                {...field}
              />
            )}
          />

          <Controller
            name="wallet_id"
            control={control}
            render={({ field }) => (
              <Select
                label="Wallet"
                placeholder="Send to staff, operator"
                data={wallets}
                searchable
                onChange={field.onChange}
              />
            )}
          />

          <Textarea
            label="Description"
            placeholder="Enter payment note if any"
            {...register("description")}
          />
        </SimpleGrid>

        <Group justify="center">
          <Button variant="gradient" type="submit" mt="xl" size="md">
            Submit
          </Button>
        </Group>
      </form>
    </>
  );
};

export default AddTransactionForm;
