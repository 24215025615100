import { axiosInstance } from "../../api/axios";

import axiosPrivate from "../../services/axiosPrivate";

const AUTH_API = "/auth-api";

// Authentication API calls
const AuthAPI = {
  loginOTP: (data) => axiosInstance.post(`${AUTH_API}/login-otp/`, data),
  login: (data) => axiosInstance.post(`${AUTH_API}/login/`, data),
  verifyOTP: (data) => axiosInstance.post(`${AUTH_API}/otp/verify/`, data),
  tokenRefresh: (data) =>
    axiosInstance.post(`${AUTH_API}/token/refresh/`, data),
  changePassword: (data) => {
    console.log(data);
    return axiosPrivate.post(`${AUTH_API}/change-password/`, data);
  },
};

export default AuthAPI;
