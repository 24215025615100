import { useFormContext, Controller } from "react-hook-form";
import { Button, Textarea, Grid } from "@mantine/core";
import TransferList from "../../../../common/components/TransferList/TransferList";
import { TextInput, Select, Group, NumberInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import classes from "./BaseForm.module.css";

const BaseForm = ({
  mode,
  nationalities,
  countries,
  adventureOptions,
  specificFields,
  onSubmit,
  onSaveAs,
  isSubmitting,
}) => {
  const { handleSubmit, control } = useFormContext();
  const isViewMode = mode === "view";

  const handleSaveAs = (event) => {
    event.preventDefault();
    handleSubmit(onSaveAs)();
  };

  return (
    <form
      className={classes.baseForm}
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid grow gutter="lg">
        {specificFields &&
          specificFields.map((field, index) => (
            <Grid.Col span={field.span} key={index}>
              {field.component()}
            </Grid.Col>
          ))}

        <Grid.Col span={{ base: 12 }}>
          <Controller
            name="adventure"
            control={control}
            render={({ field }) => (
              <TransferList
                fieldData={adventureOptions}
                selectedData={[]}
                label="Adventures"
                mode={mode}
                {...field}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Controller
            name="cover_scope"
            control={control}
            rules={{ required: "Cover Scope is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                placeholder="Enter cover scope"
                label="Cover Scope"
                size="sm"
                data={["Domestic", "International"]}
                {...field}
                error={error?.message}
                disabled={isViewMode}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Controller
            name="title"
            control={control}
            rules={{ required: "Cover Title is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                placeholder="Enter Cover Title"
                label="Cover Title"
                size="sm"
                type="text"
                {...field}
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 6, md: 6, lg: 6 }}>
          <Controller
            name="currency"
            control={control}
            rules={{ required: "Currency is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                label="Currency"
                placeholder="Select cover currency"
                size="sm"
                data={["USD", "INR"]}
                {...field}
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Controller
            name="nationality"
            control={control}
            render={({ field }) => (
              <TransferList
                fieldData={nationalities}
                selectedData={[]}
                label="Nationality"
                mode={mode}
                {...field}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Controller
            name="travelling_from"
            control={control}
            render={({ field }) => (
              <TransferList
                fieldData={countries}
                selectedData={[]}
                label="Travelling From"
                mode={mode}
                {...field}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Controller
            name="travelling_to"
            control={control}
            render={({ field }) => (
              <TransferList
                fieldData={countries}
                selectedData={[]}
                label="Travelling To"
                mode={mode}
                {...field}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, md: 3, lg: 3 }}>
          <Controller
            name={`min_duration`}
            control={control}
            rules={{ required: "Min Duration is required" }}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                allowNegative={false}
                allowDecimal={false}
                hideControls
                placeholder="Min Duration"
                label="Minimum Duration"
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, md: 3, lg: 3 }}>
          <Controller
            name={`max_duration`}
            control={control}
            rules={{ required: "Max Duration is required" }}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                allowNegative={false}
                allowDecimal={false}
                hideControls
                placeholder="Max Duration"
                label="Maximum Duration"
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 6, md: 3, lg: 3 }}>
          <Controller
            name={`age_min`}
            control={control}
            rules={{ required: "Age Min is required" }}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                allowNegative={false}
                allowDecimal={false}
                hideControls
                placeholder="Enter minimum age"
                label="Age Min"
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 6, md: 3, lg: 3 }}>
          <Controller
            name={`age_max`}
            control={control}
            rules={{ required: "Age Max is required" }}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                allowNegative={false}
                allowDecimal={false}
                hideControls
                placeholder="Enter maximum age"
                label="Age Max"
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Controller
            name="terms_and_conditions"
            control={control}
            rules={{ required: "Terms & Conditions is required" }}
            render={({ field, fieldState: { error } }) => (
              <Textarea
                placeholder="Enter cover terms & conditions json"
                label="Terms & Conditions"
                autosize
                minRows={3}
                disabled={isViewMode}
                {...field}
                error={error?.message}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Controller
            name="start_date"
            control={control}
            rules={{ required: "Start date is required" }}
            render={({ field, fieldState: { error } }) => (
              <DatePickerInput
                label="Start Date"
                placeholder="start date"
                valueFormat="YYYY MMM DD"
                value={field.value || null}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={error?.message}
                disabled={isViewMode}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Controller
            name="end_date"
            control={control}
            rules={{ required: "End date is required" }}
            render={({ field, fieldState: { error } }) => (
              <DatePickerInput
                label="End Date"
                placeholder="end date"
                valueFormat="YYYY MMM DD"
                value={field.value || null}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={error?.message}
                disabled={isViewMode}
              />
            )}
          />
        </Grid.Col>
      </Grid>

      {!isViewMode && (
        <Group justify="center" className="button-wrapper">
          <Button
            variant="gradient"
            type="submit"
            mt="xl"
            size="md"
            loading={isSubmitting}
            loaderProps={{ type: "dots" }}
          >
            Submit
          </Button>

          <Button
            variant="outline2"
            type="submit"
            mt="xl"
            size="md"
            loading={isSubmitting}
            onClick={handleSaveAs}
            loaderProps={{ type: "dots" }}
          >
            Save As
          </Button>
        </Group>
      )}
    </form>
  );
};

export default BaseForm;
