import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  FileInput,
  Select,
  SimpleGrid,
  Button,
  Title,
  Group
} from "@mantine/core";
import classes from "./AddOperatorForm.module.css"

const AddOperatorForm = ({ onSubmit }) => {
  const { register, handleSubmit, control } = useForm();

  return (
    <div>
      <Title mb="lg" className={classes.heading}>Basic Info</Title>
      <form 
      className={classes.operatorForm}
      onSubmit={handleSubmit(onSubmit)}>
      
        <SimpleGrid cols={{ base: 1, xs: 1, sm: 2, lg: 2 }} spacing={{ base: 10, xs: "xl", sm: "xl" }}>
          <Controller
            name="operator_type"
            control={control}
            render={({ field }) => (
              <Select
                label={"Select operator type"}      
                placeholder={"Select operator type"}
                data={["Domestic", "International"]}
                onChange={field.onChange}
              />
            )}
          />

          <TextInput
            label={"Add operator name"}
            type="text"
            placeholder={"Enter operator name"}
            {...register("operator_name")}
          />

          <TextInput
            label={"Select operator email"}
            type="email"
            placeholder={"Enter operator email"}
            {...register("email")}
          />

          <TextInput
            label={"Select operator phone number"}
            type="number"
            placeholder={"Enter operator phone number"}
            {...register("phone_number")}
          />

          <Controller
            name="logo"
            control={control}
            render={({ field }) => (
              <FileInput
                label={"Upload operator logo"}
                onChange={field.onChange}
              />
            )}
          />

          <TextInput
            label={"Enter POC person"}
            type="text"
            placeholder={"Enter name of poc person"}
            {...register("poc_name")}
          />

          <TextInput
            label={"Enter operator country"}
            type="text"
            placeholder={"Enter operator country"}
            {...register("country")}
          />

          <TextInput
            label={"Enter office address"}
            type="text"
            placeholder={"Enter operator office address"}
            {...register("office_address")}
          />

          <TextInput
            type="hidden"
            value={"Operator"}
            {...register("designation")}
          />

          <TextInput
            type="hidden"
            value={"Operator"}
            {...register("permission")}
          />

          <TextInput
            type="hidden"
            value={"Operators"}
            {...register("department_name")}
          />
        </SimpleGrid>
        <Group justify="center">
          <Button variant="outline" type="submit" mt="xl" size="md">
            Submit
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default AddOperatorForm;
