import { useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { Paper, Skeleton, Text } from "@mantine/core";
import VendorViewForm from "../../../forms/VendorViewForm.jsx/VendorViewForm";
// import classes from "./VendorPage.module.css";

const endpoint = "vendor/policy_providers/";

const ViewVendor = () => {
  const { id } = useParams();
  const { data, error } = useSWR(`${endpoint}${id}/`, fetcher);

  if (error) {
    return <Text>Something went wrong</Text>;
  }

  if (!data) {
    return <Skeleton height={200} />;
  }

  return (
    <Paper shadow="xl" >
      <VendorViewForm data={data} />
    </Paper>
  );
};

export default ViewVendor;
