import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  accessToken: null,
  refreshToken: null,
  roles: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      console.log(state.user, "state");
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.email || state.user,
        accessToken: action.payload.access || state.accessToken,
        refreshToken: action.payload.refresh || state.refreshToken,
        roles: action.payload.permissions || state.roles,
        user_type: action.payload.user_type || state.user_type,
      };
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.roles = null;
    },
  },
});

export const { setAuth, logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
