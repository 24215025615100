import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import DataWrapper from "../../../covers/components/DataWrapper/DataWrapper";
import { USER_TYPES } from "../../../../utils/userTypes";

const endpoint = "issuance/user-specific-payments/";

const UserPayments = () => {
  const outletContext = useOutletContext();
  const user = outletContext ? outletContext.user : null;
  const { user_type } = useSelector((state) => state.auth);

  const userEmail = user ? user.user_profile_info.user.email : null;
  const paymentsEndpoint = user_type.includes(USER_TYPES.SUPERUSER)
    ? `${endpoint}?user_email=${userEmail}`
    : endpoint;

  const paymentConfig = {
    fields: [
      { key: "transaction_id", label: "Transaction ID" },
      { key: "gateway", label: "Gateway" },
      { key: "method", label: "Method" },
      { key: "currency", label: "Currency" },
      { key: "total_amount", label: "Total Amount" },
      { key: "transaction_status", label: "Status", type: "status" },
    ],
  };

  return (
    <>
      <DataWrapper endpoint={paymentsEndpoint} config={paymentConfig} />
    </>
  );
};

export default UserPayments;
