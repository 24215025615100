import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  NumberInput,
  SimpleGrid,
  Button,
  Center,
} from "@mantine/core";

const QuoteLinkForm = ({ onSubmit, isLoading }) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
  });

  const onSubmitForm = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <SimpleGrid cols={3}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextInput label="Name" placeholder="Enter name" {...field} />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                type="email"
                label="Email"
                placeholder="Enter email address"
                {...field}
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <NumberInput
                label="Phone Number"
                placeholder="Enter phone number"
                allowNegative={false}
                allowDecimal={false}
                hideControls
                {...field}
              />
            )}
          />
        </SimpleGrid>

        <Center>
          <Button
            variant="gradient"
            type="submit"
            mt="xl"
            size="sm"
            loading={isLoading}
            loaderProps={{ type: "dots" }}
            rightSection={<img src="/images/svg/link.svg" />}
          >
            Generate Link
          </Button>
        </Center>
      </form>
    </>
  );
};

export default QuoteLinkForm;
