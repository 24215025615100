import { Menu, Group, Text, Avatar, Image } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import useAuthLogout from "../../../features/auth/hooks/useAuthLogout/useAuthLogout";
import { clearOperatorProfile } from "../../../features/auth/operatorProfileSlice";
import { clearUserProfile } from "../../../features/auth/userProfileSlice";
import { USER_TYPES } from "../../../utils/userTypes";
import classes from "./UserMenu.module.css";

const UserMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, refreshToken, user_type } = useSelector((state) => state.auth);
  const authLogout = useAuthLogout(refreshToken);

  const signOut = async (refreshToken) => {
    await authLogout(refreshToken);

    if (user_type[0] === USER_TYPES.OPERATOR) {
      dispatch(clearOperatorProfile());
    } else {
      dispatch(clearUserProfile());
    }
    navigate("/");
  };

  return (
    <Group justify="center">
      <Menu
        withArrow
        width={300}
        position="bottom"
        transitionProps={{ transition: "pop" }}
        withinPortal
      >
        <Menu.Target>
          <Image
            src="/images/svg/profile-icon.svg"
            height={28}
            width={50}
            style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'contain'}}
          />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item>
            <Group>
              <Avatar
                radius="xl"
                src="/images/svg/profile-icon.svg"
                style={{objectFit: "contain"}}
              />
              <div>
                <Text size="xs" c="dimmed" className={classes.userName}>
                  {user}
                </Text>
              </div>
            </Group>
          </Menu.Item>
          <Link className={classes.menuLink} to={"/account-settings"}>
            <Menu.Item className={classes.menuItem}>Profile settings</Menu.Item>
          </Link>
          <Link className={classes.menuLink} to={"/change-password"}>
            <Menu.Item className={classes.menuItem}>Change Password</Menu.Item>
          </Link>
          <Link className={classes.menuLink} onClick={signOut}>
            <Menu.Item className={classes.menuItem}>Logout</Menu.Item>
          </Link>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

export default UserMenu;