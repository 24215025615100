import { useEffect, useMemo, startTransition, useRef } from "react";
import useSWR from "swr";
import { useForm, FormProvider, Controller } from "react-hook-form";
import {
  Select,
  MultiSelect,
  Checkbox,
  FileInput,
  NumberInput,
} from "@mantine/core";
import { fetcher, fileFetcher } from "../../../../services/fetcher";
import { adventureTypes } from "../../../../data/adventures";
import BaseForm from "../../components/BaseForm/BaseForm";

const CustomizeCoverForm = ({
  mode,
  data,
  masterCovers,
  nationalities,
  countries,
  partnersOptions,
  adventureOptions,
  onSubmit,
  onSaveAs,
  isSubmitting,
}) => {
  const methods = useForm({
    defaultValues: {
      cover: "",
      type: "",
      customization_type: "",
      currency: "",
      title: "",
      nationality: [],
      travelling_from: [],
      travelling_to: [],
      adventure: [],
      cover_scope: "",
      max_duration: "",
      min_duration: "",
      age_max: "",
      age_min: "",
      price: "",
      terms_and_conditions: [],
      partner: [],
      is_partnership_hidden: false,
      image: null,
      brochure: null,
      policy_wording: null,
    },
    mode: "onChange",
  });

  const {
    reset,
    control,
    watch,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const selectedCover = watch("cover");

  const isViewMode = mode === "view";
  const isDev = import.meta.env.MODE === "development";
  const baseURL = isDev ? import.meta.env.VITE_API_ENDPOINT : "";

  // Refs to track fetched files
  const imageFetched = useRef(false);
  const brochureFetched = useRef(false);
  const policyWordingFetched = useRef(false);

  const { data: imageData } = useSWR(
    data?.image && !imageFetched.current ? `${baseURL}${data.image}` : null,
    fileFetcher,
    {
      onSuccess: (data) => {
        startTransition(() => {
          setValue("image", data);
        });
        imageFetched.current = true;
      },
      revalidateOnFocus: false,
    }
  );

  const { data: brochureData } = useSWR(
    data?.brochure && !brochureFetched.current
      ? `${baseURL}${data.brochure}`
      : null,
    fileFetcher,
    {
      onSuccess: (data) => {
        startTransition(() => {
          setValue("brochure", data);
        });
        brochureFetched.current = true;
      },
      revalidateOnFocus: false,
    }
  );

  const { data: policyWordingData } = useSWR(
    data?.policy_wording && !policyWordingFetched.current
      ? `${baseURL}${data.policy_wording}`
      : null,
    fileFetcher,
    {
      onSuccess: (data) => {
        startTransition(() => {
          setValue("policy_wording", data);
        });
        policyWordingFetched.current = true;
      },
      revalidateOnFocus: false,
    }
  );

  const { isLoading: isLoadingCoverData } = useSWR(
    mode === "add" && selectedCover.length > 0
      ? `/cover-api/master-covers/${selectedCover}`
      : null,
    fetcher,
    {
      onSuccess: (data) => {
        startTransition(() => {
          const {
            currency,
            title,
            nationality,
            travelling_from,
            travelling_to,
            cover_scope,
            max_duration,
            min_duration,
            age_max,
            age_min,
            terms_and_conditions,
            adventure,
            start_date,
            end_date,
          } = data;

          setValue("title", title);
          setValue("currency", currency);
          setValue("cover_scope", cover_scope);
          setValue("age_max", age_max);
          setValue("age_min", age_min);
          setValue("max_duration", max_duration);
          setValue("min_duration", min_duration);
          setValue("terms_and_conditions", terms_and_conditions || []);
          setValue("nationality", nationality);
          setValue("travelling_from", travelling_from);
          setValue("travelling_to", travelling_to);
          setValue("adventure", adventure || []);
          setValue("start_date", start_date ? new Date(start_date) : null);
          setValue("end_date", end_date ? new Date(end_date) : null);
        });
      },
    }
  );

  useEffect(() => {
    if (data) {
      const excludeKeys = [
        "travelling_to_names",
        "travelling_from_names",
        "nationality_names",
      ];

      const restData = Object.fromEntries(
        Object.entries(data).filter(([key]) => !excludeKeys.includes(key))
      );

      const formatData = {
        ...restData,
        cover: String(data.cover),
        type: String(data.type),
        customization_type: String(data.customization_type),
        partner: data.partner.map(String),
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
        image: imageData,
        brochure: brochureData,
        policy_wording: policyWordingData,
      };

      reset(formatData);
    }
  }, [data, reset, imageData, brochureData, policyWordingData]);

  const specificFields = useMemo(
    () => [
      {
        name: "Cover",
        component: () => (
          <Controller
            name="cover"
            control={control}
            rules={{ required: "Cover is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                data={masterCovers}
                label={"Master Cover"}
                placeholder={"Select Master Cover"}
                {...field}
                disabled={isViewMode || isLoadingCoverData}
                error={error?.message}
              />
            )}
          />
        ),
        span: { base: 12, md: 6, lg: 6 },
      },
      {
        name: "Type",
        component: () => (
          <Controller
            name="type"
            control={control}
            rules={{ required: "Type is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                label={"Type"}
                placeholder={"Select Type"}
                size={"sm"}
                data={adventureTypes}
                value={field.value}
                {...field}
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        ),
        span: { base: 12, md: 6, lg: 6 },
      },
      {
        name: "Customization Type",
        component: () => (
          <Controller
            name="customization_type"
            control={control}
            rules={{ required: "Customization Type is required" }}
            render={({ field, fieldState: { error } }) => (
              <Select
                label={"Customization Type"}
                placeholder={"Select Customization Type"}
                size={"sm"}
                data={[
                  { label: "Operator", value: "1" },
                  { label: "B2C", value: "2" },
                  { label: "ASC360", value: "3" },
                ]}
                {...field}
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        ),
        span: { base: 12, md: 6, lg: 6 },
      },
      {
        name: "Price",
        component: () => (
          <Controller
            name="price"
            control={control}
            rules={{ required: "Cover Price is required" }}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                placeholder="Enter cover price"
                label="Cover Price"
                decimalScale={2}
                allowNegative={false}
                hideControls
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        ),
        span: { base: 12, md: 6, lg: 6 },
      },
      {
        name: "Partner",
        component: () => (
          <Controller
            name="partner"
            control={control}
            rules={{ required: "Partner is required" }}
            render={({ field, fieldState: { error } }) => (
              <MultiSelect
                label={"Partner"}
                placeholder={"Select Partners"}
                data={partnersOptions}
                onChange={field.onChange}
                value={Array.isArray(field.value) ? field.value : []}
                disabled={isViewMode}
                error={error?.message}
              />
            )}
          />
        ),
        span: 6,
      },
      {
        name: "Is Partnership Hidden",
        component: () => (
          <Controller
            name="is_partnership_hidden"
            control={control}
            render={({ field }) => (
              <Checkbox
                my={"lg"}
                radius="sm"
                disabled={isViewMode}
                label="Is partnership hidden"
                onChange={field.onChange}
                checked={field.value}
              />
            )}
          />
        ),
        span: 6,
      },
      {
        name: "Image",
        component: () => (
          <Controller
            name="image"
            control={control}
            render={({ field }) => (
              <FileInput
                label="Cover Image"
                placeholder="Upload cover image file"
                clearable
                accept="image/png,image/jpeg"
                onChange={field.onChange}
                disabled={isViewMode}
              />
            )}
          />
        ),
        span: 4,
      },
      {
        name: "Brochure",
        component: () => (
          <Controller
            name="brochure"
            control={control}
            render={({ field }) => (
              <FileInput
                label="Cover Brochure"
                placeholder="Upload cover brochure file"
                clearable
                accept="image/png, image/jpeg, application/pdf"
                onChange={field.onChange}
                disabled={isViewMode}
              />
            )}
          />
        ),
        span: 4,
      },
      {
        name: "Policy Wording",
        component: () => (
          <Controller
            name="policy_wording"
            control={control}
            render={({ field }) => (
              <FileInput
                label="Policy Wording"
                placeholder="Upload cover policy wording file"
                clearable
                accept="image/png, image/jpeg, application/pdf"
                onChange={field.onChange}
                disabled={isViewMode}
              />
            )}
          />
        ),
        span: 4,
      },
    ],
    [control, masterCovers, isViewMode, partnersOptions, isLoadingCoverData]
  );

  return (
    <FormProvider {...methods}>
      <BaseForm
        mode={mode}
        isSubmitting={isSubmitting}
        onSubmit={methods.handleSubmit((data) => onSubmit(data, dirtyFields))}
        onSaveAs={methods.handleSubmit((data) => onSaveAs(data, dirtyFields))}
        specificFields={specificFields}
        nationalities={nationalities}
        countries={countries}
        adventureOptions={adventureOptions}
      />
    </FormProvider>
  );
};

export default CustomizeCoverForm;
