// import { useState, useEffect, useRef } from "react";

// const useWebSocket = (url) => {
//   const [socket, setSocket] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const reconnectIntervalRef = useRef(null);

//   useEffect(() => {
//     // Function to create the WebSocket connection
//     const connectWebSocket = () => {
//       const ws = new WebSocket(url);

//       // Handle opening the connection
//       ws.onopen = () => {
//         console.log("WebSocket connected!");
//         if (reconnectIntervalRef.current) {
//           clearInterval(reconnectIntervalRef.current);
//           reconnectIntervalRef.current = null;
//         }
//       };

//       // Handle incoming messages
//       ws.onmessage = (event) => {
//         const newMessage = JSON.parse(event.data);
//         setMessages((prev) => [...prev, newMessage]);
//       };

//       // Handle errors
//       ws.onerror = (error) => {
//         console.error("WebSocket error", error);
//       };

//       // Handle closing the connection
//       ws.onclose = () => {
//         console.log("WebSocket closed, attempting to reconnect...");
//         reconnectIntervalRef.current = setInterval(() => {
//           console.log("Trying to reconnect...");
//           connectWebSocket();
//         }, 5000);
//       };

//       setSocket(ws);
//     };

//     // Establish the WebSocket connection
//     connectWebSocket();

//     // Cleanup when the component unmounts or on re-render
//     return () => {
//       if (socket) {
//         socket.close();
//       }
//     };
//   }, [url]);

//   // A function to send messages through the WebSocket
//   const sendMessage = (message) => {
//     if (socket && socket.readyState === WebSocket.OPEN) {
//       socket.send(JSON.stringify(message));
//     } else {
//       console.warn("WebSocket is not open. Cannot send message.");
//     }
//   };

//   return { messages, sendMessage };
// };

// export default useWebSocket;
import { useState, useEffect, useRef } from "react";

const useWebSocket = (url) => {
  const [messages, setMessages] = useState([]);
  const socketRef = useRef(null);
  const reconnectIntervalRef = useRef(null);
  const isConnectedRef = useRef(false);

  useEffect(() => {
    // Function to create the WebSocket connection
    const connectWebSocket = () => {
      socketRef.current = new WebSocket(url);

      // Handle opening the connection
      socketRef.current.onopen = () => {
        console.log("WebSocket connected!");
        isConnectedRef.current = true;

        if (reconnectIntervalRef.current) {
          clearInterval(reconnectIntervalRef.current);
          reconnectIntervalRef.current = null;
        }
      };

      // Handle incoming messages
      socketRef.current.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        setMessages((prev) => [...prev, newMessage]);
      };

      // Handle errors
      socketRef.current.onerror = (error) => {
        console.error("WebSocket error", error);
      };

      // Handle closing the connection
      socketRef.current.onclose = () => {
        console.log("WebSocket closed, attempting to reconnect...");
        isConnectedRef.current = false;
        reconnectIntervalRef.current = setInterval(() => {
          console.log("Trying to reconnect...");
          if (socketRef.current.readyState === WebSocket.CLOSED) {
            connectWebSocket();
          }
        }, 5000);
      };
    };

    // Establish the WebSocket connection
    connectWebSocket();

    // Cleanup when the component unmounts or on re-render
    return () => {
      if (socketRef.current && isConnectedRef.current) {
        socketRef.current.close();
        isConnectedRef.current = false;
      }
    };
  }, [url]);

  // A function to send messages through the WebSocket
  const sendMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.warn("WebSocket is not open. Cannot send message.");
    }
  };

  return { messages, sendMessage };
};

export default useWebSocket;
