import { useForm, Controller } from "react-hook-form";
import {
  TextInput,
  FileInput,
  SimpleGrid,
  Button,
  Select,
  Title,
  Group,
} from "@mantine/core";
import classes from "./VendorEditForm.module.css"

const VendorEditForm = ({ data, onSubmit }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name: data.name || "",
      code: data.code || "",
      logo: data.logo || "",
      country: data.country || "",
      email: data.email || "",
      phone_number: data.phone_number || "",
      tax_id: data.tax_id || "",
      tax_withholding_category: data.tax_withholding_category || "",
      policy_provider_type: data.policy_provider_type || "",
      gst_category: data.gst_category || "",
      pan: data.pan || "",
      tax_category: data.tax_category || "",
      account_number: data.account_number || "",
      bank_name: data.bank_name || "",
      branch_name: data.branch_name || "",
      ifsc_code: data.ifsc_code || "",
      swift_code: data.swift_code || "",
      iban: data.iban || "",
      bank_country: data.bank_country || "",
      disabled: data.disabled || false,
    },
  });

  return (
    <>
        <Title mb="lg" className={classes.heading}>Basic</Title>
        <form className={classes.VendorEditForm} onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid cols={{ base: 1, xs: 1, sm: 2, lg: 2 }} spacing="xl">
            <TextInput
                label="Vendor Name"
                type="text"
                placeholder="Enter vendor name"
                {...register("name")}
            />

            <TextInput
                label="Vendor Code"
                type="text"
                placeholder="Enter vendor code"
                {...register("code")}
            />

            <Controller
                name="logo"
                control={control}
                render={({ field }) => (
                <FileInput
                    label="Upload Vendor Logo"
                    placeholder="vendor logo"
                    onChange={field.onChange}
                />
                )}
            />

            <TextInput
                label="Vendor Country"
                type="text"
                placeholder="Enter vendor country"
                {...register("country")}
            />

            <TextInput
                label="Email"
                type="email"
                placeholder="Enter Email"
                {...register("email")}
            />

            <TextInput
                label="Phone Number"
                type="text"
                placeholder="Enter Phone Number"
                {...register("phone_number")}
            />

            <TextInput
                label="Tax ID"
                type="text"
                placeholder="Enter tax ID"
                {...register("tax_id")}
            />

            <TextInput
                label="Tax Withholding Category"
                type="text"
                placeholder="Enter tax withholding category"
                {...register("tax_withholding_category")}
            />

            <Controller
                name="policy_provider_type"
                control={control}
                render={({ field }) => (
                <Select
                    label="Policy Provider Type"
                    placeholder="Select Policy Provider Type"
                    data={["Individual", "Company"]}
                    value={field.value}
                    onChange={field.onChange}
                />
                )}
            />

            <Controller
                name="gst_category"
                control={control}
                render={({ field }) => (
                <Select
                    label="GST Category"
                    placeholder="Select GST category"
                    data={[
                    "Unregistered",
                    "Registered Regular",
                    "Registered Composition",
                    "SEZ",
                    "Overseas",
                    "UIN Holder",
                    ]}
                    value={field.value}
                    onChange={field.onChange}
                />
                )}
            />

            <TextInput
                label="PAN"
                type="text"
                placeholder="Enter PAN"
                {...register("pan")}
            />

            <Controller
                name="tax_category"
                control={control}
                render={({ field }) => (
                <Select
                    label="Tax Category"
                    placeholder="Select tax category"
                    data={[
                    "In-State",
                    "Services",
                    "Registered Composition",
                    "Reverse Charge In-State",
                    "Reverse Charge Out-State",
                    ]}
                    value={field.value}
                    onChange={field.onChange}
                />
                )}
            />

            <TextInput
                label="Account Number"
                type="text"
                placeholder="Enter account number"
                {...register("account_number")}
            />

            <TextInput
                label="Bank Name"
                type="text"
                placeholder="Enter bank name"
                {...register("bank_name")}
            />

            <TextInput
                label="Branch Name"
                type="text"
                placeholder="Enter branch name"
                {...register("branch_name")}
            />

            <TextInput
                label="IFSC Code"
                type="text"
                placeholder="Enter IFSC code"
                {...register("ifsc_code")}
            />

            <TextInput
                label="SWIFT Code"
                type="text"
                placeholder="Enter SWIFT code"
                {...register("swift_code")}
            />

            <TextInput
                label="IBAN"
                type="text"
                placeholder="Enter IBAN"
                {...register("iban")}
            />

            <TextInput
                label="Bank Country"
                type="text"
                placeholder="Enter bank country"
                {...register("bank_country")}
            />

            <TextInput type="hidden" value={false} {...register("disabled")} />
            </SimpleGrid>
            <Group justify="center">
                <Button variant="outline" type="submit" mt="xl" size="md">
                    Submit
                </Button>
            </Group>
        </form>
    </>
  );
};

export default VendorEditForm;
