import { Paper } from "@mantine/core";

import WebSocketComponent from "../../../common/websocket/WebSocketComponent";

const Dashboard = () => {
  return (
    <>
      <Paper p="xl" my="lg">
        <WebSocketComponent />
      </Paper>
    </>
  );
};

export default Dashboard;
