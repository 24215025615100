import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { fetcher } from "../../../../services/fetcher";
import { Button, Text, SimpleGrid } from "@mantine/core";
import SearchComponent from "../../components/UsersTable/Search/Search";
import UserCard from "../../components/UsersTable/UserCard";
import MainLoader from "../../../../common/components/Loader/Loader";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import ConfirmModal from "../../../../common/components/ConfirmModal/ConfirmModal";
import classes from "./User.module.css";

const endpoint = "auth-api/user-create/";
const delUser = "auth-api/users-deactivate/";

const UsersPage = () => {
  const { isLoading, data: users, error, mutate } = useSWR(endpoint, fetcher);
  const [searchQuery, setSearchQuery] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleSwitchChange = (event, data) => {
    const isChecked = event.currentTarget.checked;

    if ((data.is_active && !isChecked) || (!data.is_active && isChecked)) {
      setSelectedUser(data);
      open();
    }
  };

  const deletUserMutation = async (user) => {
    try {
      await fetcher(`${delUser}`, "POST", user);
      await mutate();
      successNotifications("User deleted successfully");
    } catch (error) {
      console.error(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedUser) {
      const payload = {
        email: selectedUser.email,
        is_active: !selectedUser.is_active,
      };

      deletUserMutation(payload);
      setSelectedUser(null);
    }
  };

  const filteredUsers = users?.filter((user) => {
    const { user_profile_info, code } = user;
    const { user: userInfo } = user_profile_info;
    const { first_name, last_name, email } = userInfo;
    const { city, state } = user_profile_info;

    const query = searchQuery.toLowerCase();

    return (
      first_name.toLowerCase().includes(query) ||
      last_name.toLowerCase().includes(query) ||
      email.toLowerCase().includes(query) ||
      (city && city.toLowerCase().includes(query)) ||
      (state && state.toLowerCase().includes(query)) ||
      (code && code.toLowerCase().includes(query))
    );
  });

  if (isLoading) {
    return <MainLoader />;
  }

  if (error) {
    return (
      <>
        <p>Error {error}</p>
      </>
    );
  }

  return (
    <>
      <div style={{ float: "right" }}>
        <Link to={"add"}>
          <Button mb={"5"} variant="outline">
            + Add New Employee
          </Button>
        </Link>
      </div>
      <div className={classes.user}>
        <SearchComponent onSearch={handleSearch} />
      </div>
      <div className={classes.userTable}>
        {users ? (
          <SimpleGrid cols={{ base: 1, xs: 1, sm: 2, md: 3 }} spacing="lg">
            {filteredUsers.map((item) => (
              <UserCard
                key={item.id}
                item={item}
                handleSwitch={handleSwitchChange}
              />
            ))}
          </SimpleGrid>
        ) : (
          <Text>No users available</Text>
        )}
      </div>

      <ConfirmModal
        opened={opened}
        onClose={close}
        onConfirm={handleDeleteConfirm}
        title={
          <Text
            className={classes.confirmModalTitle}
          >
            {selectedUser?.is_active
              ? "Are you sure to Disable this Employee Profile?"
              : "Are you sure to Enable this Employee Profile?"}
          </Text>
        }
        confirmLabel="Yes"
        cancelLabel="No"
      >
        {selectedUser?.is_active
          ? "Disabling this profile will no longer allow this employee to make any operations."
          : "Enabling this profile will allow this employee to resume operations."}
      </ConfirmModal>
    </>
  );
};

export default UsersPage;