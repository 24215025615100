import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    email: "",
    first_name: "",
    last_name: "",
    is_active: false,
  },
  id: null,
  uuid: "",
  address: null,
  state: null,
  city: null,
  pin: null,
  country: null,
  tel_no: null,
  gender: null,
  date_of_birth: null,
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserProfile(state, action) {
      return { ...state, ...action.payload };
    },
    clearUserProfile() {
      return initialState;
    },
  },
});

export const { setUserProfile, clearUserProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
