import useSWR from "swr";
import { Link } from "react-router-dom";
import { fetcher } from "../../../../services/fetcher";
import { SimpleGrid, Button, Flex } from "@mantine/core";
import OperatorCard from "../../components/OperatorCard/OperatorCard";
import MainLoader from "../../../../common/components/Loader/Loader";

const endpoint = "operator/operator-profiles/";

const Operators = () => {
  const { isLoading, data: operators, error } = useSWR(endpoint, fetcher);

  if (isLoading) {
    return <MainLoader />;
  }

  if (error) {
    return <p>something went wrong</p>;
  }

  return (
    <>
      <Flex
        mih={50}
        gap="md"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        mb={"lg"}
      >
        <Link to={`add`}>
          <Button variant="outline">+ Create New Operator</Button>
        </Link>
      </Flex>

      <SimpleGrid cols={{ base: 1, xs: 1, sm: 2, md: 2, lg: 2, xl: 3 }} spacing="lg">
        {operators.map((operator) => {
          return <OperatorCard key={operator.id} data={operator} />;
        })}
      </SimpleGrid>
    </>
  );
};

export default Operators;
