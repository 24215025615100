import { useLocation, Link } from "react-router-dom";
import classes from "./CustomBreadcrumbs.module.css";

const CustomBreadcrumbs = () => {
  const location = useLocation();
  let currentLink = "";

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "" && isNaN(crumb)) 
    .map((crumb, index, array) => {
      currentLink = currentLink + `/${crumb}`;

      const isLastCrumb = index === array.length - 1;
      const isSpecialCase = ["edit", "view", "create"].includes(crumb);

      return (
        <div key={`${crumb}-${index}`} className={classes.crumb}> 
          {isLastCrumb || isSpecialCase ? (
            <a href="#">{crumb}</a> 
          ) : (
            <Link to={currentLink}>{crumb}</Link> 
          )}
        </div>
      );
    });

  return <div className={classes.breadcrumbs}>{crumbs}</div>;
};

export default CustomBreadcrumbs;