import {
  defaultVariantColorsResolver,
  parseThemeColor,
  rem,
  rgba,
  Select,
  TextInput,
  JsonInput,
  Textarea,
  MultiSelect,
  PillsInput,
  Combobox,
  FileInput,
  NumberInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { DateInput } from "@mantine/dates";
import classes from "./styles.module.css";

const variantColorResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);

  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  });

  if (input.variant === "outline") {
    return {
      ...defaultResolvedColors,
      border: `${rem(2)} solid ${parsedColor.value}`,
      color: parsedColor.value,
      background: "transparent",
      hover: {
        background: rgba(parsedColor.value, 0.1),
      },
    };
  }

  if (input.variant === "light") {
    return {
      ...defaultResolvedColors,
      border: "none",
      color: "#999999",
      background: "#F6F6F6",
      // hover: "#FF6528",
      // hoverColor: "#FFF",
    };
  }

  // Custom variant
  if (input.variant === "outline2") {
    return {
      ...defaultResolvedColors,
      border: `${rem(2)} solid #999999`,
      color: "#999",
      background: "transparent",
      hover: "#FFF",
      hoverColor: "#999",
    };
  }

  if (input.variant === "outline3") {
    return {
      ...defaultResolvedColors,
      // border: `${rem(2)} solid #FFF`,
      color: "#999",
      background: "#FFF",
      hover: "#FFF",
      boxShadow: "0px 2px 48px 0px rgba(75, 75, 75, 0.08)",
    };
  }

  return defaultResolvedColors;
};

export const theme = {
  focusRing: "auto",
  cursorType: "pointer",
  defaultRadius: "8px",
  fontFamily: "Montserrat, sans-serif",
  fontSizes: {
    xs: rem(12),
    sm: rem(14),
    md: rem(16),
    lg: rem(18),
    xl: rem(20),
  },
  headings: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    sizes: {
      h1: { fontSize: rem(64) },
      h2: { fontSize: rem(48) },
      h3: { fontSize: rem(24) },
      h4: { fontSize: rem(20) },
      h5: { fontSize: rem(16) },
      h6: { fontSize: rem(14) },
    },
  },
  breakpoints: {
    xs: "30em",
    sm: "48em",
    md: "64em",
    lg: "74em",
    xl: "90em",
  },
  defaultGradient: {
    from: "#FF6528",
    to: "#E78600",
    deg: 92,
  },
  shadows: {
    base: "0px 1px 28px 0px rgba(75, 75, 75, 0.08)",
    sm: "0px 2px 38px 0px rgba(75, 75, 75, 0.08)",
    md: "0px 2px 48px 0px rgba(75, 75, 75, 0.08)",
    xl: "0px 2px 48px 0px rgba(75, 75, 75, 0.08)",
  },
  primaryColor: "orange",
  variantColorResolver: variantColorResolver,
  components: {
    Select: Select.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    TextInput: TextInput.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    FileInput: FileInput.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    MultiSelect: MultiSelect.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    JsonInput: JsonInput.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    Textarea: Textarea.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    PillsInput: PillsInput.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    Combobox: Combobox.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    DateInput: DateInput.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    DatePickerInput: DatePickerInput.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),

    NumberInput: NumberInput.extend({
      classNames: {
        label: classes.label,
        input: classes.input,
      },
    }),
  },
};