import { useState } from "react";
import { Link } from "react-router-dom";
import { UnstyledButton, Menu, Text } from "@mantine/core";
import classes from "./Actions.module.css";

const Actions = ({ rowData, actions, handler }) => {
  const [opened, setOpened] = useState(false);

  // Generate action items
  const actionItems = actions.map((item, index) => (
    <Link key={index} to={item.to(rowData)}>
      <Menu.Item>{item.label}</Menu.Item>
    </Link>
  ));

  // Generate handler items
  const handlerItems = handler.map((item, index) => (
    <Menu.Item key={index} onClick={() => item.handler(rowData)}>
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={classes.control}
          data-expanded={opened || undefined}
        >
          <Text size="sm" fw={600}>
            {"Choose"}
          </Text>
          <div className={classes.right_section}>
            <img
              src="/images/svg/chevron.svg"
              className={classes.icon}
              stroke={1.5}
              alt="Chevron Icon"
            />
          </div>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        {actionItems}
        {handlerItems}
      </Menu.Dropdown>
    </Menu>
  );
};

export default Actions;
