import { useSelector } from "react-redux";

const WithPermission = (props) => {
  const { roleRequired, children } = props;
  const { isAuthenticated, roles } = useSelector((state) => state.auth);

  // Allow access to the dashboard for all roles
  if (isAuthenticated && roleRequired.length === 0) {
    return <>{children}</>;
  }

  return (
    isAuthenticated &&
    roles.find((role) => roleRequired?.includes(role)) && <>{children}</>
  );
};

export default WithPermission;
