import { useState, useEffect } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetcher } from "../../../../services/fetcher";
import BulkIssuance from "../../containers/BulkIssuane/BulkIssuance";
import SingleIssuance from "../../containers/SingleIssuance/SingleIssuance";
import {
  Paper,
  Skeleton,
  Select,
  SimpleGrid,
  Radio,
  Group,
  Title,
} from "@mantine/core";
import { USER_TYPES } from "../../../../utils/userTypes";
import { ISSUE_AS, COVER_TYPES } from "../../../../utils/issuance";
import {
  generateCoversGroup,
  mapDataToOptions,
} from "../../../../utils/mapDataToOptions";
import classes from "./Issuance.module.css";

const BASE_URL = "issuance/user-cover-plan-access/";

const getIssuanceUrl = (policyType, userType, issueAs, operator) => {
  let url = `${BASE_URL}?policy_type=${policyType}`;

  if (userType.includes(USER_TYPES.OPERATOR)) {
    return url;
  } else if (
    userType.includes(USER_TYPES.STAFF) ||
    userType.includes(USER_TYPES.SUPERUSER)
  ) {
    if (issueAs) {
      url += `&issue_as=${issueAs}`;
      if (operator.id) {
        url += `&operator=${operator.id}`;
      }
    } else {
      return null;
    }
  }

  return url;
};

const Issuance = () => {
  const { policy_type } = useParams();
  const { user_type } = useSelector((state) => state.auth);
  const [issuanceUrl, setIssuanceUrl] = useState("");
  const [issueAs, setIssueAs] = useState("");
  const [operator, setOperator] = useState({ id: "", label: "" });
  const [cover, setCover] = useState({});
  const [operatorList, setOperatorList] = useState([]);
  const [coverList, setCoverList] = useState([]);

  const { isLoading, data } = useSWR(
    issuanceUrl,
    async (url) => {
      if (!url) return null;
      const result = await fetcher(url);
      return result;
    },
    {
      onSuccess: (result) => {
        if (
          issuanceUrl.includes("issue_as=operator") &&
          !issuanceUrl.includes("operator=")
        ) {
          setOperatorList(mapDataToOptions(result.choose_operator));
        } else {
          setCoverList(generateCoversGroup(result));
        }
      },
    }
  );

  useEffect(() => {
    const url = getIssuanceUrl(policy_type, user_type, issueAs, operator);
    setIssuanceUrl(url);
    console.log("Issuance URL:", url);
  }, [policy_type, user_type, issueAs, operator]);

  const handleChangeCover = (value) => {
    if (!value) return;

    const selectedCustomizeCover = data.covers[0].customize_covers.find(
      (item) => item.id === +value
    );

    if (selectedCustomizeCover) {
      setCover({
        ...selectedCustomizeCover,
        cover_type: COVER_TYPES.CUSTOMIZE_COVER,
      });
    }
  };

  return (
    <>
      {(user_type[0] === USER_TYPES.STAFF ||
        user_type[0] === USER_TYPES.SUPERUSER) && (
        <Paper shadow="xl" className={classes.policyIssuance}>
          <SimpleGrid
            cols={{ base: 1, xs: 2, sm: 1, lg: 2 }}
            spacing={{ base: 10, xs: "xl", sm: "xl" }}
          >
            <Radio.Group
              label="Issue As"
              value={issueAs}
              onChange={(value) => setIssueAs(value)}
              spacing="xl"
              styles={{
                label: {
                  fontSize: "18px",
                  fontWeight: 600,
                },
              }}
            >
              <Group spacing="md" mt={"md"}>
                {ISSUE_AS.map((item) => (
                  <Radio
                    key={item.value}
                    variant="outline"
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </Group>
            </Radio.Group>

            {issueAs === "operator" && (
              <Select
                label="Select Operator"
                placeholder="select operator"
                data={operatorList}
                value={operator.id}
                onChange={(value) => {
                  const selectedOperator = operatorList.find(
                    (op) => op.value === value
                  );
                  console.log(selectedOperator);

                  setOperator({ id: value, label: selectedOperator?.label });
                }}
              />
            )}
          </SimpleGrid>
        </Paper>
      )}

      {policy_type === "bulk" &&
        issuanceUrl &&
        (issueAs !== "operator" || (issueAs === "operator" && operator.id)) && (
          <Skeleton visible={isLoading} my={"lg"}>
            <Paper shadow="xl" className={classes.assignedCover}>
              <Title className={classes.issuanceTitle} mb="xl">
                Assigned Covers
              </Title>
              <Select
                className={classes.policyIssuancePaper}
                label="Select Cover"
                mb={"lg"}
                placeholder="select cover to issue"
                data={coverList}
                value={cover?.id}
                onChange={(value) => handleChangeCover(value)}
              />
            </Paper>
          </Skeleton>
        )}

      {policy_type === "bulk" && Object.keys(cover).length != 0 && (
        <BulkIssuance
          data={cover}
          {...(issueAs === "operator" ? { operator: operator } : {})}
          selectedCover={cover}
        />
      )}

      {policy_type === "single" &&
        issuanceUrl &&
        (issueAs !== "operator" || (issueAs === "operator" && operator.id)) && (
          <SingleIssuance
            data={cover}
            {...(issueAs === "operator" || user_type[0] === USER_TYPES.OPERATOR
              ? { operator: operator }
              : {})}
          />
        )}
    </>
  );
};

export default Issuance;
