import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { Paper, SimpleGrid, Image, Group, Text } from "@mantine/core";
import WalletCard from "../../../../common/components/WalletCard/WalletCard";
import MainLoader from "../../../../common/components/Loader/Loader";
import DataTable from "../../../../common/components/DataTable/DataTable";
import classes from "./Wallet.module.css";

const walletEndpoint = "asc-wallet/user-wallet";
const addFundEndpoint = "asc-wallet/user-wallet/transactions/";

const Wallet = () => {
  const outletContext = useOutletContext();
  const user = outletContext ? outletContext.user : null;
  const { user_type } = useSelector((state) => state.auth);
  const userProfile = useSelector((state) => !user && state.userProfile);

  const email = user
    ? user.user_profile_info.user.email
    : userProfile.user.email;
  const firstName = user
    ? user.user_profile_info.user.first_name
    : userProfile.user.first_name;
  const lastName = user
    ? user.user_profile_info.user.last_name
    : userProfile.user.last_name;
  const code = user ? user.code : null;
  const departmentName = user ? user.department_name : null;
  const telNo = user ? user.user_profile_info.tel_no : null;

  const isSuperUser = user_type[0].includes("is_superuser");

  const walletURL = isSuperUser
    ? `${walletEndpoint}?email=${email}`
    : `${walletEndpoint}`;

  const transactionURL = isSuperUser
    ? `${addFundEndpoint}?user_email=${email}`
    : `${addFundEndpoint}`;

  const {
    data: wallet,
    // error: walletError,
    isLoading: isWalletLoading,
    // mutate: walletMutate,
  } = useSWR(walletURL, fetcher);

  const {
    data: transaction,
    // error: transactionError,
    isLoading: isTransactionLoading,
    // mutate,
  } = useSWR(transactionURL, fetcher);

  // const addFundMutation = async (fund) => {
  //   try {
  //     await mutate(
  //       fetcher(`${addFundEndpoint}`, "POST", fund),
  //       addFundOptions(fund)
  //     );
  //     await walletMutate();
  //     successNotifications("Success! Wallet.");
  //   } catch (error) {
  //     errorNotifications(error.response.data, error);
  //   }
  // };

  const config = {
    fields: [
      { key: "transaction_number", label: "Transaction number" },
      { key: "amount", label: "Amount" },
      { key: "created_by", label: "Created by" },
      { key: "transaction_type", label: "Transaction type" },
    ],
  };

  if (isWalletLoading || isTransactionLoading) {
    return <MainLoader />;
  }

  // if (walletError || transactionError) {
  //   return <div>Error loading data</div>;
  // }

  return (
    <>
      <Paper shadow="xl" my="lg" className={classes.userWalletPaper}>
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 2 }}>
          <div className={classes.userDetailContainer}>
            <div style={{ display: "flex" }}>
              <div className={classes.imageContainer}>
                {/* <Card.Section> */}
                <Image
                  src="/images/svg/userpro.svg"
                  width={80}
                  height={114}
                  className={classes.empImage}
                  alt="avtar"
                />
                {/* </Card.Section> */}
              </div>

              <div style={{ flex: "1" }} className={classes.userDetatil}>
                <Group position="apart">
                  <Text className={classes.name}>
                    {firstName} {lastName}
                  </Text>
                </Group>

                <Text size="sm" className={classes.jobTitle}>
                  {departmentName}
                </Text>
                <Text className={classes.call}>
                  <a href={`tel:${telNo}`}>
                    {telNo && (
                      <Image
                        src="/images/svg/call.svg"
                        className={classes.callIcon}
                        alt="avatar"
                      />
                    )}
                    {telNo}
                  </a>
                </Text>
                <Text className={classes.email}>
                  <a href={`mailto:${email}`}>
                    <Image
                      src="/images/svg/mail-icon.svg"
                      className={classes.mailIcon}
                      alt="avtar"
                    />
                    {email}
                  </a>
                </Text>
              </div>
            </div>
            <div>
              <Text className={classes.empHeading}>
                <span className={classes.code}>Emp Code : </span>
                {code}
              </Text>
            </div>
          </div>

          <div className={classes.flexContainer}>
            <div className={classes.walletbox}>
              <WalletCard
                isLoading={isWalletLoading}
                balance={wallet.balance}
                type={wallet.currency_type}
              />
            </div>
          </div>
        </SimpleGrid>
      </Paper>
      {transaction && <DataTable data={transaction.results} config={config} />}
    </>
  );
};

export default Wallet;