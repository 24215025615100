import { useState } from "react";
import useSWR from "swr";
import AuthLayout from "../../../../common/templates/AuthLayout/AuthLayout";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setAuth } from "../../authSlice";
import AuthAPI from "../../endpoints";
import { fetcher } from "../../../../services/fetcher";
import MultiLoginForm from "../../components/MultiLoginForm/MultiLoginForm";
import { errorNotifications } from "../../../../utils/handleNotifications";
import { USER_TYPES } from "../../../../utils/userTypes";
import { setUserProfile } from "../../userProfileSlice";
import { setOperatorProfile } from "../../operatorProfileSlice";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userType, setUserType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const from = location.state?.from?.pathname || "/dashboard";

  const endpoint =
    userType === USER_TYPES.OPERATOR
      ? "operator/operator"
      : "auth-api/user-info-profile/";

  // Fetch user profile based on user type
  const { mutate } = useSWR(userType ? endpoint : null, fetcher, {
    onSuccess: (data) => {
      if (userType === USER_TYPES.OPERATOR) {
        dispatch(setOperatorProfile(data));
      } else {
        dispatch(setUserProfile(data));
      }

      navigate(from, { replace: true });
    },
  });

  const handleSendOtp = (userID) => {
    return new Promise((resolve, reject) => {
      AuthAPI.loginOTP(userID)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          errorNotifications(error.response.data, error);
          reject(error);
        });
    });
  };

  const handleAuthentication = (authMethod) => async (loginData) => {
    setIsSubmitting(true);
    try {
      const response = await authMethod(loginData);
      dispatch(setAuth(response.data));
      setUserType(response.data.user_type[0]);
      await mutate();
    } catch (error) {
      errorNotifications(error.response.data, error);
      console.error("Login failed:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout title={"ASC360 Portal"}>
      <MultiLoginForm
        onLoginPassword={handleAuthentication(AuthAPI.login)}
        onLoginOtp={handleAuthentication(AuthAPI.verifyOTP)}
        onSendOtp={handleSendOtp}
        isSubmitting={isSubmitting}
      />
    </AuthLayout>
  );
}

export default LoginPage;
