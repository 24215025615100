import { fetcher } from "../../../../services/fetcher";
import { useNavigate, useOutletContext } from "react-router-dom";
import UserForm from "../../forms/UserForm/UserForm";
import useGroupsAndDepartments from "../../hooks/useGroupsAndDepartments/useGroupsAndDepartments";
import { errorNotifications } from "../../../../utils/handleNotifications";

const endpoint = "auth-api/user-create/";

const UserPage = ({ mode }) => {
  const navigate = useNavigate();
  const { user, mutateUser } = useOutletContext();
  const { fields, loadingFields } = useGroupsAndDepartments();

  const updateUserMutation = async (updatedUser) => {
    try {
      await fetcher(`${endpoint}${updatedUser.id}/`, "PUT", updatedUser);
      await mutateUser();
      navigate(-1);
    } catch (error) {
      console.error(error);
      errorNotifications(error.response?.data || "An error occurred", error);
    }
  };

  const handleEditUser = (payload) => {
    updateUserMutation(payload);
  };

  return (
    <>
      <UserForm
        data={user}
        groups={loadingFields ? [] : fields.groups}
        departments={loadingFields ? [] : fields.departments}
        onSubmit={handleEditUser}
        mode={mode}
      />
    </>
  );
};

export default UserPage;
