import { Modal, Button, Group, Text } from "@mantine/core";
import classes from "./ConfirmModal.module.css";

function ConfirmModal({
  opened,
  onClose,
  onConfirm,
  title,
  children,
  confirmLabel,
  cancelLabel,
}) {
  return (
    <Modal opened={opened} onClose={onClose} title={title} withCloseButton={false} centered className={classes.modal}>
      <Text className={classes.text} size="sm">{children}</Text>
      <Group justify="center" mt="lg" mb="lg">
        <Button variant="default" size="md" onClick={onClose}>
          {cancelLabel || "Cancel"}
        </Button>
        <Button
          variant="filled"
          size="md"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {confirmLabel || "Confirm"}
        </Button>    
      </Group>
    </Modal>
  );
}

export default ConfirmModal;